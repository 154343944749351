/* eslint-disable import/no-anonymous-default-export */

const initialState = 0;

export default (state = initialState, action) => {
    if (action.type === 'SET_PROJECT_UNREAD_NOTIFICATION') {
        return action.payload;
    }

    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};