import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import NoData from '../../../common/NoData/NoData';
import { Row, Col, Card, Table, Button, Stack } from 'react-bootstrap';
import moment from 'moment';
import server from '../../../../apis/server';



const ClientNotesSection = () => {
    const [allNotes, setAllNotes] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { projectId } = useParams();
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);

    const getNotes = async () => {
        try {
            const response = await server.get('/notes', {
                params: {
                    companyId: currentCompany._id,
                    project_id: currentProject._id,
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?.length > 0) setAllNotes(response.data);
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const getPublicNotes = () => {
        if (allNotes.length === 0) return [];
        return allNotes.filter(note => note.public === true);
    };
    const getUserRole = (user) => {
        if (user.userRole === 'system_admin') return 'System Admin';
        return 'Client';

    };
    useEffect(() => {
        getNotes();
    }, [currentCompany]);

    return (
        <div>
            <Row className="py-4">
                <Col>
                    <Card>
                        <Card.Header >
                            <Stack className='justify-content-between' direction='horizontal'>
                                <h4 className="mb-0">Client Notes</h4>
                                {location.pathname.includes(`/projectoverview`) &&
                                    <Button variant="primary" size="sm" className="ms-2"
                                        onClick={() => navigate(`/projectsnotes/${projectId}`)}>
                                        View All Notes
                                    </Button>
                                }
                            </Stack>
                        </Card.Header>
                        <Card.Body>
                            <Row>

                                <Col md={12}>
                                    {getPublicNotes().length > 0
                                        ? <Table bordered striped>
                                            <thead>
                                                <tr>
                                                    <th>Notes</th>
                                                    <th>Client Name</th>
                                                    <th>Client type</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {getPublicNotes().map(noteData => {
                                                    const { note, added_by, createdAt } = noteData;
                                                    return (
                                                        <tr>
                                                            <td>{note}</td>
                                                            <td>{added_by.contactName}</td>
                                                            <td>{getUserRole(added_by)}</td>
                                                            <td>{moment(createdAt).format('lll')}</td>
                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </Table>
                                        : <NoData message={'No public notes added'} />
                                    }

                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default ClientNotesSection;
