import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import envelopeIcon from '@iconify/icons-bi/envelope';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEmail } from 'validator';
import server from '../../../apis/server';

//Comp
import MainHeader from '../MainHeader/MainHeader';

const PasswordResetPage = () => {
    //React router hook that handles navigation
    let navigate = useNavigate();

    //local state
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    const onRequestClick = async () => {
        if (!email || !isEmail(email)) return setError('Please enter your email address to reset your password');
        setError('');

        try {
            await server.post('/resetpassword', { email });

            setSuccessMessage(`If there is an account associated with that email, a reset password instructions will be sent.`);
            setEmail('');
            setShowSuccessMessage(true);

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    return (
        <div>
            <MainHeader />

            <main>
                <Container className="px-4 py-5 p-md-5 mt-md-5">
                    <div className="text-center">
                        <h1 className="simple-title text-uppercase">REQUEST PASSWORD RESET</h1>
                    </div>

                    <div className="container--sm px-md-4">

                        <div className="p-3 p-md-5">
                            <fieldset className="pb-4">

                                <Row>
                                    <Col md={12}>
                                        <div className="pb-4 position-relative">
                                            <Form.Label className="custom-form-label">Email</Form.Label>
                                            <InputGroup className="mb-3 addon-left">
                                                <InputGroup.Text id="basic-addon-envelopeIcon"><Icon icon={envelopeIcon} width="24" height="24" /></InputGroup.Text>

                                                <Form.Control
                                                    placeholder="Email"
                                                    aria-label="Email"
                                                    aria-describedby="basic-addon-envelopeIcon"
                                                    isInvalid={error ? true : false}
                                                    value={email} onChange={e => setEmail(e.target.value)}
                                                    onKeyPress={e => { if (e.key === 'Enter') onRequestClick(); }}
                                                />


                                            </InputGroup>
                                            {error && <p className='text-red'>{error}</p>}
                                        </div>
                                    </Col>

                                </Row>
                            </fieldset>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Button variant="primary" className="w-100" onClick={onRequestClick}>REQUEST RESET</Button>
                                    </div>

                                </Col>

                                <Col md={12}>
                                    <div className="mb-3 text-center">
                                        <span className="btn btn-link btn-link--primary" onClick={() => navigate(-1)}>Back</span>
                                    </div>

                                    {showSuccessMessage && successMessage && <p className="text-green">{successMessage}</p>}

                                </Col>

                            </Row>

                        </div>
                    </div>
                </Container>
            </main>
        </div>
    );
};

export default PasswordResetPage;