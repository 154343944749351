import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';

//comp
import CustomTooltip from '../../common/CustomTooltip/CustomTooltip';

//icons
import { Icon } from '@iconify/react';
import pencilSquare from '@iconify/icons-bi/pencil-square';
import trash3Fill from '@iconify/icons-bi/trash3-fill';
import eyeFill from '@iconify/icons-bi/eye-fill';

const getLatestData = (project) => {
    let statuses = project.progress_statuses;
    let approval = project.approval_status;
    
    if (statuses?.corrections_issued?.state === true) {
        return {
            status: 'Corrections Issued',
            date: statuses?.corrections_issued?.changed_at
        };
    }

    console.log(statuses);

    if (statuses?.corrections_issued?.state === true) {
        return {
            status: 'Corrections Issued',
            date: statuses.corrections_issued.changed_at
        };
    }
    if (statuses?.permit_dropped_off?.state === true) {
        return {
            status: 'Permit Dropped Off',
            date: statuses.permit_dropped_off.changed_at
        };
    }
    if (statuses?.permit_issued_secured?.state === true) {
        return {
            status: 'Permit Issued / Secured',
            date: statuses.permit_issued_secured.changed_at
        };
    }
    if (statuses?.permit_approved_pending_issuance?.state === true) {
        return {
            status: 'Permit Approved / Pending Issuance',
            date: statuses.permit_approved_pending_issuance.changed_at
        };
    }
    if (statuses?.public_works?.state === true) {
        return {
            status: 'Public Works',
            date: statuses.public_works.changed_at
        };
    }
    if (statuses?.building_and_safety?.state === true) {
        return {
            status: 'Building And Safety',
            date: statuses.building_and_safety.changed_at
        };
    }
    if (statuses?.planning?.state === true) {
        return {
            status: 'Planning',
            date: statuses.planning.changed_at
        };
    }
    if (statuses?.submitted?.state === true) {
        return {
            status: 'Submitted',
            date: statuses.submitted.changed_at
        };
    }
    if (approval?.state === 'accepted') {
        return {
            status: "Project Accepted",
            date: approval.changed_at
        };
    }
    if (approval?.state === 'rejected') {
        return {
            status: "Project Rejected",
            date: approval.changed_at
        };
    }
    return {
        status: 'Project Received',
        date: project.date_created
    };
};

const ProjectTableRow = ({ project }) => {
    const navigate = useNavigate();

    //redux state
    const currentUser = useSelector(state => state.currentUser);

    const latestStatusData = getLatestData(project);
    console.log(latestStatusData);

    const {
        name,
        companyName,
        companyColor,
        date_created,
        location_street_address,
        location_city,
        location_state,
        location_zip,
        project_status,
        ownerContactName,
        latestStatus,
        latestStatusDate,
        expected_Plan_Check,
    } = project;

    const onViewClick = () => {
        navigate(`/projectoverview/${project._id}`);
    };
    const onConfirmClick = () => {
        navigate(`/projectdetails/${project._id}`);
    };

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    const getSubmittedDate = (project) => {
        const { progress_statuses } = project;
        if (progress_statuses?.submitted?.state === true) {
            if (progress_statuses?.submitted?.changed_at) return moment(progress_statuses.submitted.changed_at).format('ll');
        }
        return '-';
    };

    return (
        <tr>
            <td><div className="d-flex align-items-center"><span className="color-indicator" style={{ background: companyColor }}></span><span>{companyName}</span></div></td>
            <td><span className="btn-link--primary pointer" onClick={() => navigate(`/projectoverview/${project._id}`)}>{name}</span></td>
            <td>{location_street_address}</td>
            <td>{location_city}</td>
            {/* <td>{location_state}</td>
            <td>{location_zip}</td> */}
            <td>{expected_Plan_Check && moment(expected_Plan_Check).format('ll') || '-'}</td>
            <td>{moment(date_created).format('ll')}</td>
            <td>{getSubmittedDate(project)}</td>
            {/* <td>{moment(latestStatusData.date).format('lll')}</td> */}
            <td className='text-capitalize'>{latestStatusData.status}</td>
            <td>{ownerContactName}</td>
            <td style={{ width: '6%' }}>
                <span className="btn-link py-0 pointer" data-toggle="tooltip" data-trigger="hover" data-delay='{"show":50, "hide":50}' title="View Project" onClick={onViewClick}><Icon icon={eyeFill} width="18" height="18" /></span>
                {/* <span>
                    <CustomTooltip
                        id="view-tooltip"
                        placement="top"
                        content="View Project"
                    >
                        <span className="btn-link py-0 pointer" onClick={onViewClick}><Icon icon={eyeFill} width="18" height="18" /></span>
                    </CustomTooltip>
                </span> */}

                {isSystemAdmin() && <span className="btn-link py-0 pointer" data-toggle="tooltip" data-trigger="hover" data-delay='{"show":50, "hide":50}' title="Edit Project" onClick={onConfirmClick}><Icon icon={pencilSquare} width="18" height="18" /></span>}

                {/* {isSystemAdmin() && <span>
                    <CustomTooltip
                        id="edit-tooltip"
                        placement="top"
                        content="Review Proposal"
                    >
                        <span className="btn-link py-0 pointer" onClick={onConfirmClick}><Icon icon={pencilSquare} width="18" height="18" /></span>
                    </CustomTooltip>
                </span>} */}

            </td>
        </tr>
    );
};

export default ProjectTableRow;