import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

//actions
import { loadCurrentProject } from '../../../redux_actions/currentProjectActions';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import ProjectNavigationBar from '../../common/ProjectNavigationBar/ProjectNavigationBar';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import DocumentSection from './DocumentSection';
import { documentTitles } from '../../utils/constants';

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectsDocumentPage = () => {
    const { projectId } = useParams();

    //redux state
    const dispatch = useDispatch();

    //local state

    useEffect(() => {
        dispatch(loadCurrentProject(projectId));
    }, [projectId]);


    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">

                    <ProjectNavigationBar />

                    <Container fluid className="py-3">

                        <DocumentSection title={documentTitles.purchase_order} />
                        <DocumentSection title={documentTitles.owner_landloard_approval} />
                        <DocumentSection title={documentTitles.signed_plans} />
                        <DocumentSection title={documentTitles.title_24} />
                        <DocumentSection title={documentTitles.structural_calculations} />

                    </Container>

                </div>
            </main>

        </div>
    );
};

export default ProjectsDocumentPage;