import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, InputGroup, Table  } from 'react-bootstrap';

//comp
import CreateUserBasicForm from '../../../common/Forms/CreateUserBasicForm';
import server from '../../../../apis/server';
import { SketchPicker } from 'react-color';

const CreateClient = ({ showModal, setShowModal, setAdmins, admins }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);

    //local
    const [responseError, setResponseError] = useState('');

    const onModalHide = () => {
        setShowModal(false);
    };

    const onContinueClick = async (createUserFormData) => {
        try {
            const response = await server.post('/admins/create', { userData: createUserFormData, method: 'admin-portal' }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            // clearing response error from local state if any
            setResponseError('');

            // adding the newely created admin user to the UI admins list
            if (response.data._id) setAdmins([...admins, response.data]);

            //closing the Modal
            onModalHide();

        } catch (error) {
            console.log({ error: error.message });
            if (error.response.status === 409) {
                setResponseError('There is already an account associated with this email');
            } else {
                setResponseError('Something went wrong please try again');
            }
        }
    };

    return (
        <Modal show={showModal} onHide={onModalHide} size="xl" centered>
             <Modal.Header closeButton className="ps-md-4">
                <h2>Create Company</h2>
            </Modal.Header>

            <Modal.Body className="px-md-4">
                <Form>
                    <Row>
                        <Col lg={4}>
                            <fieldset>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Company Name</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Company Name"/>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Mailing Address (City, State ZIP)</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Mailing Address (City, State ZIP)" />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Contractor License Number</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Contractor License Number" />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>

                            <fieldset>
                                <label className="form-fieldset-title pb-4">Contact Information</label>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Phone Number</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Phone Number" />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Email Address</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email Address" />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>

                        </Col>

                        <Col lg={5}>
                            <fieldset className="pb-3">
                                <label className="form-fieldset-title pb-4 line-height-1">Letter of Authorization</label>
                                <Row>
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">File name (optional)</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="upload"
                                                    placeholder="File name" />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>

                            <fieldset>
                                <label className="form-fieldset-title pb-4">Workers Compensation Information</label>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Workers Compensation Company Name</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Workers Compensation Company Name" />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Workers Compensation Policy Number</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Workers Compensation Policy Number" />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Workers Compensation Expiration Date</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Workers Compensation Expiration Date" />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>

                        <Col lg="3">
                            <span className="d-block info-text pb-3">Pick a color for the company</span>

                            <SketchPicker />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <div className="ms-auto d-flex">
                    <Button variant="outline-primary" type="submit" className="ms-2">Cancel</Button>
                    <Button variant="primary" type="submit" className="ms-2">Create Company</Button>
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default CreateClient;