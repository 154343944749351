import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import server from '../../../apis/server';

class PDFUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
        };
    }

    singleFileChangedHandler = (event) => {

        //check if images already uploaded

        let file;

        let data = new FormData();

        if (event.target.files[0]) {
            file = event.target.files[0].name;

            if (this.props.uploadedFile) {
                this.props.setUploading(false);
                const existinfPdf = this.props.uploadedFile;
                if (existinfPdf.length > 0) return this.onShowAlert('Already uploaded', '#3089cf');
            }

            this.props.setUploading(true);
            this.props.setUploadError({});

            data.append('pdf-file', event.target.files[0], event.target.files[0].name);

            const headers = {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            };
            console.log(headers);
            console.log(data);

            server.post('/pdf-upload', data, {
                headers: headers
            })
                .then((response) => {
                    this.props.setUploading(false);
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                this.onShowAlert('Max size: 1MB', 'red');
                            } else {
                                // If not the given file type
                                this.onShowAlert(response.data.error, 'red');
                            }
                        } else {
                            // Success
                            this.props.onPDFUploadClick({ url: response.data.url, fileName: file });
                            this.onShowAlert('File Uploaded', '#3089cf');
                        }
                        this.props.setUploading(false);
                    }
                    this.fileInput.value = '';

                }).catch((error) => {
                    console.log(error);
                    // If another error
                    this.onShowAlert(error, 'red');
                    this.props.setUploading(false);
                    this.fileInput.value = '';
                });
        } else {
            // if file not selected throw error
            this.onShowAlert('Please upload file', 'red');
        }
    };

    // ShowAlert Function
    onShowAlert = (message, background = '#3089cf') => {
        this.props.setUploading(false);
        let errorHandler = this.props.setUploadError;
        errorHandler({ message, background });
        // setTimeout(function () {
        //     errorHandler({});
        // }, 3000);
    };

    render() {
        return (
            <div >
                <input type="file" accept=".pdf" onChange={this.singleFileChangedHandler} ref={fileInput => this.fileInput = fileInput} style={{ display: 'none' }} disabled={this.props.disabled ? this.props.disabled : false} />
                <span onClick={() => this.fileInput.click()} >
                    {this.props.trigger}
                </span>
            </div>
        );
    }
}

export default PDFUpload;
