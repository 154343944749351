import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const NoData = ({ onActionClick, message, actionLabel }) => {
    return (
        <Row>
            <Col md={12}>
                <div className="text-center py-4">
                    <img src={require('../../../images/no-data.svg').default} alt="No data found" className="img-fluid no-data-found-img" />
                    <p className="py-4">{message}</p>
                    {actionLabel && <Button variant="primary" type="submit" className="ms-2" onClick={onActionClick}>{actionLabel}</Button>}
                </div>
            </Col>
        </Row>
    );
};

export default NoData;