import React, { useEffect, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination, Container, Row, Col, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

//icons
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-bi/eye-fill';

//components
import NoData from '../../common/NoData/NoData';
import server from '../../../apis/server';

let active = 1;
let items = [];
for (let number = 1; number <= 1; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ClientUserProjects = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    //redux state
    const accessToken = useSelector(state => state.accessToken);

    //Searching and sorting state
    const [searchInput, setSearchInput] = useState('');
    const [search, setSearch] = useState('');

    const [projects, setProjects] = useState([]);

    const getProjects = async () => {
        try {
            const response = await server.get('/projects', {
                params: { userId: id },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) setProjects(response.data);

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        getProjects();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center px-3 pb-3">
                <h4 className="mb-0">User Projects</h4>
            </div>

            {projects?.length > 0
                ? <Container fluid>
                    <Row>
                        <Col>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Location
                                        </th>
                                        <th>
                                            Project Owner
                                        </th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects.map(project => {
                                        return (
                                            <tr key={project._id}>
                                                <td>{project.name}</td>
                                                <td>{project.location_city}, {project.location_state}, {project.location_zip}</td>
                                                <td>{project.ownerContactName}</td>
                                                <td>{project.latestStatus}</td>
                                                <td>
                                                    <span className="btn-link py-0 pointer" onClick={() => navigate(`/projectoverview/${project._id}`)}><Icon icon={eyeFill} width="18" height="18" /></span>
                                                </td>
                                            </tr>
                                        );
                                    })}


                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="d-flex justify-content-end">
                                <Pagination size="sm">{items}</Pagination>
                            </div>
                        </Col>
                    </Row>
                </Container>
                : <NoData
                    message={'No Projects assigned'}
                />
            }

        </div>
    );
};

export default ClientUserProjects;