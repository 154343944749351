import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, Spinner, Navbar, Nav, Button, Form, Table, Pagination } from 'react-bootstrap';
import { CSVLink } from "react-csv";

//icons
import { Icon } from '@iconify/react';
import baselineBuild from '@iconify/icons-ic/baseline-build';
import peopleFill from '@iconify/icons-bi/people-fill';
import roundGroupWork from '@iconify/icons-ic/round-group-work';
import flagFill from '@iconify/icons-bi/flag-fill';
import settingsFilled from '@iconify/icons-ci/settings-filled';
import filterIcon from '@iconify/icons-bi/filter';
import searchIcon from '@iconify/icons-bi/search';
import server from '../../../apis/server';

//Modals
import CreateSystemAdminUser from './modals/CreateSystemAdminUser';
import InviteSystemAdminUser from './modals/InviteSystemAdminUser';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import AdminUserTableRaw from './AdminUserTableRaw';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import useDebounce from '../../utils/useDebounce';

let active = 1;
let items = [];
for (let number = 1; number <= 1; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const SystemAdminUsers = () => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    //local state
    const [admins, setAdmins] = useState([]);
    const [showCreateSystemAdminUser, setShowCreateSystemAdminUser] = useState(false);
    const [showInviteSystemAdminUser, setShowInviteSystemAdminUser] = useState(false);

    const [loading, setLoading] = useState(false);

    //Searching and sorting state
    const [searchInput, setSearchInput] = useState('');
    // const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('lastActive');
    const [sortOrder, setSortOrder] = useState(-1);

    //csv ref
    const csvLink = useRef();

    const debouncedSearch = useDebounce(searchInput, 500);

    const getAdminUsers = async () => {
        try {
            setLoading(true);
            const response = await server.get('/admins', {
                params: { search: debouncedSearch, sortBy, sortOrder },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setLoading(false);
            if (response.data) setAdmins(response.data);
        } catch (error) {
            setLoading(false);
            console.log({ error: error.message });
        }
    };

    const onSearchClear = () => {
        setSearchInput('');
    };

    const onSortByChange = (sort) => {
        setSortBy(sort);
        setSortOrder(-1);
    };

    useEffect(() => {
        getAdminUsers();
    }, [debouncedSearch, sortBy, sortOrder]);

    const getCSVData = () => {
        if (!admins || admins.length === 0) return [];
        const data = admins.map(admin => {
            const { firstName, lastName, contactName, email, phoneNumber, updatedAt, active } = admin;
            return {
                Name: `${firstName} ${lastName}`,
                "Contact Name": contactName,
                Email: email,
                "Phone Number": phoneNumber,
                "Last Active": updatedAt,
                Status: active === true ? 'Active' : 'Not Active'
            };
        });
        return data;
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">
                    <div className="top-bar">
                        <h2>System Admin Users </h2>

                        <div className="top-bar--right">
                            <Button variant="outline-primary" type="submit" className="ms-2" onClick={() => csvLink.current.link.click()}>export <span className="text-uppercase">CSV</span></Button>
                            <Button variant="primary" className="ms-2" onClick={() => setShowInviteSystemAdminUser(true)}>Invite  System Admin</Button>
                            <Button variant="primary" className="ms-2" onClick={() => setShowCreateSystemAdminUser(true)}>Create System Admin</Button>

                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center p-3">
                        <div className="input-search" controlid="exampleForm.ControlInput1">
                            <span className="input-search--icon">
                                {!searchInput && !loading && < Icon className="pointer" icon={searchIcon} width="24" height="24" />}
                                {searchInput && !loading && <Icon className="pointer" icon="ic:round-clear" width="24" height="24" onClick={onSearchClear} />}
                                {loading && <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />}

                            </span>
                            <Form.Control type="text" placeholder="Search" value={searchInput} onChange={e => setSearchInput(e.target.value.trimStart())} />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-basic" className="primary">
                                    <Button variant="outline-secondary" size="sm">Sort By - {sortBy} <Icon icon={filterIcon} width="24" height="24" /></Button>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => onSortByChange('name')}>Sort by name</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('email')}>Sort by email</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('status')}>Sort by status</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('lastActive')}>Sort by last active</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div>
                                {sortOrder === 1 && <Icon icon="cil:sort-ascending" className="pointer" onClick={() => setSortOrder(-1)} />}
                                {sortOrder === -1 && <Icon icon="cil:sort-descending" className="pointer" onClick={() => setSortOrder(1)} />}
                            </div>
                        </div>
                    </div>

                    <Container className="py-3">
                        <Row>
                            <Col>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className={`pointer ${sortBy === 'name' ? 'text-theme' : ''}`} onClick={() => onSortByChange('name')}>Name</span>
                                                    {sortBy === 'name' && <div>
                                                        {sortOrder === 1 && <Icon icon="cil:sort-ascending" className="pointer" onClick={() => setSortOrder(-1)} />}
                                                        {sortOrder === -1 && <Icon icon="cil:sort-descending" className="pointer" onClick={() => setSortOrder(1)} />}
                                                    </div>}
                                                </div>
                                            </th>

                                            <th>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className={`pointer ${sortBy === 'email' ? 'text-theme' : ''}`} onClick={() => onSortByChange('email')}>Email</span>
                                                    {sortBy === 'email' && <div>
                                                        {sortOrder === 1 && <Icon icon="cil:sort-ascending" className="pointer" onClick={() => setSortOrder(-1)} />}
                                                        {sortOrder === -1 && <Icon icon="cil:sort-descending" className="pointer" onClick={() => setSortOrder(1)} />}
                                                    </div>}
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className={`pointer ${sortBy === 'status' ? 'text-theme' : ''}`} onClick={() => onSortByChange('status')}>Status</span>
                                                    {sortBy === 'status' && <div>
                                                        {sortOrder === 1 && <Icon icon="cil:sort-ascending" className="pointer" onClick={() => setSortOrder(-1)} />}
                                                        {sortOrder === -1 && <Icon icon="cil:sort-descending" className="pointer" onClick={() => setSortOrder(1)} />}
                                                    </div>}
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className={`pointer ${sortBy === 'lastActive' ? 'text-theme' : ''}`} onClick={() => onSortByChange('lastActive')}>Last active</span>
                                                    {sortBy === 'lastActive' && <div>
                                                        {sortOrder === 1 && <Icon icon="cil:sort-ascending" className="pointer" onClick={() => setSortOrder(-1)} />}
                                                        {sortOrder === -1 && <Icon icon="cil:sort-descending" className="pointer" onClick={() => setSortOrder(1)} />}
                                                    </div>}
                                                </div>
                                            </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {admins?.length > 0 && admins.map(admin => <AdminUserTableRaw key={admin._id} adminData={admin} admins={admins} setAdmins={setAdmins} />)}

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <Pagination size="sm">{items}</Pagination>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </main>

            <CreateSystemAdminUser showModal={showCreateSystemAdminUser} setShowModal={setShowCreateSystemAdminUser} setAdmins={setAdmins} admins={admins} />
            <InviteSystemAdminUser showModal={showInviteSystemAdminUser} setShowModal={setShowInviteSystemAdminUser} />

            {admins?.length > 0 && <CSVLink ref={csvLink} className="text-highliten d-none" data={getCSVData()} filename={`admin-users.csv`} style={{ textDecoration: 'none', color: 'white' }}></CSVLink>}
        </div>
    );
};

export default SystemAdminUsers;