export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const textRegex = /^[ A-Za-z0-9_.&?!’:',-/()\n]*$/;
export const numberRegex = /^[+]?\d+([.]\d+)?$/;
export const textRegexNoNumbers = /^[ A-Za-z_.&?!’:',-/()\n]*$/;

export const validateSmallText = (text, name) => {
    if (!text) return { valid: false, error: `Please enter ${name}` };
    if (text.length > 50) return { valid: false, error: `${name} must not exceed 50 charachters` };
    if (!textRegex.test(text)) return { valid: false, error: `${name} should not contain symbols` };
    return { valid: true, error: `` };
};

export const validateSmallTextNoNumbers = (text, name) => {
    if (!text) return { valid: false, error: `Please enter ${name}` };
    if (text.length > 50) return { valid: false, error: `${name} must not exceed 50 charachters` };
    if (!textRegexNoNumbers.test(text)) return { valid: false, error: `${name} should not contain symbols or numbers` };
    return { valid: true, error: `` };
};

export const validateSmallOptionalTextNoNumbers = (text, name) => {
    if (text?.length > 50) return { valid: false, error: `${name} must not exceed 50 charachters` };
    if (!textRegexNoNumbers.test(text)) return { valid: false, error: `${name} should not contain symbols or numbers` };
    return { valid: true, error: `` };
};

export const validateMessageInput = (text, name) => {
    if (text?.length > 300) return { valid: false, error: `${name} must not exceed 200 charachters` };
    if (!textRegex.test(text)) return { valid: false, error: `${name} should not contain symbols` };
    return { valid: true, error: `` };
};

export const validateRequiredMessageInput = (text, name) => {
    if (!text) return { valid: false, error: `Please enter ${name}` };
    // if (text.length > 300) return { valid: false, error: `${name} must not exceed 200 charachters` };
    // if (!textRegex.test(text)) return { valid: false, error: `${name} should not contain symbols` };
    return { valid: true, error: `` };
};

export const validateZipCode = (text, name) => {
    if (!name) name = 'Zip';
    if (!text) return { valid: false, error: `Please enter ${name}` };
    if (!zipRegex.test(text)) return { valid: false, error: `Not valid Zip code` };
    return { valid: true, error: `` };
};

export const validatePhoneNumber = (text, name) => {
    if (!name) name = 'phone number';
    if (!text) return { valid: false, error: `Please enter ${name}` };
    if (!phoneRegex.test(text)) return { valid: false, error: `Not valid phone number` };
    return { valid: true, error: `` };
};

export const validateSmallNumber = (text, name) => {
    if (!text) return { valid: false, error: `Please enter ${name}` };
    if (text.length > 50) return { valid: false, error: `${name} must not exceed 50 charachters` };
    if (!numberRegex.test(text)) return { valid: false, error: `${name} must be valid positive number` };
    return { valid: true, error: `` };
};

export const validateSmallOptionalNumber = (text, name) => {
    if (text.length > 50) return { valid: false, error: `${name} must not exceed 50 charachters` };
    if (!numberRegex.test(text)) return { valid: false, error: `${name} must be valid positive number` };
    return { valid: true, error: `` };
};

// export const onSmallTextChange = (text, name, label, setErrors, errors) => {
//     if (!text) return setErrors({...errors, [name]: `Please enter ${label}`});
//     if (text.length > 50) return { valid: false, error: `${name} must not exceed 50 charachters` };
//     if (!textRegex.test(text)) return { valid: false, error: `${name} should not contain symbols` };
//     return { valid: true, error: `` };
// };

