import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// redux actions
import { logoutUser } from '../../../redux_actions/userActions';
import { getProjectUnreadNotificationsCount } from '../../../redux_actions/projectNotificationsActions';

//icons
import { Icon } from '@iconify/react';
import angleDown from '@iconify/icons-la/angle-down';

const HeaderWithActions = () => {
    const navigate = useNavigate(); // React router navigation hook

    //redux
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    const onLogoutClick = () => {
        dispatch(logoutUser(), () => {
            navigate('/');
        });
        navigate('/');
    };

    useEffect(() => {
        let interval;
        if (currentCompany._id && currentProject._id) {
            dispatch(getProjectUnreadNotificationsCount());
            interval = setInterval(() => dispatch(getProjectUnreadNotificationsCount()), 3000);
        } else {
            dispatch({
                type: 'SET_PROJECT_UNREAD_NOTIFICATION',
                payload: 0
            });
        }

        return () => {
            if (interval) clearInterval(interval);
        };

    }, [currentCompany, currentProject]);

    return (
        <header className="header">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center justify-content-center">
                    <img src={require('../../../images/Tepia Co Logo.png')} alt="Stellar logo" className="img-fluid" style={{ width: 45, marginRight: 10 }} />
                    <p className='logo-font'>Ryan's Permit Services</p>
                </div>

                <Dropdown>
                    <Dropdown.Toggle variant="link" id="user-dropdown">
                        <div className="d-inline-flex align-items-center">
                            <span className="d-inline-block pe-2">{currentUser.firstName} {currentUser.lastName}</span>
                            <span className="dropdown-toggle--icon"><Icon icon={angleDown} /></span>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={onLogoutClick}>Logout</Dropdown.Item>
                        {/* <Dropdown.Item>Another action</Dropdown.Item>
                        <Dropdown.Item>Something else</Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>

            </div>
        </header>
    );
};

export default HeaderWithActions;