import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './css/style.scss';

//utils
import CheckStoredInfo from './utils/checkStoredData';

//importing components and css (local files)
import SignInPage from './pages/SignInPage/SignInPage';
import PasswordResetPage from './pages/PasswordResetPage/PasswordResetPage';
import CreateUserPage from './pages/CreateUserPage/CreateUserPage';
import SetNewPassPage from './pages/SetNewPassPage/SetNewPassPage';
import SystemAdminUsers from './pages/SystemAdminUsers/SystemAdminUsers';
import SystemAdminCreateProfile from './pages/SystemAdminCreateProfile/SystemAdminCreateProfile';
import ClientCreateProfile from './pages/ClientCreateProfile/ClientCreateProfile';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage';
import ProjectDetailsPage from './pages/ProjectDetailsPage/ProjectDetailsPage';
import ProjectsOverviewPage from './pages/ProjectsOverviewPage/ProjectsOverviewPage';
import CompanyListPage from './pages/CompanyListPage/CompanyListPage';
import ClientUsersPage from './pages/ClientUsersPage/ClientUsersPage';
import ClientUserDetailsPage from './pages/ClientUserDetailsPage/ClientUserDetailsPage';
import ProjectsDocumentPage from './pages/ProjectsDocumentPage/ProjectsDocumentPage';
import ProjectsNotesPage from './pages/ProjectsNotesPage/ProjectsNotesPage';
import ProjectsFeesPage from './pages/ProjectsFeesPage/ProjectsFeesPage';
import ProjectsTeamPage from './pages/ProjectsTeamPage/ProjectsTeamPage';
import ProjectsHoursPage from './pages/ProjectsHoursPage/ProjectsHoursPage';
import ProjectsNotificationsPage from './pages/ProjectsNotificationsPage/ProjectsNotificationsPage';
import SystemSettingsPage from './pages/SystemSettingsPage/SystemSettingsPage';
import SystemNotificationsPage from './pages/SystemNotificationsPage/SystemNotificationsPage';

//actions
import { getSystemUnreadNotificationsCount } from '../redux_actions/systemNotificationsActions';

const App = (props) => {
  //redux store
  const currentUser = useSelector(state => state.currentUser);
  const accessToken = useSelector(state => state.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    CheckStoredInfo(accessToken, dispatch);
  }, []);

  return (
    <div>
      <Routes>
        <Route path='/' exact element={currentUser?._id ? <ProjectsPage /> : <SignInPage />} />
        <Route path='/createuser' element={<CreateUserPage />} />
        <Route path='/resetpassword' element={<PasswordResetPage />} />
        <Route path='/setpassword' element={<SetNewPassPage />} />
        <Route path='/admins' element={<SystemAdminUsers />} />
        <Route path='/admininvite' element={<SystemAdminCreateProfile />} />
        <Route path='/clientinvite' element={<ClientCreateProfile />} />

        <Route path='/projects' element={<ProjectsPage />} />

        <Route path='/projectdetails/:projectId' element={<ProjectDetailsPage />} />
        <Route path='/projectoverview/:projectId' element={<ProjectsOverviewPage />} />
        <Route path='/projectsdocument/:projectId' element={<ProjectsDocumentPage />} />
        <Route path='/projectsnotes/:projectId' element={<ProjectsNotesPage />} />
        <Route path='/projectsfees/:projectId' element={<ProjectsFeesPage />} />
        <Route path='/projectsteam/:projectId' element={<ProjectsTeamPage />} />
        <Route path='/projectnotifications/:projectId' element={<ProjectsNotificationsPage />} />
        <Route path='/projectshours/:projectId' element={<ProjectsHoursPage />} />

        <Route path='/clients' element={<CompanyListPage />} />
        <Route path='/client/:id' element={<ClientUsersPage />} />
        <Route path='/user/:id' element={<ClientUserDetailsPage />} />

        <Route path='/settings' element={currentUser?.userRole === 'system_admin' ? <SystemSettingsPage /> : <ProjectsPage />} />
        <Route path='/notifications' element={<SystemNotificationsPage />} />

        <Route path="*" element={<Navigate to="/" />} />

      </Routes>
    </div>
  );
};


export default App;