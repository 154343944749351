/* eslint-disable import/no-anonymous-default-export */

const initialState = [];

export default (state = initialState, action) => {
    if (action.type === 'SET_DOCUMENTS') {
        return action.payload;
    }

    if (action.type === 'ADD_DOCUMENT') {
        return [...state, action.payload];
    }

    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};