import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button, Form, Card, InputGroup, Spinner, ButtonGroup } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import circleCheck from '@iconify/icons-ci/circle-check';
import twotoneAttachFile from '@iconify/icons-ic/twotone-attach-file';
import { useNavigate } from 'react-router-dom';
import { isEmail } from 'validator';
import server from '../../../apis/server';
import Select from 'react-select';

//validation functions
import { validateSmallTextNoNumbers, validateZipCode, validatePhoneNumber, validateSmallNumber } from '../../utils/customValidations';

//Comp
import PasswordInput from '../inputs/PasswordInput';
import PDFUpload from '../fileUpload/PDFUpload';

const CreateUserBasicForm = ({
    actionButtonLabel,
    onCreateUserClick,
    onCancelClick,
    infoTitle,
    disabledFields,
    invitedEmail,
    companyOptions,
    projectOptions,
    showUserRole,
    submitting, setSubmitting,
}) => {

    //React router hook that handles navigation
    let navigate = useNavigate();

    //redux state
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const dispatch = useDispatch();

    //local state
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [recieveNotifications, setRecieveNotifications] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordCriteria, setPasswordCriteria] = useState({});
    const [letterOfAuthorization, setLetterOfAuthorization] = useState({}); //after successful pdf upload this object will be => {fileName, url}

    //these options are made if the form is used in the create client modal
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedCompanyRole, setSelectedCompanyRole] = useState('user');

    //state for handling pdf upload
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState('');

    //handling Form Errors
    const [errors, setErrors] = useState('');
    const [responseError, setResponseError] = useState('');

    useEffect(() => {
        if (currentCompany._id) setSelectedCompany({ value: currentCompany._id, label: currentCompany.name });
    }, []);

    useEffect(() => {
        if (invitedEmail) setEmail(invitedEmail);
    }, [invitedEmail]);

    const onUploadClick = async (data) => {
        setLetterOfAuthorization(data);
    };

    // effect to validate inputs with thier change
    useEffect(() => {
        const newErrors = {};

        //validating inputs
        if (firstName && validateSmallTextNoNumbers(firstName, 'First Name').error) newErrors.firstName = validateSmallTextNoNumbers(firstName, 'First Name').error;
        if (lastName && validateSmallTextNoNumbers(lastName, 'Last Name').error) newErrors.lastName = validateSmallTextNoNumbers(lastName, 'Last Name').error;
        if (contactName && validateSmallTextNoNumbers(contactName, 'Contact Name').error) newErrors.contactName = validateSmallTextNoNumbers(contactName, 'Contact Name').error;

        if (phoneNumber && validatePhoneNumber(phoneNumber).error) newErrors.phoneNumber = validatePhoneNumber(phoneNumber).error;

        // if (!password) newErrors.password = 'Please enter a password';
        if (password && (!passwordCriteria || !passwordCriteria.isValidPassword)) newErrors.password = 'Please make sure the password meets all of the criteria above';

        if (email && !isEmail(email)) newErrors.email = 'Email is not valid';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});
    }, [firstName, lastName, contactName, phoneNumber, password, email]);


    const onContinueClick = async () => {
        const newErrors = {};

        //validating inputs
        if (validateSmallTextNoNumbers(firstName, 'First Name').error) newErrors.firstName = validateSmallTextNoNumbers(firstName, 'First Name').error;
        if (validateSmallTextNoNumbers(lastName, 'Last Name').error) newErrors.lastName = validateSmallTextNoNumbers(lastName, 'Last Name').error;
        if (validateSmallTextNoNumbers(contactName, 'Contact Name').error) newErrors.contactName = validateSmallTextNoNumbers(contactName, 'Contact Name').error;

        if (validatePhoneNumber(phoneNumber).error) newErrors.phoneNumber = validatePhoneNumber(phoneNumber).error;

        if (!password) newErrors.password = 'Please enter a password';
        else if (!passwordCriteria || !passwordCriteria.isValidPassword) newErrors.password = 'Please make sure the password meets all of the criteria above';

        if (!email) newErrors.email = 'Please enter email';
        else if (!isEmail(email)) newErrors.email = 'Email is not valid';

        if (companyOptions && !selectedCompany) newErrors.company = 'Please select a company';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        if (uploadError) return;

        try {

            const newUserData = {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                contactName: contactName.trim(),
                phoneNumber: phoneNumber.trim(),
                email: email.trim(),
                recieveNotifications: recieveNotifications,
                password: password,
                letterOfAuthorization: letterOfAuthorization,
                companyId: selectedCompany?.value ? selectedCompany.value : undefined,
                project: selectedProject?.value ? selectedProject.value : undefined,
            };
            if (showUserRole) newUserData.companyRole = selectedCompanyRole;

            onCreateUserClick(newUserData);

        } catch (error) {
            setSubmitting(false);

            console.log(error.message);
            console.log(error.response.status);

            if (error.response.status === 409) {
                setErrors({ email: 'There is already an account associated with this email' });
            } else {
                setErrors({ response: 'Something went wrong please try again' });
            }

        }
    };

    const isCompanyDisabled = () => {
        if (disabledFields?.includes('companies')) return true;
        return selectedCompany?.value ? false : true;
    };

    const isSystemAdminOrCompanySupperAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }

        if (currentCompany?._id) {
            if (currentCompany?.client_supper_admins?.includes(currentUser._id)) return true;
        }
        return false;
    };

    return (
        <Form>
            <fieldset className="pb-4">
                <label className="simple-title">{infoTitle}</label>
                <span className="d-block info-text pb-4">You can change this at any time later.</span>

                <Row>
                    <Col md={6}>
                        <div className="mb-3">
                            <InputGroup>
                                <Form.Label className="custom-form-label">First Name</Form.Label>
                                <Form.Control id="firstName" placeholder="First name" value={firstName} onChange={e => setFirstName(e.target.value.trimStart())}
                                    isInvalid={errors.firstName ? true : false}
                                />
                                {firstName && !errors.firstName && <InputGroup.Text id="basic-addon"><Icon icon={circleCheck} width="24" height="24" /></InputGroup.Text>}
                            </InputGroup>
                            {errors.firstName && <p className='text-red m-0 p-0 text-sm'>{errors.firstName}</p>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <InputGroup>
                                <Form.Label className="custom-form-label">Last Name</Form.Label>
                                <Form.Control id="lastName" placeholder="Last name" value={lastName}
                                    onChange={e => setLastName(e.target.value.trimStart())}
                                    isInvalid={errors.lastName ? true : false} />
                                {lastName && !errors.lastName && <InputGroup.Text id="basic-addon"><Icon icon={circleCheck} width="24" height="24" /></InputGroup.Text>}
                            </InputGroup>
                            {errors.lastName && <p className='text-red m-0 p-0  text-sm'>{errors.lastName}</p>}
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="mb-3">
                            <InputGroup>
                                <Form.Label className="custom-form-label">Contact Name</Form.Label>
                                <Form.Control id="contactName" placeholder="Contact name" value={contactName}
                                    onChange={e => setContactName(e.target.value.trimStart())}
                                    isInvalid={errors.contactName ? true : false} />
                                {contactName && !errors.contactName && <InputGroup.Text id="basic-addon"><Icon icon={circleCheck} width="24" height="24" /></InputGroup.Text>}
                            </InputGroup>
                            {errors.contactName && <p className='text-red m-0 p-0 text-sm'>{errors.contactName}</p>}
                        </div>
                    </Col>
                </Row>
            </fieldset>

            <fieldset className="pb-4">
                <label className="simple-title pb-4">Contact Information?</label>

                <Row>
                    <Col md={12}>
                        <div className="mb-3">
                            <InputGroup>
                                <Form.Label className="custom-form-label">Phone Number</Form.Label>
                                <Form.Control
                                    placeholder="Phone number"
                                    aria-label="Phone number"
                                    aria-describedby="basic-addon"
                                    value={phoneNumber}
                                    onChange={e => setPhoneNumber(e.target.value.trimStart())}
                                    isInvalid={errors.phoneNumber ? true : false}
                                />
                                {phoneNumber && !errors.phoneNumber && <InputGroup.Text id="basic-addon"><Icon icon={circleCheck} width="24" height="24" /></InputGroup.Text>}
                            </InputGroup>
                            {errors.phoneNumber && <p className='text-red m-0 p-0 text-sm'>{errors.phoneNumber}</p>}
                        </div>

                    </Col>
                    <Col md={12}>
                        <div className="mb-3">
                            <InputGroup>
                                <Form.Label className="custom-form-label">Email</Form.Label>
                                <Form.Control id="emailAddress" placeholder="Email address" value={email}
                                    onChange={e => setEmail(e.target.value.trimStart())}
                                    isInvalid={errors.email ? true : false} disabled={disabledFields?.includes('email')} />
                                {email && !errors.email && <InputGroup.Text id="basic-addon"><Icon icon={circleCheck} width="24" height="24" /></InputGroup.Text>}
                            </InputGroup>
                            {errors.email && <p className='text-red m-0 p-0 text-sm'>{errors.email}</p>}
                        </div>
                    </Col>
                    <Col md={12}>
                        <label className="checkbox">
                            <input type='checkbox' checked={recieveNotifications} onChange={e => setRecieveNotifications(e.target.checked)} />
                            <span className="checkbox-checkmark"></span>
                            <span className="checkbox-label">I would like to recieve notifications</span>
                        </label>
                    </Col>
                </Row>
            </fieldset>

            <fieldset className="pb-4">
                <label className="simple-title">Set Password</label>
                <span className="d-block info-text pb-4">Enter Password you will like to use for this account.</span>

                <Row>
                    <Col md={12}>
                        <div className="mb-3">
                            <InputGroup>
                                <PasswordInput
                                    password={password}
                                    setPassword={setPassword}
                                    setPasswordCriteria={setPasswordCriteria}
                                    passwordError={errors.password}
                                />
                            </InputGroup>
                            {errors.password && <p className='text-red m-0 p-0 text-sm'>{errors.password}</p>}
                        </div>

                    </Col>
                </Row>
            </fieldset>

            <fieldset className="pb-4">
                <label className="simple-title">Letter of Authorization</label>
                <span className="d-block info-text pb-4">This PDF is optional</span>

                <Row>
                    <Col md={12}>
                        <div className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder={letterOfAuthorization.fileName ? letterOfAuthorization.fileName : "please upload a pdf file"}
                                    aria-label="File name"
                                    aria-describedby="basic-addon-twotoneAttachFile"
                                    disabled
                                />
                                <InputGroup.Text id="basic-addon-twotoneAttachFile">
                                    <PDFUpload
                                        trigger={uploading ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <Icon className="pointer" icon={twotoneAttachFile} width="24" height="24" />}
                                        onPDFUploadClick={onUploadClick}
                                        setUploading={setUploading}
                                        setUploadError={setUploadError}
                                        uploadedFile={letterOfAuthorization}
                                    />

                                </InputGroup.Text>
                            </InputGroup>
                            {uploadError.message && <p className='text-red m-0 p-0 text-sm' style={{ color: uploadError.background }}>{uploadError.message}</p>}
                        </div>

                    </Col>
                </Row>
            </fieldset>

            {companyOptions && <fieldset className="pb-4">
                <label className="simple-title">Company Assignment</label>
                <Row className="pt-2">
                    <Col md={12}>
                        <div className="pb-3 position-relative">
                            <Select
                                placeholder="Select a company"
                                options={companyOptions}
                                value={selectedCompany}
                                onChange={company => setSelectedCompany(company)}
                                isDisabled={disabledFields?.includes('companies')}
                            />
                        </div>
                    </Col>
                </Row>
            </fieldset>}

            {projectOptions && <fieldset className="pb-4">
                <label className="simple-title">Project Assignment</label>
                <Row className="pt-2">
                    <Col md={12}>
                        <div className="pb-3 position-relative">
                            <Select
                                placeholder="Select a project"
                                options={projectOptions}
                                isDisabled={selectedCompany?.value ? false : true}
                                value={selectedProject}
                                onChange={project => console.log(project)}
                                isClearable
                            />
                        </div>
                    </Col>
                </Row>
            </fieldset>}

            {showUserRole && <Row className="mb-3">
                <Col md={12} className="pb-3">
                    <label className="simple-title">Select Role</label>
                    <div className="d-flex align-items-center justify-content-center pt-2">
                        <ButtonGroup aria-label="Basic example">
                            {/* {isSystemAdminOrCompanySupperAdmin() && <Button variant="outline-secondary" className={selectedCompanyRole === 'supper_admin' ? 'active' : ''} onClick={() => setSelectedCompanyRole('supper_admin')}>Supper Admin</Button>} */}
                            <Button variant="outline-secondary" className={selectedCompanyRole === 'admin' ? 'active' : ''} onClick={() => setSelectedCompanyRole('admin')}>Admin</Button>
                            <Button variant="outline-secondary" className={selectedCompanyRole === 'user' ? 'active' : ''} onClick={() => setSelectedCompanyRole('user')}>User</Button>
                        </ButtonGroup>

                        <div></div>
                    </div>
                </Col>
            </Row>}

            <Row>
                <Col md={6}>
                    <div className="mb-3">
                        <Button variant="outline-primary" className="w-100" onClick={onCancelClick} >Cancel</Button>
                    </div>
                </Col>

                <Col md={6}>
                    <div className="mb-3">
                        <Button variant="primary" className="w-100" onClick={onContinueClick} disabled={submitting}>{submitting ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : actionButtonLabel ? actionButtonLabel : 'Create Client'}</Button>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        {/* {Object.keys(errors).length > 0 && <p className='text-red m-0 p-0 text-sm'>Please fix errors</p>} */}
                        {errors.response && <p className='text-red m-0 p-0 text-sm'>{errors.response}</p>}
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default CreateUserBasicForm;