import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, InputGroup, Table } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import server from '../../../../apis/server';
import Select from 'react-select';
import { isEmail } from 'validator';

//constants
import { documentTitles } from '../../../utils/constants';

//comp
import PDFUpload from '../../../common/fileUpload/PDFUpload';

//validation functions
import { validateSmallTextNoNumbers, validateZipCode, validatePhoneNumber, validateSmallOptionalNumber, validateSmallText, validateRequiredMessageInput, validateMessageInput } from '../../../utils/customValidations';

//icons
import { Icon } from '@iconify/react';
import circleCheck from '@iconify/icons-ci/circle-check';
import twotoneAttachFile from '@iconify/icons-ic/twotone-attach-file';
import asteriskIcon from '@iconify/icons-bi/asterisk';


const CreateProject = ({ showModal, setShowModal, getProjects }) => {
    const navigate = useNavigate();

    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);

    //local store
    const [name, setName] = useState('');
    const [companyOptions, setCompanyOptions] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [clientsOptions, setClientsOptions] = useState([]);
    const [selectedProjectOwner, setSelectedProjectOwner] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState([]);

    const [locationStreetAddress, setLocationStreetAddress] = useState('');
    const [locationCity, setLocationCity] = useState('');
    const [locationState, setLocationState] = useState('');
    const [locationZip, setLocationZip] = useState('');


    const [propertyOwnerName, setPropertyOwnerName] = useState('');
    const [propertyOwnerStreetAddress, setPropertyOwnerStreetAddress] = useState('');
    const [propertyOwnerCity, setPropertyOwnerCity] = useState('');
    const [propertyOwnerState, setPropertyOwnerState] = useState('');
    const [propertyOwnerZip, setPropertyOwnerZip] = useState('');
    const [propertyOwnerPhoneNumber, setPropertyOwnerPhoneNumber] = useState('');
    const [propertyOwnerEmail, setPropertyOwnerEmail] = useState('');

    const [PONumber, setPONumber] = useState('');
    const [scopeOfWork, setScopeOfWork] = useState('');
    const [additionalNotes, setAdditionalNotes] = useState('');

    //Upload docs state
    const [poDocument, setPoDocument] = useState({});
    const [landloardApprovalDocument, setLandloardApprovalDocument] = useState({});
    const [signedPlansDocument, setSignedPlansDocument] = useState({});
    const [title24Document, setTitle24Document] = useState({});

    //state for handling pdf upload
    const [uploadingPO, setUploadingPO] = useState(false);
    const [uploadingLandloard, setUploadingLandloard] = useState(false);
    const [uploadingSignedPlans, setUploadingSignedPlans] = useState(false);
    const [uploadingTitle24, setUploadingTitle24] = useState(false);
    const [uploadPDFError, setUploadPDFError] = useState('');

    //handling Form Errors
    const [errors, setErrors] = useState('');
    const [responseError, setResponseError] = useState('');

    //state of weather the form is submitting
    const [submitting, setSubmitting] = useState(false);

    const onModalHide = () => {
        setShowModal(false);
        resetData();
    };

    const resetData = () => {
        //local store
        setName('');
        setSelectedCompany(null);
        setSelectedProjectOwner(null);
        setSelectedTeam([]);

        setLocationStreetAddress('');
        setLocationCity('');
        setLocationState('');
        setLocationZip('');


        setPropertyOwnerName('');
        setPropertyOwnerStreetAddress('');
        setPropertyOwnerCity('');
        setPropertyOwnerState('');
        setPropertyOwnerZip('');
        setPropertyOwnerPhoneNumber('');
        setPropertyOwnerEmail('');

        setPONumber('');
        setScopeOfWork('');
        setAdditionalNotes('');

        //Upload docs state
        setPoDocument({});
        setLandloardApprovalDocument({});
        setSignedPlansDocument({});
        setTitle24Document({});

        //state for handling pdf upload
        setUploadingPO(false);
        setUploadingLandloard(false);
        setUploadingSignedPlans(false);
        setUploadingTitle24(false);
        setUploadPDFError('');

        //handling Form Errors
        setErrors('');
        setResponseError('');
    };

    const getCompaniesOptions = async () => {
        try {
            const response = await server.get('/companies', {
                // params: { search: debouncedSearch, sortBy, sortOrder },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) {
                setCompanyOptions(response.data.map(el => {
                    return { ...el, label: el.name, value: el._id };
                }));
            }
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const getClientsOptions = async () => {
        try {
            const response = await server.get('/users', {
                // params: { search: debouncedSearch, sortBy, sortOrder },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) {
                setClientsOptions(response.data.map(el => {
                    return { ...el, label: `${el.firstName} ${el.lastName}`, value: el._id };
                }));
            }
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        getCompaniesOptions();
        getClientsOptions();
    }, []);

    const onAddProjectClick = async () => {


        const newErrors = {};

        if (!selectedCompany) newErrors.company = 'Please select project’s company';
        if (!selectedProjectOwner) newErrors.projectOwner = "Please enter project’s owner";
        if (!selectedTeam || selectedTeam?.length === 0) newErrors.selectedTeam = "Please select project team member(s)";
        if (validateSmallText(name, 'Project Name').error) newErrors.name = validateSmallText(name, 'Project Name').error;
        if (validateSmallTextNoNumbers(propertyOwnerName, 'Property Owner Name').error) newErrors.propertyOwnerName = validateSmallTextNoNumbers(propertyOwnerName, 'Property Owner Name').error;

        if (validateSmallText(locationStreetAddress, 'Street Address').error) newErrors.locationStreetAddress = validateSmallText(locationStreetAddress, 'Street Address').error;
        if (validateSmallTextNoNumbers(locationCity, 'City').error) newErrors.locationCity = validateSmallTextNoNumbers(locationCity, 'City').error;
        if (validateSmallTextNoNumbers(locationState, 'State').error) newErrors.locationState = validateSmallTextNoNumbers(locationState, 'State').error;
        if (validateZipCode(locationZip).error) newErrors.locationZip = validateZipCode(locationZip).error;


        if (validateSmallText(propertyOwnerStreetAddress, 'Street Address').error) newErrors.propertyOwnerStreetAddress = validateSmallText(propertyOwnerStreetAddress, 'Street Address').error;
        if (validateSmallTextNoNumbers(propertyOwnerCity, 'City').error) newErrors.propertyOwnerCity = validateSmallTextNoNumbers(propertyOwnerCity, 'City').error;
        if (validateSmallTextNoNumbers(propertyOwnerState, 'State').error) newErrors.propertyOwnerState = validateSmallTextNoNumbers(propertyOwnerState, 'State').error;
        if (validateZipCode(propertyOwnerZip).error) newErrors.propertyOwnerZip = validateZipCode(propertyOwnerZip).error;
        if (validatePhoneNumber(propertyOwnerPhoneNumber).error) newErrors.propertyOwnerPhoneNumber = validatePhoneNumber(propertyOwnerPhoneNumber).error;

        if (PONumber && validateSmallOptionalNumber(PONumber, 'PO Number').error) newErrors.PONumber = validateSmallOptionalNumber(PONumber, 'PO Number').error;
        if (validateRequiredMessageInput(scopeOfWork, 'Scope Of Work').error) newErrors.scopeOfWork = validateRequiredMessageInput(scopeOfWork, 'Scope Of Work').error;
        if (validateMessageInput(additionalNotes, 'Additional Notes').error) newErrors.additionalNotes = validateMessageInput(additionalNotes, 'Additional Notes').error;

        if (!landloardApprovalDocument.url || !signedPlansDocument.url) newErrors.documents = 'Please Upload the required documents';

        if (!propertyOwnerEmail) newErrors.propertyOwnerEmail = 'Please enter property owner email';
        else if (!isEmail(propertyOwnerEmail)) newErrors.propertyOwnerEmail = 'Property owner email is not valid';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        //local store


        const projectData = {
            name,
            companyId: selectedCompany._id,
            project_owner: selectedProjectOwner._id,
            selectedTeam: selectedTeam.map(el => el._id),

            locationStreetAddress,
            locationCity,
            locationState,
            locationZip,

            propertyOwnerName,
            propertyOwnerStreetAddress,
            propertyOwnerCity,
            propertyOwnerState,
            propertyOwnerZip,
            propertyOwnerPhoneNumber,
            propertyOwnerEmail,

            PONumber,
            scopeOfWork,
            additionalNotes,

            //documents
            poDocument,
            landloardApprovalDocument,
            signedPlansDocument,
            title24Document
        };

        try {
            setSubmitting(true);

            const response = await server.post('/project/create', projectData, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setSubmitting(false);
            if (response.data) {
                getProjects();
                onModalHide();
            }
        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });
        }
    };

    const getUsersOptions = () => {
        if (!selectedCompany?._id) return [];
        if (!clientsOptions || clientsOptions.length === 0) return [];
        let ids = [...selectedCompany.client_admins, ...selectedCompany.client_supper_admins, ...selectedCompany.client_users];
        return clientsOptions.filter(el => ids.includes(el._id));
    };

    return (
        <Modal show={showModal} onHide={onModalHide} size="xl" centered>
            <Modal.Header closeButton className="ps-md-4">
                <h2>Create Project</h2>
            </Modal.Header>

            <Modal.Body className="px-md-4">
                <Form>
                    <Row>
                        <Col lg={5}>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="pb-3 position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Company</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <Select
                                            placeholder="Select a company"
                                            options={companyOptions}
                                            value={selectedCompany}
                                            onChange={company => {
                                                setSelectedProjectOwner(null);
                                                setSelectedTeam([]);
                                                setSelectedCompany(company);
                                            }}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                                input: provided => ({ ...provided, paddingTop: '5px', paddingBottom: '5px' })
                                            }}
                                            isClearable
                                        // isDisabled={disabledFields?.includes('companies')}
                                        />
                                        {errors.company && <p className='text-red m-0 p-0 text-sm'>{errors.company}</p>}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="pb-3 position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Project Name</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                placeholder="Project Name"
                                                aria-label="Project Name"
                                                value={name} onChange={e => setName(e.target.value.trimStart())}
                                            />
                                        </InputGroup>
                                        {errors.name && <p className='text-red m-0 p-0 text-sm'>{errors.name}</p>}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="pb-3 position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Project Owner</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <Select
                                            placeholder={!selectedCompany?._id ? "Select a company to enable this option" : "Select project owner"}
                                            options={getUsersOptions()}
                                            value={selectedProjectOwner}
                                            onChange={user => setSelectedProjectOwner(user)}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                                input: provided => ({ ...provided, paddingTop: '5px', paddingBottom: '5px' })
                                            }}
                                            isDisabled={!selectedCompany?._id}
                                            isClearable
                                        />
                                        {errors.projectOwner && <p className='text-red m-0 p-0 text-sm'>{errors.projectOwner}</p>}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="pb-3 position-relative">
                                        {/* <Form.Label className="custom-form-label">Assigned Team</Form.Label> */}
                                        <label className="pb-1">Assigned Team <span className='text-red text-lg'>*</span><span className='text-sm text-green' style={{ marginLeft: 10 }}>{selectedTeam?.length > 0 ? `(${selectedTeam.length} selected)` : ''}</span></label>
                                        <Select
                                            isMulti
                                            placeholder={!selectedCompany?._id ? "Select a company to enable this option" : "Select project team member(s)"}
                                            options={getUsersOptions()}
                                            value={selectedTeam}
                                            onChange={user => setSelectedTeam(user)}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                                input: provided => ({ ...provided, paddingTop: '5px', paddingBottom: '5px' }),
                                                // multiValueLabel: provided => ({ ...provided, background: '#019EB2', color: 'white' }),
                                                // multiValueRemove: provided => ({ ...provided, background: '#019EB2', color: 'white' }),

                                            }}
                                            isDisabled={!selectedCompany?._id}
                                        />
                                        {errors.selectedTeam && <p className='text-red m-0 p-0 text-sm'>{errors.selectedTeam}</p>}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="pb-3 position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Property Owner Name</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                placeholder="Property Owner Name"
                                                aria-label="Property Owner Name"
                                                value={propertyOwnerName} onChange={e => setPropertyOwnerName(e.target.value.trimStart())}
                                            />
                                        </InputGroup>
                                        {errors.propertyOwnerName && <p className='text-red m-0 p-0 text-sm'>{errors.propertyOwnerName}</p>}
                                    </div>
                                </Col>
                            </Row>

                            <label className="pb-3">Property Owner Address</label>
                            <Row className="mb-3 pb-3">
                                <Col md={12} className="mb-3">
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Street Address</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Street Address"
                                                value={propertyOwnerStreetAddress} onChange={e => setPropertyOwnerStreetAddress(e.target.value.trimStart())}
                                                isInvalid={errors.propertyOwnerStreetAddress ? true : false}
                                            />
                                        </InputGroup>
                                    </div>
                                    {errors.propertyOwnerStreetAddress && <p className='text-red m-0 p-0'>- {errors.propertyOwnerStreetAddress}</p>}
                                </Col>
                                <Col md={4}>
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>City</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="city"
                                                value={propertyOwnerCity} onChange={e => setPropertyOwnerCity(e.target.value.trimStart())}
                                                isInvalid={errors.propertyOwnerCity ? true : false}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>State</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="state"
                                                value={propertyOwnerState} onChange={e => setPropertyOwnerState(e.target.value.trimStart())}
                                                isInvalid={errors.propertyOwnerState ? true : false}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Zip</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number"
                                                placeholder="zip"
                                                value={propertyOwnerZip} onChange={e => setPropertyOwnerZip(e.target.value.trimStart())}
                                                isInvalid={errors.propertyOwnerZip ? true : false}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    {errors.propertyOwnerCity && <p className='text-red m-0 p-0'>- {errors.propertyOwnerCity}</p>}
                                    {errors.propertyOwnerState && <p className='text-red m-0 p-0'>- {errors.propertyOwnerState}</p>}
                                    {errors.propertyOwnerZip && <p className='text-red m-0 p-0'>- {errors.propertyOwnerZip}</p>}
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="pb-3 position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Property Owner Phone Number</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                placeholder="Property Owner Phone Number"
                                                aria-label="Property Owner Phone Number"
                                                value={propertyOwnerPhoneNumber} onChange={e => setPropertyOwnerPhoneNumber(e.target.value.trimStart())}
                                            />
                                        </InputGroup>
                                        {errors.propertyOwnerPhoneNumber && <p className='text-red m-0 p-0'>- {errors.propertyOwnerPhoneNumber}</p>}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <div className="pb-3 position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Property Owner Email</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                placeholder="Property Owner Email"
                                                aria-label="Property Owner Email"
                                                value={propertyOwnerEmail} onChange={e => setPropertyOwnerEmail(e.target.value.trimStart())}
                                            />
                                        </InputGroup>
                                        {errors.propertyOwnerEmail && <p className='text-red m-0 p-0'>- {errors.propertyOwnerEmail}</p>}
                                    </div>
                                </Col>
                            </Row>

                        </Col>

                        <Col lg={7}>

                            <label className="pb-3">Project Address</label>
                            <Row className="mb-3 pb-3">
                                <Col md={12} className="mb-3">
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Street Address</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Street Address"
                                                value={locationStreetAddress} onChange={e => setLocationStreetAddress(e.target.value.trimStart())}
                                                isInvalid={errors.locationStreetAddress ? true : false}
                                            />
                                        </InputGroup>
                                    </div>
                                    {errors.locationStreetAddress && <p className='text-red m-0 p-0'>- {errors.locationStreetAddress}</p>}
                                </Col>
                                <Col md={4}>
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>City</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="city"
                                                value={locationCity} onChange={e => setLocationCity(e.target.value.trimStart())}
                                                isInvalid={errors.locationCity ? true : false}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label">State</Form.Label>
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>State</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="state"
                                                value={locationState} onChange={e => setLocationState(e.target.value.trimStart())}
                                                isInvalid={errors.locationState ? true : false}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Zip</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number"
                                                placeholder="zip"
                                                value={locationZip} onChange={e => setLocationZip(e.target.value.trimStart())}
                                                isInvalid={errors.locationZip ? true : false}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    {errors.locationCity && <p className='text-red m-0 p-0'>- {errors.locationCity}</p>}
                                    {errors.locationState && <p className='text-red m-0 p-0'>- {errors.locationState}</p>}
                                    {errors.locationZip && <p className='text-red m-0 p-0'>- {errors.locationZip}</p>}
                                </Col>
                            </Row>

                            <Row className='mb-4'>
                                <Col md={12}>
                                    <h4>Prerequisite PDFs</h4>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Document Title</th>
                                                <th>Uploaded</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Purchase order
                                                    <p></p>
                                                </td>
                                                <td>
                                                    {poDocument.url
                                                        ? <span className="active">Yes</span>
                                                        : <span className="text-grey">No</span>
                                                    }

                                                </td>
                                                <td className='d-flex align-items-center justify-content-around'>
                                                    {poDocument.url
                                                        ? <a className="btn-link--primary pointer" href={poDocument.url}>View</a>
                                                        : <span className="text-grey">View</span>
                                                    }

                                                    <PDFUpload
                                                        trigger={uploadingPO ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className="btn-link--primary pointer">Upload</span>}
                                                        onPDFUploadClick={data => setPoDocument(data)}
                                                        setUploading={setUploadingPO}
                                                        setUploadError={setUploadPDFError}
                                                        uploadedFile={poDocument}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Owner / Landloard Approval <span className='text-red text-lg'>*</span></td>
                                                <td>
                                                    {landloardApprovalDocument.url
                                                        ? <span className="active">Yes</span>
                                                        : <span className="text-grey">No</span>
                                                    }

                                                </td>
                                                <td className='d-flex align-items-center justify-content-around'>
                                                    {landloardApprovalDocument.url
                                                        ? <a className="btn-link--primary pointer" href={landloardApprovalDocument.url}>View</a>
                                                        : <span className="text-grey">View</span>
                                                    }

                                                    <PDFUpload
                                                        trigger={uploadingLandloard ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className="btn-link--primary pointer">Upload</span>}
                                                        onPDFUploadClick={data => setLandloardApprovalDocument(data)}
                                                        setUploading={setUploadingLandloard}
                                                        setUploadError={setUploadPDFError}
                                                        uploadedFile={landloardApprovalDocument}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Signed Plans <span className='text-red text-lg'>*</span></td>
                                                <td>
                                                    {signedPlansDocument.url
                                                        ? <span className="active">Yes</span>
                                                        : <span className="text-grey">No</span>
                                                    }

                                                </td>
                                                <td className='d-flex align-items-center justify-content-around'>
                                                    {signedPlansDocument.url
                                                        ? <a className="btn-link--primary pointer" href={signedPlansDocument.url}>View</a>
                                                        : <span className="text-grey">View</span>
                                                    }

                                                    <PDFUpload
                                                        trigger={uploadingSignedPlans ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className="btn-link--primary pointer">Upload</span>}
                                                        onPDFUploadClick={data => setSignedPlansDocument(data)}
                                                        setUploading={setUploadingSignedPlans}
                                                        setUploadError={setUploadPDFError}
                                                        uploadedFile={signedPlansDocument}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Title 24</td>
                                                <td>
                                                    {title24Document.url
                                                        ? <span className="active">Yes</span>
                                                        : <span className="text-grey">No</span>
                                                    }

                                                </td>
                                                <td className='d-flex align-items-center justify-content-around'>
                                                    {title24Document.url
                                                        ? <a className="btn-link--primary pointer" href={title24Document.url}>View</a>
                                                        : <span className="text-grey">View</span>
                                                    }

                                                    <PDFUpload
                                                        trigger={uploadingTitle24 ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className="btn-link--primary pointer">Upload</span>}
                                                        onPDFUploadClick={data => setTitle24Document(data)}
                                                        setUploading={setUploadingTitle24}
                                                        setUploadError={setUploadPDFError}
                                                        uploadedFile={title24Document}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    {errors.documents && <p className='text-red m-0 p-0 text-sm'>{errors.documents}</p>}
                                    {uploadPDFError.message && uploadPDFError.message !== 'File Uploaded' && <p className='text-red m-0 p-0 text-sm'>{uploadPDFError.message}</p>}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12} className="mb-3">
                                    <div className="pb-3 position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>PO Number</span>
                                                {/* <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span> */}
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number"
                                                placeholder="PO Number"
                                                aria-label="PO Number"
                                                value={PONumber} onChange={e => setPONumber(e.target.value.trimStart())}
                                            />
                                        </InputGroup>
                                        {errors.PONumber && <p className='text-red m-0 p-0 text-sm'>{errors.PONumber}</p>}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12} className="mb-3">
                                    <div className="pb-3 position-relative">
                                        <Form.Label className="custom-form-label-required">
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-5'>Scope of Work</span>
                                                <span className='text-red' style={{ fontSize: 18, marginLeft: 5 }}>*</span>
                                            </div>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control as="textarea" rows={2}
                                                placeholder="Scope of Work"
                                                aria-label="Scope of Work"
                                                value={scopeOfWork} onChange={e => setScopeOfWork(e.target.value.trimStart())}
                                            />
                                        </InputGroup>
                                        {errors.scopeOfWork && <p className='text-red m-0 p-0 text-sm'>{errors.scopeOfWork}</p>}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12} className="mb-3">
                                    <div className="position-relative">
                                        <Form.Label className="custom-form-label">Additional Notes</Form.Label>
                                        <InputGroup>
                                            <Form.Control as="textarea" rows={2}
                                                placeholder="Additional Notes"
                                                aria-label="Additional Notes"
                                                value={additionalNotes} onChange={e => setAdditionalNotes(e.target.value.trimStart())}
                                            />
                                        </InputGroup>
                                        {errors.additionalNotes && <p className='text-red m-0 p-0 text-sm'>{errors.additionalNotes}</p>}
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer className='content-start'>
                <div className='d-flex align-items-center justify-content-center'>
                    <Icon icon={asteriskIcon} style={{ fontSize: 12, marginRight: 10, color: 'rgb(198, 58, 58)' }} />
                    <span>Required fields</span>
                </div>
                <div className="d-flex justify-content-between">
                    <div>
                        <Button variant="outline-primary" className="ms-2" onClick={onModalHide}>Cancel</Button>
                        <Button variant="primary" className="ms-2" onClick={onAddProjectClick} disabled={submitting}>{submitting ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : 'Submit project proposal'}</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default CreateProject;;