import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, Button, Table, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import server from '../../../apis/server';

//action
import { addDocument } from '../../../redux_actions/documentsActions';
import { updateFinalizedDocuments } from '../../../redux_actions/currentProjectActions';

//constants
import { documentTitles } from '../../utils/constants';

//comps
import PDFUpload from '../../common/fileUpload/PDFUpload';
import NoData from '../../common/NoData/NoData';

const DocumentSection = ({ title }) => {
    const navigate = useNavigate();
    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const documents = useSelector(state => state.documents);
    const dispatch = useDispatch();

    //local state
    const [filteredDocuments, setFilteredDocuments] = useState([]);

    const [uploading, setUploading] = useState('');
    const [uploadPDFError, setUploadPDFError] = useState('');

    useEffect(() => {
        if (documents?.length > 0) {
            const docs = documents.filter(el => el.title === title).sort((a, b) => b.version - a.version);
            if (docs?.length > 0) setFilteredDocuments(docs);
        }
    }, [documents]);

    const onUpload = async (data) => {
        try {

            const documentData = {
                companyId: currentCompany._id,
                project_id: currentProject._id,
                title,
                file_name: data.fileName,
                url: data.url,
            };

            const response = await server.post('/documents/upload', documentData, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data._id) {
                dispatch(addDocument(response.data));
            }

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const onFinalizedChange = async (e) => {
        try {
            const response = await server.post('/project/updatefinalizeddocuments', { projectId: currentProject._id, title, state: e.target.checked }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) dispatch(updateFinalizedDocuments(response.data));
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const isDocumentFinalized = () => {
        if (!currentProject) return false;
        if (!currentProject.finalized_documents) return false;
        if (currentProject.finalized_documents[title] && currentProject.finalized_documents[title].state !== undefined) return currentProject.finalized_documents[title].state;
        return false;
    };

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    return (
        <Row className="py-4">
            <Col>
                <Card>
                    <Card.Header>
                        <h4 className="mb-0">{title}</h4>
                    </Card.Header>
                    <Card.Body>
                        <Row>

                            <Col md={12}>
                                {filteredDocuments?.length > 0
                                    ? <Table bordered striped>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '40%' }}>{title}</th>
                                                <th>Version</th>
                                                <th>Uploaded Date and Time</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {filteredDocuments?.length > 0 && filteredDocuments.map(document => {
                                                const { title, version, uploaded_by, createdAt, url } = document;

                                                return (
                                                    <tr>
                                                        <td>{title}</td>
                                                        <td>{version}</td>
                                                        <td>{moment(createdAt).format('lll')}</td>
                                                        <td><a href={url} className="btn-link--primary">View</a></td>
                                                    </tr>
                                                );
                                            })

                                            }


                                        </tbody>
                                    </Table>
                                    : <NoData message={'No documents uploaded'} />
                                }


                                <div className="d-flex justify-content-between align-items-center">
                                    <label className="checkbox">
                                        <input type='checkbox' onClick={onFinalizedChange} checked={isDocumentFinalized()} disabled={!isSystemAdmin()} />
                                        <span className="checkbox-checkmark"></span>
                                        <span className="checkbox-label">Finalize document</span>
                                    </label>
                                    <PDFUpload
                                        trigger={uploading ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <Button variant="primary" size="sm" type="submit" className="ms-2" disabled={isDocumentFinalized()}>Upload documents</Button>}
                                        onPDFUploadClick={onUpload}
                                        setUploading={setUploading}
                                        setUploadError={setUploadPDFError}
                                        disabled={isDocumentFinalized()}
                                    />

                                </div>
                            </Col>

                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default DocumentSection;