import React, { useState } from 'react';
import moment from 'moment';

//Modals
import EditSystemAdminUser from './modals/EditSystemAdminUser';

const AdminUserTableRaw = ({ adminData, admins, setAdmins }) => {
    //local state
    const [showEditSystemAdminUser, setShowEditSystemAdminUser] = useState(false);

    const { email, firstName, lastName, active, updatedAt } = adminData;

    return (
        <tr>
            <td>{firstName} {lastName}</td>
            <td>{email}</td>
            <td>{active ? 'Active' : 'Not Active'}</td>
            <td>{moment(updatedAt).format('lll')}</td>
            <td><span className="btn-link--primary pointer" onClick={() => setShowEditSystemAdminUser(true)}>View</span></td>
            <EditSystemAdminUser showModal={showEditSystemAdminUser} setShowModal={setShowEditSystemAdminUser} userData={adminData} admins={admins} setAdmins={setAdmins} />
        </tr>
    );
};

export default AdminUserTableRaw;