import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, InputGroup, Table } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import circleCheck from '@iconify/icons-ci/circle-check';
import twotoneAttachFile from '@iconify/icons-ic/twotone-attach-file';
//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//validation functions
import { validateSmallTextNoNumbers, validateZipCode, validatePhoneNumber, validateSmallNumber } from '../../../utils/customValidations';

//comps
import PDFUpload from '../../../common/fileUpload/PDFUpload';
import server from '../../../../apis/server';

const EditProjectStatuses = ({ showModal, setShowModal, getFees, fee }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local
    const [note, setNote] = useState('');
    const [amount, setAmount] = useState('');
    const [log_date, set_log_date] = useState('');
    const [receipt, setReceipt] = useState('');

    //state for handling pdf upload
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState('');

    //handling Form Errors
    const [errors, setErrors] = useState('');
    const [responseError, setResponseError] = useState('');

    const onModalHide = () => {
        if (!fee) {
            setAmount('');
            set_log_date('');
            setNote('');
            setReceipt('');
        }
        setShowModal(false);
        setUploadError('');
        setErrors('');
    };

    useEffect(() => {
        if (fee) {
            setAmount(fee.amount);
            set_log_date(new Date());
            setNote(fee.note);
            setReceipt(fee.receipt);
        }
    }, [fee]);

    // effect to validate inputs with thier change
    useEffect(() => {
        const newErrors = {};

        //validating inputs
        if (note && validateSmallTextNoNumbers(note, 'Note').error) newErrors.note = validateSmallTextNoNumbers(note, 'Note').error;
        if (amount && validateSmallNumber(amount, 'Fee Amount').error) newErrors.amount = validateSmallNumber(amount, 'Fee Amount').error;

        if (log_date) newErrors.log_date = undefined;

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});
    }, [note, amount, log_date]);


    const onAddClick = async () => {
        const newErrors = {};

        //validating inputs
        if (validateSmallTextNoNumbers(note, 'Note').error) newErrors.note = validateSmallTextNoNumbers(note, 'Note').error;
        if (validateSmallNumber(amount, 'Fee Amount').error) newErrors.amount = validateSmallNumber(amount, 'Fee Amount').error;

        if (!log_date) newErrors.log_date = 'Please select log date';


        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        try {

            const logData = {
                companyId: currentCompany._id,
                project_id: currentProject._id,
                note: note.trim(),
                amount,
                log_date,
                receipt: receipt,
                paid: false
            };

            if (fee) logData.id = fee._id;

            const response = await server.post('/fees/add', logData, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            console.log(response.data);

            if (response.data?._id) {
                onModalHide();
                getFees();
            }

        } catch (error) {
            console.log(error.message);
            console.log(error.response.status);

        }
    };

    return (
        <Modal show={showModal} onHide={onModalHide} size="md" centered>
            <Modal.Header closeButton className="ps-md-4">
                <h2>Log payment</h2>
            </Modal.Header>

            <Modal.Body className="px-md-4">
                <Row>
                    <Col md={12} className="pb-3 mb-3">
                        <div className="position-relative">
                            <Form.Label className="custom-form-label">Fee Note</Form.Label>
                            <InputGroup>
                                <Form.Control as="textarea" rows={3} value={note} onChange={e => setNote(e.target.value.trimStart())} />
                            </InputGroup>
                            {errors.note && <p className='text-red m-0 p-0 text-sm'>{errors.note}</p>}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} className="pb-3 mb-3">
                        <div className="position-relative">
                            <Form.Label className="custom-form-label">Fee Amount Paid</Form.Label>
                            <InputGroup>
                                <Form.Control type="number" value={amount} onChange={e => setAmount(e.target.value.trimStart())} />
                            </InputGroup>
                            {errors.amount && <p className='text-red m-0 p-0 text-sm'>{errors.amount}</p>}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} className="pb-3 mb-3">
                        <div className="position-relative">
                            <Form.Label className="custom-form-label">Workers Compensation Expiration Date</Form.Label>
                            <DatePicker
                                selected={log_date}
                                onChange={date => set_log_date(date)}
                                showMonthDropdown
                                showYearDropdown
                                placeholderText="select date"
                                className='form-control'
                                style={{ zIndex: 100 }}
                            />
                        </div>
                        {errors.log_date && <p className='text-red m-0 p-0 text-sm'>{errors.log_date}</p>}
                    </Col>
                </Row>

                <Row>
                    <Col md={12} className="mb-3">
                        <div className="mb-3">
                            <InputGroup>
                                <Form.Control
                                    placeholder={receipt?.fileName ? receipt.fileName : "please upload a pdf file"}
                                    aria-label="File name"
                                    aria-describedby="basic-addon-twotoneAttachFile"
                                    disabled
                                />
                                <InputGroup.Text id="basic-addon-twotoneAttachFile">
                                    <PDFUpload
                                        trigger={uploading ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <Icon className="pointer" icon={twotoneAttachFile} width="24" height="24" />}
                                        onPDFUploadClick={(data) => setReceipt(data)}
                                        setUploading={setUploading}
                                        setUploadError={setUploadError}
                                    />
                                </InputGroup.Text>
                            </InputGroup>
                            {uploadError.message && <p className='text-red m-0 p-0 text-sm' style={{ color: uploadError.background }}>{uploadError.message}</p>}
                        </div>

                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer>
                <div className="ms-auto d-flex">
                    <Button variant="outline-primary" type="submit" className="ms-2" onClick={onModalHide}>Cancel</Button>
                    <Button variant="primary" type="submit" className="ms-2" onClick={onAddClick}>{fee ? 'Save' : 'Log Payment'}</Button>
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default EditProjectStatuses;