import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';

//actions
import { setCurrentProject } from '../../../../redux_actions/currentProjectActions';

//comp
import CreateUserBasicForm from '../../../common/Forms/CreateUserBasicForm';
import server from '../../../../apis/server';

const CreateClient = ({ showModal, setShowModal, getCompanyDetails }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local
    const [projectOptions, setProjectOptions] = useState([]); // will get all the project options from the server
    const [companyOptions, setCompanyOptions] = useState([]); // will get all the company options from the server

    const [submitting, setSubmitting] = useState(false);
    const [responseError, setResponseError] = useState('');

    const onModalHide = () => {
        setShowModal(false);
        setResponseError('');
        setSubmitting(false);
    };

    const getOptions = async () => {
        try {
            const response = await server.get('/data/options', {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response?.data?.companyOptions) setCompanyOptions(response.data.companyOptions);
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        getOptions();
    }, []);

    const onContinueClick = async (createUserFormData) => {
        try {
            setSubmitting(true);
            const createUserResponse = await server.post('/client/create', createUserFormData, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setSubmitting(false);

            //closing the modal
            onModalHide();

            if (createUserResponse.data?.createdUser) {
                const response = await server.post('/project/addteammember', { companyId: currentCompany._id, projectId: currentProject._id, userId: createUserResponse.data.createdUser._id }, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });

                if (response.data._id) {
                    dispatch(setCurrentProject(response.data));
                }
            }


        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });
            if (error.response?.status === 409) {
                setResponseError('There is already an account associated with this email');
            } else {
                setResponseError('Something went wrong please try again');
            }
        }
    };

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }

        return false;
    };

    return (
        <Modal show={showModal} onHide={onModalHide} centered>
            <Modal.Header closeButton className="ps-md-4">
                <h2>Create Client</h2>
            </Modal.Header>

            <Modal.Body className="px-md-4">

                <CreateUserBasicForm
                    actionButtonLabel="Create Client"
                    infoTitle={'Client Information'}
                    onCreateUserClick={onContinueClick}
                    onCancelClick={onModalHide}
                    // companyOptions={companyOptions}
                    // projectOptions={projectOptions}
                    showUserRole
                    disabledFields={isSystemAdmin() ? [] : ['companies']}
                    submitting={submitting} setSubmitting={setSubmitting}
                />

                <Row>
                    <Col md={12}>
                        <div className="mb-3">
                            {responseError && <p className="text-red">{responseError}</p>}
                        </div>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal >
    );
};

export default CreateClient;