import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, ButtonGroup } from 'react-bootstrap';
import { isEmail } from 'validator';
import server from '../../../../apis/server';

//comp
import EditUserForm from '../../../common/Forms/EditUserForm';

const EditSystemAdminUser = ({ showModal, setShowModal, userData, admins, setAdmins }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);

    const [submitting, setSubmitting] = useState(false); //after successful pdf upload this object will be => {fileName, url}

    //handling Form Errors
    const [responseError, setResponseError] = useState('');

    //effect hook to populate the data using the passed user object


    const onModalHide = () => {
        setShowModal(false);
        setResponseError('');
        setSubmitting(false);
    };

    const onSaveClick = async (values) => {
        try {
            setSubmitting(true);
            const response = await server.post('/admins/update', { updatedData: values, oldUserData: userData }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setSubmitting(false);

            if (response.data?.updatedAdmin) {
                const updatedAdmin = response.data.updatedAdmin;

                setAdmins(admins.map(el => {
                    if (el._id === updatedAdmin._id) return updatedAdmin;
                    else return el;
                }));

                onModalHide();
            }
        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });
            if (error.response.status === 409) {
                setResponseError('There is already an account associated with this email');
            } else {
                setResponseError('Something went wrong please try again');
            }
        }

    };

    return (
        <Modal show={showModal} onHide={onModalHide} centered>
            <Modal.Header closeButton className="ps-md-5">
                <h2>Edit System Admin User</h2>
            </Modal.Header>

            <Modal.Body className="px-md-5">
                <EditUserForm
                    onSave={onSaveClick}
                    onCancel={onModalHide}
                    userData={userData}
                    submitting={submitting} setSubmitting={setSubmitting}
                />
                <Col md={12}>
                    <div className="mb-3">
                        {responseError && <p className='text-red m-0 p-0 text-sm'>{responseError}</p>}
                    </div>
                </Col>
            </Modal.Body>
        </Modal >
    );
};

export default EditSystemAdminUser;