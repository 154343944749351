import server from '../apis/server';

export const getSystemUnreadNotificationsCount = () => async (dispatch, getState) => {

    try {
        const response = await server.get(`/notifications/system/count`, {
            headers: {
                Authorization: 'Bearer ' + getState().accessToken
            }
        });

        dispatch({
            type: 'SET_SYSTEM_UNREAD_NOTIFICATIONS',
            payload: response.data?.count ? response.data?.count : 0
        });
    } catch (error) {
        console.log({ error: error.message });
    }

};
