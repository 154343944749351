import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { isEmail } from 'validator';
import server from '../../../../apis/server';

//validation functions
import { validateMessageInput } from '../../../utils/customValidations';

const InviteSystemAdminUser = ({ showModal, setShowModal }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);

    //local state
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [responseError, setResponseError] = useState('');

    const onModalHide = () => {
        setShowModal(false);
        setEmail('');
        setMessage('');
        setSubmitting(false);
        setErrors({});
        setResponseError('');
    };

    // effect to validate inputs with thier change
    useEffect(() => {
        const newErrors = {};

        //validating inputs
        if (message && validateMessageInput(message, 'Message').error) newErrors.message = validateMessageInput(message, 'Message').error;

        if (email && !isEmail(email)) newErrors.email = 'Email is not valid';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});
    }, [message, email]);


    const onInviteClick = async () => {
        const newErrors = {};

        if (validateMessageInput(message, 'Message').error) newErrors.message = validateMessageInput(message, 'Message').error;

        if (!email) newErrors.email = 'Please enter email';
        else if (!isEmail(email)) newErrors.email = 'Email is not valid';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        try {
            setSubmitting(true);
            const response = await server.post('/admins/invite', { email, message, inviterName: `${currentUser.firstName} ${currentUser.lastName}` }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setSubmitting(false);
            setErrors({});
            onModalHide();

        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });

            if (error.response.status === 409) {
                setResponseError('There is already an account associated with this email');
            } else {
                setResponseError('Something went wrong please try again');
            }
        }
    };

    return (
        <Modal show={showModal} onHide={onModalHide} centered>
            <Modal.Header closeButton className="ps-md-5">
                <h2>Invite System Admin User</h2>
            </Modal.Header>

            <Modal.Body className="px-md-5">
                <Row className="mb-3">
                    <Col md={12}>
                        <div className="pb-3 position-relative">
                            <Form.Label className="custom-form-label">Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                aria-label="Email"
                                value={email} onChange={e => setEmail(e.target.value)}
                                isInvalid={errors.email ? true : false}
                            />
                            {errors.email && <p className='text-red m-0 p-0 text-sm'>{errors.email}</p>}
                        </div>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <div className="pb-3 position-relative">
                            <Form.Label className="custom-form-label">Message (optional)</Form.Label>
                            <Form.Control as="textarea" rows={6} value={message} onChange={e => setMessage(e.target.value)} />
                            {errors.message && <p className='text-red m-0 p-0 text-sm'>{errors.message}</p>}

                        </div>
                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer>
                <div className="ms-auto d-flex">
                    <Button variant="outline-primary" type="submit" className="ms-2" onClick={onModalHide}>Cancel</Button>
                    <Button variant="primary" type="submit" className="ms-2" onClick={onInviteClick}>
                        {submitting
                            ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />
                            : 'Send Admin invite'
                        }

                    </Button>


                </div>
                <div className="mb-3">
                    {responseError && <p className="text-red">{responseError}</p>}
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default InviteSystemAdminUser;