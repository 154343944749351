import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Card } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import { useNavigate } from 'react-router-dom';
import server from '../../../apis/server';

//redux actions
import { createdUserLogin } from '../../../redux_actions/userActions';

//Comp
import MainHeader from '../MainHeader/MainHeader';
import CreateUserBasicForm from '../../common/Forms/CreateUserBasicForm';

const CreateUserPage = () => {
    //React router hook that handles navigation
    let navigate = useNavigate();

    //redux state
    const dispatch = useDispatch();

    //local state
    const [submitting, setSubmitting] = useState(false); //after successful pdf upload this object will be => {fileName, url}

    //handling Form Errors
    const [errors, setErrors] = useState('');


    const onContinueClick = async (createUserFormData) => {
        console.log(createUserFormData);

        try {
            setSubmitting(true);

            const response = await server.post('/users/create', createUserFormData);
            setSubmitting(false);

            // need to get the user and accessToken and store in redux
            dispatch(createdUserLogin(response.data));
            navigate('/projects');

        } catch (error) {
            setSubmitting(false);

            console.log(error.message);
            console.log(error.response.status);

            if (error.response.status === 409) {
                setErrors({ email: 'There is already an account associated with this email' });
            } else {
                setErrors({ response: 'Something went wrong please try again' });
            }

        }
    };

    return (
        <div>
            <MainHeader />

            <main>
                <Container className="p-4">
                    <div className="text-center">
                        <h1 className="simple-title">Create your Account</h1>
                    </div>

                    <div className="container--sm">
                        <div>
                            <span className="btn-link pointer" onClick={() => navigate(-1)}> <Icon icon={roundArrowBack} width="24" height="24" /> <span className="d-inline-block ps-1">Back</span></span>
                        </div>

                        <Card className="primary-card">

                            <CreateUserBasicForm
                                infoTitle={'What’s your Name?'}
                                onCreateUserClick={onContinueClick}
                                onCancelClick={() => navigate(-1)}
                                submitting={submitting} setSubmitting={setSubmitting}
                            />

                        </Card>

                    </div>
                </Container>
            </main>


        </div>
    );
};

export default CreateUserPage;