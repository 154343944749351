import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import Dropzone from 'react-dropzone';

//action
import { addDocument } from '../../../../redux_actions/documentsActions';

//constants
import { documentTitles } from '../../../utils/constants';

//comp
import PDFUpload from '../../../common/fileUpload/PDFUpload';
import server from '../../../../apis/server';

const DocumentsSection = () => {
    const navigate = useNavigate();
    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const documents = useSelector(state => state.documents);
    const dispatch = useDispatch();

    //Upload docs state
    const [poDocument, setPoDocument] = useState({});
    const [landloardApprovalDocument, setLandloardApprovalDocument] = useState({});
    const [signedPlansDocument, setSignedPlansDocument] = useState({});
    const [title24Document, setTitle24Document] = useState({});
    const [structuralCalculationsDocument, setStructuralCalculationsDocument] = useState({});

    //state for handling pdf upload
    const [uploadingPO, setUploadingPO] = useState(false);
    const [uploadingLandloard, setUploadingLandloard] = useState(false);
    const [uploadingSignedPlans, setUploadingSignedPlans] = useState(false);
    const [uploadingTitle24, setUploadingTitle24] = useState(false);
    const [uploadingStructuralCalculations, setUploadingStructuralCalculations] = useState(false);
    const [uploadPDFError, setUploadPDFError] = useState('');

    // drop zone refs
    const poRef = createRef();

    useEffect(() => {
        if (documents?.length > 0) {
            const poDoc = documents.filter(el => el.title === documentTitles.purchase_order).sort((a, b) => b.version - a.version);
            const landDoc = documents.filter(el => el.title === documentTitles.owner_landloard_approval).sort((a, b) => b.version - a.version);
            const signedPlansDoc = documents.filter(el => el.title === documentTitles.signed_plans).sort((a, b) => b.version - a.version);
            const title24Doc = documents.filter(el => el.title === documentTitles.title_24).sort((a, b) => b.version - a.version);
            const strDoc = documents.filter(el => el.title === documentTitles.structural_calculations).sort((a, b) => b.version - a.version);

            if (poDoc?.length > 0) setPoDocument(poDoc[0]);
            if (landDoc?.length > 0) setLandloardApprovalDocument(landDoc[0]);
            if (signedPlansDoc?.length > 0) setSignedPlansDocument(signedPlansDoc[0]);
            if (title24Doc?.length > 0) setTitle24Document(title24Doc[0]);
            if (strDoc?.length > 0) setStructuralCalculationsDocument(strDoc[0]);
        }
    }, [documents]);

    const uploadDocument = async (data) => {
        try {

            const documentData = {
                companyId: currentCompany._id,
                project_id: currentProject._id,
                title: data.title,
                file_name: data.fileName,
                url: data.url,
            };

            const response = await server.post('/documents/upload', documentData, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data._id) {
                dispatch(addDocument(response.data));
            }

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const isDocumentFinalized = (title) => {
        if (!currentProject) return false;
        if (!currentProject.finalized_documents) return false;
        if (currentProject.finalized_documents[title] && currentProject.finalized_documents[title].state !== undefined) return currentProject.finalized_documents[title].state;
        return false;
    };

    return (
        <Row className="my-4">
            <Col>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h4 className="mb-0">Documents Most Recent</h4>
                        <Button variant="primary" size="sm" type="submit" className="ms-2" onClick={() => navigate(`/projectsdocument/${currentProject._id}`)}>See All Documents</Button>
                    </Card.Header>
                    <Card.Body>


                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Document Title</th>
                                    <th>Version</th>
                                    <th>Uploaded</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Purchase order
                                    </td>
                                    <td>
                                        {poDocument._id ? poDocument.version : '--'}
                                    </td>
                                    <td>
                                        {poDocument.url
                                            ? <span className="active">Yes</span>
                                            : <span className="text-grey">No</span>
                                        }

                                    </td>
                                    <td >
                                        <div className='d-flex align-items-center justify-content-around'>
                                            {poDocument.url
                                                ? <a className="btn-link--primary pointer" href={poDocument.url}>View</a>
                                                : <span className="text-grey">View</span>
                                            }

                                            <PDFUpload
                                                trigger={uploadingPO ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className={isDocumentFinalized(documentTitles.purchase_order) ? "" : "btn-link--primary pointer"}>Upload</span>}
                                                onPDFUploadClick={data => {
                                                    uploadDocument({ ...data, title: documentTitles.purchase_order });
                                                }}
                                                setUploading={setUploadingPO}
                                                setUploadError={setUploadPDFError}
                                                uploadedFile={poDocument}
                                                disabled={isDocumentFinalized(documentTitles.purchase_order)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Owner / Landloard Approval</td>
                                    <td>{landloardApprovalDocument._id ? landloardApprovalDocument.version : '--'}</td>
                                    <td>
                                        {landloardApprovalDocument.url
                                            ? <span className="active">Yes</span>
                                            : <span className="text-grey">No</span>
                                        }

                                    </td>
                                    <td className='d-flex align-items-center justify-content-around'>
                                        {landloardApprovalDocument.url
                                            ? <a className="btn-link--primary pointer" href={landloardApprovalDocument.url}>View</a>
                                            : <span className="text-grey">View</span>
                                        }

                                        <PDFUpload
                                            trigger={uploadingLandloard ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className={isDocumentFinalized(documentTitles.owner_landloard_approval) ? "" : "btn-link--primary pointer"}>Upload</span>}
                                            onPDFUploadClick={data => {
                                                uploadDocument({ ...data, title: documentTitles.owner_landloard_approval });
                                            }}
                                            setUploading={setUploadingLandloard}
                                            setUploadError={setUploadPDFError}
                                            uploadedFile={landloardApprovalDocument}
                                            disabled={isDocumentFinalized(documentTitles.owner_landloard_approval)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Signed Plans</td>
                                    <td>{signedPlansDocument._id ? signedPlansDocument.version : '--'}</td>
                                    <td>
                                        {signedPlansDocument.url
                                            ? <span className="active">Yes</span>
                                            : <span className="text-grey">No</span>
                                        }

                                    </td>
                                    <td >
                                        <div className='d-flex align-items-center justify-content-around'>
                                            {signedPlansDocument.url
                                                ? <a className="btn-link--primary pointer" href={signedPlansDocument.url}>View</a>
                                                : <span className="text-grey">View</span>
                                            }
                                            <PDFUpload
                                                trigger={uploadingSignedPlans ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className={isDocumentFinalized(documentTitles.signed_plans) ? "" : "btn-link--primary pointer"}>Upload</span>}
                                                onPDFUploadClick={data => {
                                                    uploadDocument({ ...data, title: documentTitles.signed_plans });
                                                }}
                                                setUploading={setUploadingSignedPlans}
                                                setUploadError={setUploadPDFError}
                                                uploadedFile={signedPlansDocument}
                                                disabled={isDocumentFinalized(documentTitles.signed_plans)}
                                            />
                                        </div>



                                    </td>
                                </tr>
                                <tr>
                                    <td>Title 24</td>
                                    <td>{title24Document._id ? title24Document.version : '--'}</td>
                                    <td>
                                        {title24Document.url
                                            ? <span className="active">Yes</span>
                                            : <span className="text-grey">No</span>
                                        }

                                    </td>
                                    <td className='d-flex align-items-center justify-content-around'>
                                        {title24Document.url
                                            ? <a className="btn-link--primary pointer" href={title24Document.url}>View</a>
                                            : <span className="text-grey">View</span>
                                        }

                                        <PDFUpload
                                            trigger={uploadingTitle24 ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className={isDocumentFinalized(documentTitles.title_24) ? "" : "btn-link--primary pointer"}>Upload</span>}
                                            onPDFUploadClick={data => {
                                                uploadDocument({ ...data, title: documentTitles.title_24 });
                                            }}
                                            setUploading={setUploadingTitle24}
                                            setUploadError={setUploadPDFError}
                                            uploadedFile={title24Document}
                                            disabled={isDocumentFinalized(documentTitles.title_24)}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>Structural Calculations</td>
                                    <td>{structuralCalculationsDocument._id ? structuralCalculationsDocument.version : '--'}</td>
                                    <td>
                                        {structuralCalculationsDocument.url
                                            ? <span className="active">Yes</span>
                                            : <span className="text-grey">No</span>
                                        }

                                    </td>
                                    <td className='d-flex align-items-center justify-content-around'>
                                        {structuralCalculationsDocument.url
                                            ? <a className="btn-link--primary pointer" href={structuralCalculationsDocument.url}>View</a>
                                            : <span className="text-grey">View</span>
                                        }

                                        <PDFUpload
                                            trigger={uploadingStructuralCalculations ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <span className={isDocumentFinalized(documentTitles.structural_calculations) ? "" : "btn-link--primary pointer"}>Upload</span>}
                                            onPDFUploadClick={data => {
                                                uploadDocument({ ...data, title: documentTitles.structural_calculations });
                                            }}
                                            setUploading={setUploadingStructuralCalculations}
                                            setUploadError={setUploadPDFError}
                                            uploadedFile={structuralCalculationsDocument}
                                            disabled={isDocumentFinalized(documentTitles.structural_calculations)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        {/* <div className="d-flex align-items-center justify-content-end">
                            <Button variant="primary" type="button" size="sm">Upload Documents</Button>
                        </div> */}

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default DocumentsSection;