import server from '../apis/server';

export const getProjectUnreadNotificationsCount = () => async (dispatch, getState) => {

    try {
        if (getState().currentProject?._id) {
            const response = await server.get(`/projectunreadnotifications`, {
                params: {
                    project_id: getState().currentProject._id,
                    companyId: getState().currentCompany._id
                },
                headers: {
                    Authorization: 'Bearer ' + getState().accessToken
                }
            });

            dispatch({
                type: 'SET_PROJECT_UNREAD_NOTIFICATION',
                payload: response.data?.count ? response.data?.count : 0
            });
        }
    } catch (error) {
        console.log({ error: error.message });
    }

};
