/* eslint-disable import/no-anonymous-default-export */

const initialState = {

};

export default (state = initialState, action) => {
    if (action.type === 'SET_CURRENT_PROJECT') {
        return action.payload;
    }

    if (action.type === 'UPDATE_FINALIZED_DOCUMENTS') {
        return { ...state, finalized_documents: action.payload };
    }

    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};