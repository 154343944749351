import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, ButtonGroup } from 'react-bootstrap';
import { isEmail } from 'validator';
import Select from 'react-select';
import server from '../../../../apis/server';

//validation functions
import { validateMessageInput } from '../../../utils/customValidations';

const InviteClient = ({ showModal, setShowModal }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);

    //local state
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [selectedCompanyRole, setSelectedCompanyRole] = useState('user');
    const [selectedProject, setSelectedProject] = useState(null);

    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [responseError, setResponseError] = useState('');

    const onModalHide = () => {
        setShowModal(false);

        //reseting inputs
        setEmail('');
        setMessage('');
        setSelectedCompanyRole('user');
        setErrors({});
        setResponseError('');
    };

    // effect to validate inputs with thier change
    useEffect(() => {
        const newErrors = {};

        //validating inputs
        if (message && validateMessageInput(message, 'Message').error) newErrors.message = validateMessageInput(message, 'Message').error;

        if (email && !isEmail(email)) newErrors.email = 'Email is not valid';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});
    }, [message, email]);


    const onInviteClick = async () => {
        const newErrors = {};

        if (validateMessageInput(message, 'Message').error) newErrors.message = validateMessageInput(message, 'Message').error;

        if (!email) newErrors.email = 'Please enter email';
        else if (!isEmail(email)) newErrors.email = 'Email is not valid';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        setSubmitting(true);

        try {
            const inviteData = {
                email: email.trim(),
                message: message.trim(),
                inviterName: `${currentUser.firstName} ${currentUser.lastName}`,
                role: selectedCompanyRole,
                companyId: currentCompany._id,
                project_id: currentProject._id
            };

            console.log(inviteData);

            const response = await server.post('/client/invite', inviteData, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setSubmitting(false);
            setErrors({});
            onModalHide();

        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });

            if (error.response.status === 409) {
                setErrors({ email: 'There is already an account associated with this email' });
                // setResponseError('There is already an account associated with this email');
            } else {
                setResponseError('Something went wrong please try again');
            }
        }
    };

    const isSystemAdminOrCompanySupperAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }

        if (currentCompany?._id) {
            if (currentCompany?.client_supper_admins?.includes(currentUser._id)) return true;
        }
        return false;
    };

    return (
        <Modal show={showModal} onHide={onModalHide} centered>
            <Modal.Header closeButton className="ps-md-5">
                <h2>Invite Client</h2>
            </Modal.Header>

            <Modal.Body className="px-md-5">
                <Form>
                    <Row className="mb-3">
                        <Col md={12}>
                            <div className="pb-3 position-relative">
                                <Form.Label className="custom-form-label">Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                    value={email} onChange={e => setEmail(e.target.value.trimStart())}
                                    isInvalid={errors.email ? true : false}
                                />
                                {errors.email && <p className='text-red m-0 p-0 text-sm'>{errors.email}</p>}
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={12}>
                            <div className="pb-3 position-relative">
                                <Form.Label className="custom-form-label">Message (optional)</Form.Label>
                                <Form.Control as="textarea" rows={6} value={message} onChange={e => setMessage(e.target.value.trimStart())} isInvalid={errors.message ? true : false} />
                                {errors.message && <p className='text-red m-0 p-0 text-sm'>{errors.message}</p>}
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={12} className="pb-3">
                            <label className="simple-title">Select Role</label>
                            <div className="d-flex align-items-center justify-content-center pt-2">
                                <ButtonGroup aria-label="Basic example">
                                    {/* {isSystemAdminOrCompanySupperAdmin() && <Button variant="outline-secondary" className={selectedCompanyRole === 'supper_admin' ? 'active' : ''} onClick={() => setSelectedCompanyRole('supper_admin')}>Supper Admin</Button>} */}
                                    <Button variant="outline-secondary" className={selectedCompanyRole === 'admin' ? 'active' : ''} onClick={() => setSelectedCompanyRole('admin')}>Admin</Button>
                                    <Button variant="outline-secondary" className={selectedCompanyRole === 'user' ? 'active' : ''} onClick={() => setSelectedCompanyRole('user')}>User</Button>
                                </ButtonGroup>

                                <div></div>
                            </div>
                        </Col>
                    </Row>

                    {/* <label className="simple-title">Project Assignment</label>
                    <Row className="pt-2">
                        <Col md={12}>
                            <div className="pb-3 position-relative">
                                <Select
                                    placeholder="Select a project"
                                    options={[]}
                                    // isDisabled={selectedProject?.value ? false : true}
                                    value={selectedProject}
                                    onChange={project => console.log(project)}
                                    isClearable
                                />
                            </div>
                        </Col>
                    </Row> */}

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <div className="ms-auto d-flex">
                    <Button variant="outline-primary" type="submit" className="ms-2" onClick={onModalHide}>Cancel</Button>
                    <Button variant="primary" type="submit" className="ms-2" onClick={onInviteClick} disabled={submitting}>
                        {submitting
                            ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />
                            : 'Send Client invite'
                        }
                    </Button>


                </div>
                <div className="mb-3">
                    {responseError && <p className="text-red">{responseError}</p>}
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default InviteClient;