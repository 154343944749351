import server from "../../apis/server";
import { logoutUser } from "../../redux_actions/userActions";

const CheckStoredInfo = async (accessToken, dispatch) => {
    try {
        const response = await server.get('/checkstore', {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        });
    } catch (error) {
        dispatch(logoutUser());
        console.log({ error: error.message });
    }

};

export default CheckStoredInfo;