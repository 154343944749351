import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, Spinner, Button, Form, Table, Pagination } from 'react-bootstrap';
import { CSVLink } from "react-csv";

//icons
import { Icon } from '@iconify/react';
import filterIcon from '@iconify/icons-bi/filter';
import searchIcon from '@iconify/icons-bi/search';
import server from '../../../apis/server';

//Modals
import CreateCompany from './modals/CreateCompany';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import CompanyTableRaw from './CompanyTableRaw';
import NoData from '../../common/NoData/NoData';
import useDebounce from '../../utils/useDebounce';

let active = 1;
let items = [];
for (let number = 1; number <= 1; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const CompanyListPage = () => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    //local state
    const [showCreateCompany, setShowCreateCompany] = useState(false);

    //Searching and sorting state
    const [searchInput, setSearchInput] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState(-1);

    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);

    //csv ref
    const csvLink = useRef();

    const debouncedSearch = useDebounce(searchInput, 500);

    const getCompanies = async () => {
        try {
            setLoading(true);
            const response = await server.get('/companies', {
                params: { search: debouncedSearch, sortBy, sortOrder },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            setLoading(false);

            if (response.data) setCompanies(response.data);
        } catch (error) {
            setLoading(false);
            console.log({ error: error.message });
        }
    };

    const onSearchClear = () => {
        setSearchInput('');
    };

    const onSortByChange = (sort) => {
        setSortBy(sort);
    };

    useEffect(() => {
        getCompanies();
    }, [debouncedSearch, sortBy, sortOrder]);

    const getCSVData = () => {
        if (!companies || companies.length === 0) return [];
        const data = companies.map(company => {
            const { name, location_city, location_state, location_zip, phoneNumber, contractor_license_number, workers_compensation_company_name, workers_compensation_policy_number, workers_compensation_expiration_date } = company;
            return {
                Name: name,
                City: location_city,
                State: location_state,
                Zip: location_zip,
                "Phone Number": phoneNumber,
                "Contractor License Number": contractor_license_number,
                "WC company name": workers_compensation_company_name,
                "WC policy number": workers_compensation_policy_number,
                "WC expiration date": workers_compensation_expiration_date,
            };
        });
        return data;
    };

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">
                    <div className="top-bar">
                        <h2>Companies </h2>

                        <div className="top-bar--right">
                            {companies?.length > 0 && <Button variant="outline-primary" type="submit" className="ms-2" onClick={() => csvLink.current.link.click()}>Export <span className="text-uppercase">CSV</span></Button>}
                            {isSystemAdmin() && <Button variant="primary" className="ms-2" onClick={() => setShowCreateCompany(true)}>Add Company</Button>}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center px-3 py-2">
                        <div className="input-search" controlid="exampleForm.ControlInput1">
                            <span className="input-search--icon">
                                {!searchInput && !loading && < Icon className="pointer" icon={searchIcon} width="24" height="24" />}
                                {searchInput && !loading && <Icon className="pointer" icon="ic:round-clear" width="24" height="24" onClick={onSearchClear} />}
                                {loading && <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />}

                            </span>
                            <Form.Control type="text" placeholder="Search" value={searchInput} onChange={e => setSearchInput(e.target.value.trimStart())} />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-basic" className="primary">
                                    <Button variant="outline-secondary" size="sm">Sort By - {sortBy} <Icon icon={filterIcon} width="24" height="24" /></Button>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => onSortByChange('name')}>Sort by name</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('city')}>Sort by city</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('state')}>Sort by state</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('zip')}>Sort by zip</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div>
                                {sortOrder === 1 && <Icon icon="cil:sort-ascending" className="pointer" onClick={() => setSortOrder(-1)} />}
                                {sortOrder === -1 && <Icon icon="cil:sort-descending" className="pointer" onClick={() => setSortOrder(1)} />}
                            </div>
                        </div>
                    </div>



                    {companies.length > 0
                        ? <Container fluid className="px-3 py-2">
                            <Row>
                                <Col>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Name
                                                </th>

                                                <th>
                                                    City
                                                </th>
                                                <th>
                                                    State
                                                </th>
                                                <th>
                                                    Zip
                                                </th>
                                                <th>Number</th>
                                                <th>Contractor License Number</th>
                                                <th>WC company</th>
                                                <th>WC policy number</th>
                                                <th>WC Exp Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {companies?.length > 0 && companies.map(company => <CompanyTableRaw key={company._id} company={company} />)}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        <Pagination size="sm">{items}</Pagination>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        : loading
                            ? <Row>
                                <Col md={12}>
                                    <div className="text-center py-4">
                                        <Spinner className="mx-auto" animation="border" style={{ color: '#019EB2' }} />
                                    </div>
                                </Col>
                            </Row>

                            : <NoData
                                message={searchInput ? 'No companies found try changing your search' : 'No Companies added'}
                                actionLabel={isSystemAdmin() ? 'Create Company' : ''}
                                onActionClick={() => setShowCreateCompany(true)}
                            />
                    }



                </div>
            </main>

            <CreateCompany showModal={showCreateCompany} setShowModal={setShowCreateCompany} companies={companies} setCompanies={setCompanies} />
            {companies?.length > 0 && <CSVLink ref={csvLink} className="text-highliten d-none" data={getCSVData()} filename={`admin-users.csv`} style={{ textDecoration: 'none', color: 'white' }}></CSVLink>}
        </div>
    );
};

export default CompanyListPage;