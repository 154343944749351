import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

//actions
import { loadCurrentProject } from '../../../redux_actions/currentProjectActions';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import ProjectNavigationBar from '../../common/ProjectNavigationBar/ProjectNavigationBar';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import OverviewSection from './sections/OverviewSection';
import ProjectStatus from './sections/ProjectStatus';
import DocumentsSection from './sections/DocumentsSection';
import HoursSection from './sections/HoursSection';
import FeesSection from './sections/FeesSection';
import ClientNotesSection from './sections/ClientNotesSection';

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectsOverviewPage = () => {
    const { projectId } = useParams();

    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCurrentProject(projectId));
    }, [projectId]);

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">

                    <ProjectNavigationBar />

                    <Container fluid className="py-3">
                        <Row className="pb-4">
                            <OverviewSection />
                        </Row>

                        <ProjectStatus />
                        
                        <ClientNotesSection />

                        <FeesSection />

                        <DocumentsSection />

                        {isSystemAdmin() && <HoursSection />}

                    </Container>

                </div>
            </main>

        </div>
    );
};

export default ProjectsOverviewPage;