import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import server from '../../../../apis/server';

//comp
import NoData from '../../../common/NoData/NoData';
import LogPayment from '../../ProjectsFeesPage/modals/LogPayment';

const FeesSection = () => {
    let navigate = useNavigate();

    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local
    const [showEditLogPayment, setShowLogPayment] = useState(false);
    const [projectFees, setProjectFees] = useState([]);

    const getFees = async () => {
        try {
            const response = await server.get('/fees', {
                params: {
                    companyId: currentCompany._id,
                    project_id: currentProject._id,
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setProjectFees(response.data);
        } catch (error) {
            console.log({ error: error.message });
        }
    };


    useEffect(() => {
        if (currentCompany._id && currentProject._id) getFees();
    }, [currentCompany, currentProject]);

    const getUnpaidAmount = () => {
        const unpaidFees = projectFees.filter(fee => fee.paid === false);
        let unpaidAmount = 0;
        unpaidFees.forEach(fee => unpaidAmount += fee.amount);
        return Math.round(unpaidAmount * 100) / 100;
    };
    const getTotalAmount = () => {
        let totalAmount = 0;
        projectFees.forEach(fee => totalAmount += fee.amount);
        return Math.round(totalAmount * 100) / 100;
    };

    return (
        <Row className="py-4">
            <Col>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h4 className="mb-0">Fees</h4>
                        <Button variant="primary" size="sm" type="submit" className="ms-2" onClick={() => navigate(`/projectsfees/${currentProject._id}`)}>See All Payments</Button>
                    </Card.Header>

                    <Card.Body>
                        <Row>

                            <Col md={12}>
                                {projectFees.length > 0
                                    ? <Table bordered striped>
                                        <thead>
                                            <tr>
                                                <th>Notes</th>
                                                <th>Amount Paid</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {projectFees.length > 0 && projectFees.map(fee => {
                                                const { note, amount, paid, log_date, logged_by, receipt } = fee;
                                                return (
                                                    <tr>
                                                        <td>{note}</td>
                                                        <td>${amount}</td>
                                                        <td>{moment(log_date).format('lll')}</td>
                                                    </tr>
                                                );
                                            })}

                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td colSpan="5">
                                                    <div className="text-right py-2"><strong>Total Fees:</strong><strong className="d-inline-block ps-2 text-md">${getTotalAmount()}</strong></div>
                                                    <div className="text-right py-2"><strong>Unpaid Balance:</strong><strong className="d-inline-block ps-2 text-lg">${getUnpaidAmount()}</strong></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                    : <NoData message={'No fees logged'} />
                                }


                                <div className="d-flex justify-content-end">
                                    <Button variant="primary" size="sm" type="submit" className="ms-2" onClick={() => setShowLogPayment(true)}>Log Payment</Button>
                                </div>
                            </Col>

                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <LogPayment showModal={showEditLogPayment} setShowModal={setShowLogPayment} getFees={getFees} />

        </Row>
    );
};

export default FeesSection;
