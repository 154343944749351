import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Table, Pagination, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import server from '../../../apis/server';
import moment from 'moment';

//comp
import EditClient from './modals/EditClient';

//icons 
import { Icon } from '@iconify/react';
import pencilFill from '@iconify/icons-bi/pencil-fill';

const UserTableRaw = ({ user }) => {
    const navigate = useNavigate();
    //redux state
    const currentCompany = useSelector(state => state.currentCompany);
    const currentUser = useSelector(state => state.currentUser);

    //local
    const [showEditModal, setShowEditModal] = useState(false);

    const { _id, contactName, email, phoneNumber, active, updatedAt } = user;

    const getUserRole = (id) => {
        if (currentCompany?.client_admins?.includes(id)) return 'Admin';
        if (currentCompany?.client_supper_admins?.includes(id)) return 'Supper Admin';
        if (currentCompany?.client_users?.includes(id)) return 'User';
    };

    const showEditButton = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }

        if (currentCompany?._id) {
            if (currentCompany?.client_supper_admins?.includes(currentUser._id)) return true;
            if (currentCompany?.client_admins?.includes(currentUser._id)) {
                if (currentCompany?.client_supper_admins?.includes(user._id)) return false;
                return true;
            }
        }
        return false;
    };

    return (
        <tr>
            <td>{contactName}</td>
            <td><a href={`mailto:${email}`}>{email}</a></td>
            <td><a href="tel:972-555-555">{phoneNumber}</a></td>
            <td><a>--</a></td>
            <td>{getUserRole(_id)}</td>
            <td><span className={active ? 'active' : 'inactive'}>{active ? 'Active' : 'Inactive'}</span></td>
            <td>{moment(updatedAt).format('lll')}</td>
            <td>
                <Button variant="outline-primary" size="sm" onClick={() => navigate(`/user/${_id}`)}><Icon icon="el:eye-open" width="15" height="15" /></Button>
                {showEditButton() && <Button variant="outline-primary" size="sm" onClick={() => setShowEditModal(true)}><Icon icon={pencilFill} width="15" height="15" /></Button>}
            </td>
            <EditClient showModal={showEditModal} setShowModal={setShowEditModal} userData={user} />
        </tr>
    );
};

export default UserTableRaw;