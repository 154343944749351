import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, ButtonGroup } from 'react-bootstrap';
import { isEmail } from 'validator';
import server from '../../../apis/server';
import Select from 'react-select';

//validation functions
import { validateSmallTextNoNumbers, validateSmallText, validatePhoneNumber, validateSmallNumber, validateSmallOptionalTextNoNumbers } from '../../utils/customValidations';

const EditUserForm = ({ onSave, onCancel, showUserRole, currentRole, userData, projectOptions, companyOptions, submitting, setSubmitting }) => {
    console.log(userData);
    //redux state
    const currentCompany = useSelector(state => state.currentCompany);
    const currentUser = useSelector(state => state.currentUser);

    //local state
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [active, setActive] = useState('');
    const [address, setAddress] = useState('');
    const [mailingAddress, setMailingAddress] = useState('');

    //these options are made if the form is used in the create client modal
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedCompanyRole, setSelectedCompanyRole] = useState('user');

    //handling Form Errors
    const [errors, setErrors] = useState('');

    //effect hook to populate the data using the passed user object
    const loadUserData = () => {
        if (userData) {
            if (userData.firstName) setFirstName(userData.firstName);
            if (userData.lastName) setLastName(userData.lastName);
            if (userData.contactName) setContactName(userData.contactName);
            if (userData.phoneNumber) setPhoneNumber(userData.phoneNumber);
            if (userData.email) setEmail(userData.email);
            if (userData.address) setAddress(userData.address);
            if (userData.mailing_address) setMailingAddress(userData.mailing_address);

            if (userData.active !== undefined) setActive(userData.active);
            else setActive(false);

            if (showUserRole && currentRole) setSelectedCompanyRole(currentRole);
        }
    };

    useEffect(() => {
        loadUserData();
    }, [userData]);

    useEffect(() => {
        if (currentCompany._id) setSelectedCompany({ value: currentCompany._id, label: currentCompany.name });
    }, []);



    const onCancelClick = () => {
        loadUserData();
        setErrors('');
        onCancel();
    };

    // effect to validate inputs with thier change
    useEffect(() => {
        const newErrors = {};

        //validating inputs
        if (firstName && validateSmallTextNoNumbers(firstName, 'First Name').error) newErrors.firstName = validateSmallTextNoNumbers(firstName, 'First Name').error;
        if (lastName && validateSmallTextNoNumbers(lastName, 'Last Name').error) newErrors.lastName = validateSmallTextNoNumbers(lastName, 'Last Name').error;
        if (contactName && validateSmallTextNoNumbers(contactName, 'Contact Name').error) newErrors.contactName = validateSmallTextNoNumbers(contactName, 'Contact Name').error;

        if (phoneNumber && validatePhoneNumber(phoneNumber).error) newErrors.phoneNumber = validatePhoneNumber(phoneNumber).error;

        if (email && !isEmail(email)) newErrors.email = 'Email is not valid';

        if (address && validateSmallText(address, 'Address').error) newErrors.address = validateSmallText(address, 'Address').error;
        if (mailingAddress && validateSmallText(mailingAddress, 'Mailing Address').error) newErrors.mailingAddress = validateSmallText(mailingAddress, 'Mailing Address').error;

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});
    }, [firstName, lastName, email, contactName, phoneNumber, address, mailingAddress]);


    const onSaveClick = async () => {
        const newErrors = {};

        //validating inputs
        if (validateSmallTextNoNumbers(firstName, 'First Name').error) newErrors.firstName = validateSmallTextNoNumbers(firstName, 'First Name').error;
        if (validateSmallTextNoNumbers(lastName, 'Last Name').error) newErrors.lastName = validateSmallTextNoNumbers(lastName, 'Last Name').error;
        if (validateSmallTextNoNumbers(contactName, 'Contact Name').error) newErrors.contactName = validateSmallTextNoNumbers(contactName, 'Contact Name').error;

        if (validatePhoneNumber(phoneNumber).error) newErrors.phoneNumber = validatePhoneNumber(phoneNumber).error;

        if (validateSmallText(address, 'Address').error) newErrors.address = validateSmallText(address, 'Address').error;
        if (validateSmallText(mailingAddress, 'Mailing Address').error) newErrors.mailingAddress = validateSmallText(mailingAddress, 'Mailing Address').error;

        if (!email) newErrors.email = 'Please enter email';
        else if (!isEmail(email)) newErrors.email = 'Email is not valid';

        if (companyOptions && !selectedCompany) newErrors.company = 'Please select a company';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});


        const updatedData = {
            _id: userData._id,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            contactName: contactName.trim(),
            phoneNumber: phoneNumber.trim(),
            email: email.trim(),
            address: address.trim(),
            mailing_address: mailingAddress.trim(),
            active: active,
            companyId: selectedCompany?.value ? selectedCompany.value : undefined,
        };
        if (showUserRole) {
            updatedData.companyRole = selectedCompanyRole;
        }

        onSave(updatedData);
    };

    const isSystemAdminOrCompanySupperAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }

        if (currentCompany?._id) {
            if (currentCompany?.client_supper_admins?.includes(currentUser._id)) return true;
        }
        return false;
    };

    return (
        <Form>
            <Row className="mb-3">
                <Col md={12} className="pb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <label className="form-label">Status:</label>

                        <ButtonGroup aria-label="Basic example">
                            <Button variant="outline-secondary" className={active ? 'active' : ''} onClick={() => setActive(true)}>Active</Button>
                            <Button variant="outline-secondary" className={active ? '' : 'active'} onClick={() => setActive(false)}>Inactive</Button>
                        </ButtonGroup>

                        <div></div>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3 pb-3">
                <Col md={6}>
                    <div className="position-relative">
                        <Form.Label className="custom-form-label">First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="First Name"
                            aria-label="First Name"
                            value={firstName} onChange={e => setFirstName(e.target.value.trimStart())}
                            isInvalid={errors.firstName ? true : false}
                        />
                        {errors.firstName && <p className='text-red m-0 p-0 text-sm'>{errors.firstName}</p>}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="pb-3 position-relative">
                        <Form.Label className="custom-form-label">Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Last Name"
                            aria-label="Last Name"
                            value={lastName} onChange={e => setLastName(e.target.value.trimStart())}
                            isInvalid={errors.lastName ? true : false}
                        />
                        {errors.lastName && <p className='text-red m-0 p-0 text-sm'>{errors.lastName}</p>}
                    </div>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={12}>
                    <div className="pb-3 position-relative">
                        <Form.Label className="custom-form-label">Contact Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Contact Name"
                            aria-label="Contact Name"
                            value={contactName} onChange={e => setContactName(e.target.value.trimStart())}
                            isInvalid={errors.contactName ? true : false}
                        />
                        {errors.contactName && <p className='text-red m-0 p-0 text-sm'>{errors.contactName}</p>}
                    </div>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={12}>
                    <div className="pb-3 position-relative">
                        <Form.Label className="custom-form-label">Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            aria-label="Email"
                            value={email} onChange={e => setEmail(e.target.value.trimStart())}
                            isInvalid={errors.email ? true : false}
                        />
                        {errors.email && <p className='text-red m-0 p-0 text-sm'>{errors.email}</p>}
                    </div>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={12}>
                    <div className="pb-3 position-relative">
                        <Form.Label className="custom-form-label">Phone Number</Form.Label>
                        <Form.Control
                            type="phone"
                            placeholder="Phone"
                            aria-label="Phone"
                            value={phoneNumber} onChange={e => setPhoneNumber(e.target.value.trimStart())}
                            isInvalid={errors.phoneNumber ? true : false}
                        />
                        {errors.phoneNumber && <p className='text-red m-0 p-0 text-sm'>{errors.phoneNumber}</p>}
                    </div>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={12}>
                    <div className="pb-3 position-relative">
                        <Form.Label className="custom-form-label">Address</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Address"
                            aria-label="Address"
                            value={address} onChange={e => setAddress(e.target.value.trimStart())}
                            isInvalid={errors.address ? true : false}
                        />
                        {errors.address && <p className='text-red m-0 p-0 text-sm'>{errors.address}</p>}
                    </div>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={12}>
                    <div className="pb-3 position-relative">
                        <Form.Label className="custom-form-label">Mailing Address</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Mailing Address"
                            aria-label="Mailing Address"
                            value={mailingAddress} onChange={e => setMailingAddress(e.target.value.trimStart())}
                            isInvalid={errors.mailingAddress ? true : false}
                        />
                        {errors.mailingAddress && <p className='text-red m-0 p-0 text-sm'>{errors.mailingAddress}</p>}
                    </div>
                </Col>
            </Row>

            {companyOptions && <fieldset className="pb-4">
                <label className="simple-title">Company Assignment</label>
                <Row className="pt-2">
                    <Col md={12}>
                        <div className="pb-3 position-relative">
                            <Select
                                placeholder="Select a company"
                                options={companyOptions}
                                value={selectedCompany}
                                onChange={company => setSelectedCompany(company)}
                                isDisabled
                            />
                        </div>
                    </Col>
                </Row>
            </fieldset>}

            {projectOptions && <fieldset className="pb-4">
                <label className="simple-title">Project Assignment</label>
                <Row className="pt-2">
                    <Col md={12}>
                        <div className="pb-3 position-relative">
                            <Select
                                placeholder="Select a project"
                                options={projectOptions}
                                isDisabled={selectedCompany?.value ? false : true}
                                value={selectedProject}
                                onChange={project => console.log(project)}
                                isClearable
                            />
                        </div>
                    </Col>
                </Row>
            </fieldset>}

            {showUserRole && <Row className="mb-3">
                <Col md={12} className="pb-3">
                    <label className="simple-title">Select Role</label>
                    <div className="d-flex align-items-center justify-content-center pt-2">
                        <ButtonGroup aria-label="Basic example">
                            {/* {isSystemAdminOrCompanySupperAdmin() && <Button variant="outline-secondary" className={selectedCompanyRole === 'supper_admin' ? 'active' : ''} onClick={() => setSelectedCompanyRole('supper_admin')}>Supper Admin</Button>} */}
                            <Button variant="outline-secondary" className={selectedCompanyRole === 'admin' ? 'active' : ''} onClick={() => setSelectedCompanyRole('admin')}>Admin</Button>
                            <Button variant="outline-secondary" className={selectedCompanyRole === 'user' ? 'active' : ''} onClick={() => setSelectedCompanyRole('user')}>User</Button>
                        </ButtonGroup>

                        <div></div>
                    </div>
                </Col>
            </Row>}

            <div className="ms-auto d-flex">
                <Button variant="outline-primary" className="ms-2" onClick={onCancelClick}>Cancel Changes</Button>
                <Button variant="primary" className="ms-2" onClick={onSaveClick} disabled={submitting}>{submitting ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : 'Save'}</Button>
            </div>
            {/* <Col md={12}>
                <div className="mb-3">
                    {Object.keys(errors).length > 0 && <p className='text-red m-0 p-0 text-sm'>Please fix the above errors</p>}
                </div>
            </Col> */}
        </Form>
    );
};

export default EditUserForm;