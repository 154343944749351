import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination, InputGroup } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import server from '../../../apis/server';
import moment from 'moment';

//actions
import { loadCurrentProject } from '../../../redux_actions/currentProjectActions';

//icons
import { Icon } from '@iconify/react';
import pencilSquare from '@iconify/icons-bi/pencil-square';
import trash3Fill from '@iconify/icons-bi/trash3-fill';
import eyeFill from '@iconify/icons-bi/eye-fill';

//Modals
import LogPayment from './modals/LogPayment';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import ProjectNavigationBar from '../../common/ProjectNavigationBar/ProjectNavigationBar';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import NoData from '../../common/NoData/NoData';
import FeeItem from './FeeItem';

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectsFeesPage = () => {
    const { projectId } = useParams();

    //redux state
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    const [showEditLogPayment, setShowLogPayment] = useState(false);
    const [projectFees, setProjectFees] = useState([]);

    const getFees = async () => {
        try {
            const response = await server.get('/fees', {
                params: {
                    companyId: currentCompany._id,
                    project_id: currentProject._id,
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setProjectFees(response.data);
        } catch (error) {
            console.log({ error: error.message });
        }
    };


    useEffect(() => {
        if (currentCompany._id && currentProject._id) getFees();
    }, [currentCompany, currentProject]);


    useEffect(() => {
        dispatch(loadCurrentProject(projectId));
    }, [projectId]);

    const getUnpaidAmount = () => {
        const unpaidFees = projectFees.filter(fee => fee.paid === false);
        let unpaidAmount = 0;
        unpaidFees.forEach(fee => unpaidAmount += fee.amount);
        return Math.round(unpaidAmount * 100) / 100;
    };
    const getTotalAmount = () => {
        let totalAmount = 0;
        projectFees.forEach(fee => totalAmount += fee.amount);
        return Math.round(totalAmount * 100) / 100;
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">

                    <ProjectNavigationBar />

                    <Container fluid className="py-3">

                        <Row className="py-4">
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <h4 className="mb-0">Fees</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>

                                            <Col md={12}>
                                                {projectFees.length > 0
                                                    ? <Table bordered striped>
                                                        <thead>
                                                            <tr>
                                                                <th>Notes</th>
                                                                <th>Logged By</th>
                                                                <th>Paid</th>
                                                                <th>Amount paid</th>
                                                                <th>Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {projectFees.length > 0 && projectFees.map(fee => <FeeItem fee={fee} getFees={getFees} />)}

                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="5">
                                                                    <div className="text-right py-2"><strong>Total Fees:</strong><strong className="d-inline-block ps-2 text-md">${getTotalAmount()}</strong></div>
                                                                    <div className="text-right py-2"><strong>Unpaid Balance:</strong><strong className="d-inline-block ps-2 text-lg">${getUnpaidAmount()}</strong></div>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                    : <NoData message={'No fees logged'} />
                                                }

                                                <div className="d-flex justify-content-end align-items-center">
                                                    <Button variant="primary" type="button" className="ms-2" onClick={() => setShowLogPayment(true)}>Log payment</Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Container>

                </div>
            </main>

            <LogPayment showModal={showEditLogPayment} setShowModal={setShowLogPayment} getFees={getFees} />
        </div>
    );
};

export default ProjectsFeesPage;