import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';

//comp
import CreateUserBasicForm from '../../../common/Forms/CreateUserBasicForm';
import server from '../../../../apis/server';

const CreateSystemAdminUser = ({ showModal, setShowModal, setAdmins, admins }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);

    //local
    const [responseError, setResponseError] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const onModalHide = () => {
        setShowModal(false);
    };

    const onContinueClick = async (createUserFormData) => {
        setSubmitting(true);
        try {
            const response = await server.post('/admins/create', { userData: createUserFormData, method: 'admin-portal' }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            // clearing response error from local state if any
            setResponseError('');
            setSubmitting(false);

            // adding the newely created admin user to the UI admins list
            if (response.data._id) setAdmins([...admins, response.data]);

            //closing the Modal
            onModalHide();

        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });
            if (error.response.status === 409) {
                setResponseError('There is already an account associated with this email');
            } else {
                setResponseError('Something went wrong please try again');
            }
        }
    };

    return (
        <Modal show={showModal} onHide={onModalHide} centered>
            <Modal.Header closeButton className="ps-md-4">
                <h2>Create System Admin User</h2>
            </Modal.Header>

            <Modal.Body className="px-md-4">

                <CreateUserBasicForm
                    actionButtonLabel="Create System Admin"
                    infoTitle={'Admin Information'}
                    onCreateUserClick={onContinueClick}
                    onCancelClick={onModalHide}
                    submitting={submitting} setSubmitting={setSubmitting}
                />

                <Row>
                    <Col md={12}>
                        <div className="mb-3">
                            {responseError && <p className="text-red">{responseError}</p>}
                        </div>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal >
    );
};

export default CreateSystemAdminUser;