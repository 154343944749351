import server from '../apis/server';

export const setDocuments = (payload) => {
    return {
        type: 'SET_DOCUMENTS',
        payload
    };
};

export const addDocument = (payload) => {
    return {
        type: 'ADD_DOCUMENT',
        payload
    };
};