import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

//icons
import { Icon } from '@iconify/react';
import angleLeft from '@iconify/icons-la/angle-left';

const ProjectNavigationBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { projectId } = useParams();

    //redux
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const projectUnreadNotifications = useSelector(state => state.projectUnreadNotifications);

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    return (
        <div>

            <div className="top-bar ps-0">
                <h2 className="d-flex align-items-center mb-0">
                    <span className="btn--dark pointer" onClick={() => navigate('/projects')}><span className="d-inline-block pe-3"><Icon icon={angleLeft} width="32" height="32" /></span></span>
                    {currentCompany.name} - {currentProject.name}
                </h2>
            </div>

            <Nav variant="tabs">
                <Nav.Item>
                    <Nav.Link active={location.pathname.includes(`/projectoverview`)} onClick={() => navigate(`/projectoverview/${projectId}`)}>Overview</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={location.pathname.includes(`/projectsdocument`)} onClick={() => navigate(`/projectsdocument/${projectId}`)}>Documents</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={location.pathname.includes(`/projectsnotes`)} onClick={() => navigate(`/projectsnotes/${projectId}`)}>Notes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={location.pathname.includes(`/projectsfees`)} onClick={() => navigate(`/projectsfees/${projectId}`)}>Fees</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={location.pathname.includes(`/projectsteam`)} onClick={() => navigate(`/projectsteam/${projectId}`)}>Team</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={location.pathname.includes(`/projectnotifications`)} onClick={() => navigate(`/projectnotifications/${projectId}`)} style={{ minWidth: 185 }}>Notifications
                        <span className="project-notifications">
                            {projectUnreadNotifications > 0 && <span className="badge white">{projectUnreadNotifications}</span>}
                        </span>
                    </Nav.Link>
                </Nav.Item>
                {isSystemAdmin() && <Nav.Item>
                    <Nav.Link active={location.pathname.includes(`/projectshours`)} onClick={() => navigate(`/projectshours/${projectId}`)}>Hours</Nav.Link>
                </Nav.Item>}
            </Nav>
        </div>
    );
};

export default ProjectNavigationBar;