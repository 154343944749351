import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Spinner, Button, Table, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import server from '../../../apis/server';
import Select from 'react-select';
import { validateSmallTextNoNumbers, validateZipCode, validatePhoneNumber, validateSmallNumber } from '../../utils/customValidations';

//icons
import { Icon } from '@iconify/react';
import filterIcon from '@iconify/icons-bi/filter';
import searchIcon from '@iconify/icons-bi/search';

//Modals

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import SideNavigation from '../../common/SideNavigation/SideNavigation';

const ProjectsPage = () => {
    const navigate = useNavigate();
    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);

    //local state
    const [hourlyRate, setHourlyRate] = useState(0);
    const [selectedInterval, setSelectedInterval] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const [savedSettings, setSavedSettings] = useState({});

    const getSettings = async () => {
        try {
            const response = await server.get('/settings', {
                params: {},
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            if (response.data?.hourly_rate) setSavedSettings(response.data);

        } catch (error) {
            console.log({ error: error.message });
        }

    };


    useEffect(() => {
        getSettings();
    }, []);

    //disable the save button if the values are not changed
    const checkDisabled = () => {
        if (savedSettings?.hourly_rate !== hourlyRate) return false;
        if (selectedInterval && savedSettings?.check_interval !== selectedInterval.value) return false;
        return true;
    };

    useEffect(() => {
        if (validateSmallNumber(hourlyRate, 'Hourly Rate').error) errors.hourlyRate = validateSmallNumber(hourlyRate, 'Hourly Rate').error;
        else setErrors({});
        if (!hourlyRate) setErrors({});
    }, [hourlyRate]);

    useEffect(() => {
        if (savedSettings.hourly_rate) setHourlyRate(savedSettings.hourly_rate);
        if (savedSettings.check_interval) setSelectedInterval({ value: savedSettings.check_interval, label: savedSettings.check_interval });
    }, [savedSettings]);

    const onSaveClick = async () => {
        const newErrors = {};

        if (validateSmallNumber(hourlyRate, 'Hourly Rate').error) newErrors.hourlyRate = validateSmallNumber(hourlyRate, 'Hourly Rate').error;

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        try {
            const response = await server.post('/settings/update', {
                hourly_rate: hourlyRate,
                check_interval: selectedInterval.value
            }, {
                params: {},
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            if (response.data?.hourly_rate) {
                setSavedSettings(response.data);
            }
        } catch (error) {
            console.log({ error: error.message });
        }

    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">
                    <div className="top-bar">
                        <h2>System Settings</h2>
                    </div>

                    {/* <div className="d-flex justify-content-between align-items-center px-3 py-2">
                        <Form>
                            <div className="input-search" controlid="exampleForm.ControlInput1">
                                <span className="input-search--icon"><Icon icon={searchIcon} width="24" height="24" /></span>
                                <Form.Control type="text" placeholder="Search" />
                            </div>
                        </Form>

                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="primary">
                                <Icon icon={filterIcon} width="24" height="24" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div> */}

                    <Container fluid className="px-3 py-2">

                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Value</th>
                                    <th>Description</th>
                                    {/* <th>Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>

                                <tr style={{ height: 80 }}>
                                    <td>Hourly Rate</td>
                                    <td>
                                        <InputGroup>
                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                            <Form.Control
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                type='number'
                                                placeholder='Please enter hourly rate'
                                                value={hourlyRate} onChange={e => {
                                                    if (parseInt(e.target.value.trimStart())) setHourlyRate(parseInt(e.target.value.trimStart()));
                                                    else setHourlyRate('');
                                                }}

                                            />
                                        </InputGroup>
                                        {errors.hourlyRate && <p className='text-red m-0 p-0 text-sm'>{errors.hourlyRate}</p>}
                                    </td>
                                    <td>Ryan's hourly rate earned for project work</td>
                                </tr>

                                <tr style={{ height: 80 }}>
                                    <td>Check In Interval</td>
                                    <td>
                                        <Select
                                            placeholder="Select a project"
                                            options={['1 Day', '2 Days', '3 Days', '4 Days', '5 Days'].map(el => { return { value: el, label: el }; })}
                                            value={selectedInterval}
                                            onChange={interval => setSelectedInterval(interval)}
                                        />
                                        {errors.interval && <p className='text-red m-0 p-0 text-sm'>{errors.interval}</p>}
                                    </td>
                                    <td>when System Admins get notified to check in on a Project</td>
                                </tr>

                            </tbody>
                        </Table>

                        <Row>

                            <Col md={12}>
                                <div className="mb-3 d-flex align-items-center justify-content-end">
                                    <Button variant="primary" disabled={checkDisabled()} onClick={onSaveClick}>{submitting ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : 'Save'}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </main>
        </div>
    );
};

export default ProjectsPage;