import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, Table, Pagination } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';

//actions
import { loadCurrentProject } from '../../../redux_actions/currentProjectActions';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import ProjectNavigationBar from '../../common/ProjectNavigationBar/ProjectNavigationBar';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import server from '../../../apis/server';

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectsTeamPage = () => {
    const { projectId } = useParams();

    //redux state
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    const [projectNotifications, setProjectNotification] = useState([]);

    const getProjectNotifications = async () => {
        try {
            const response = await server.get('/projectnotifications', {
                params: {
                    companyId: currentCompany._id,
                    project_id: currentProject._id,
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) setProjectNotification(response.data);
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        if (currentCompany._id && currentProject._id) getProjectNotifications();
    }, [currentCompany, currentProject]);

    useEffect(() => {
        dispatch(loadCurrentProject(projectId));
    }, [projectId]);

    const onViewClick = async (id) => {
        try {
            const response = await server.post('/notifications/project/read', { id }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) getProjectNotifications();
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">

                    <ProjectNavigationBar />

                    <Container fluid className="py-3">

                        <Row className="py-4">
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <h4 className="mb-0">Notifications</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>

                                            <Col md={12}>
                                                <Table bordered striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Send by</th>
                                                            <th>Received by</th>
                                                            <th>Date</th>
                                                            <th>Message</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {projectNotifications.map(el => {

                                                            const { title, message, sent_by, received_by, createdAt, read } = el;

                                                            return (
                                                                <tr>
                                                                    <td>{title}</td>
                                                                    <td>{sent_by ? sent_by.contactName : null}</td>
                                                                    <td>All public</td>
                                                                    <td>{moment(createdAt).format('lll')}</td>
                                                                    <td>{message}</td>
                                                                    <td>{el.read === true ? 'Read' : 'New'}</td>
                                                                    <td><span className="btn-link--primary pointer" onClick={() => onViewClick(el._id)}>{el.read === false ? 'View' : ''}</span></td>
                                                                </tr>
                                                            );
                                                        })}


                                                    </tbody>
                                                </Table>

                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Container>

                </div>
            </main>
        </div>
    );
};

export default ProjectsTeamPage;