import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import server from '../../../apis/server';
import moment from 'moment';

//icons
import { Icon } from '@iconify/react';
import pencilSquare from '@iconify/icons-bi/pencil-square';
import trash3Fill from '@iconify/icons-bi/trash3-fill';
import eyeFill from '@iconify/icons-bi/eye-fill';

//Modals
import LogPayment from './modals/LogPayment';

const FeeItem = ({ fee, getFees }) => {
    //redux
    const currentUser = useSelector(state => state.currentUser);
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local    
    const [showEditLogPayment, setShowLogPayment] = useState(false);


    const { note, amount, paid, log_date, logged_by, receipt } = fee;

    const onFeePaidCheck = async (e) => {
        try {
            const response = await server.post('/fees/editpaid', { companyId: currentCompany._id, feeId: fee._id, paid: e.target.checked }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?._id) {
                getFees();
            }
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const onFeeDeleteClick = async (e) => {
        try {
            const response = await server.post('/fees/delete', { companyId: currentCompany._id, feeId: fee._id }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) {
                getFees();
            }
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    return (
        <tr>
            <td>{note}</td>
            <td>{logged_by.contactName}</td>
            <td>
                <label className="checkbox">
                    <input type='checkbox' checked={paid} onClick={onFeePaidCheck} disabled={!isSystemAdmin()} />
                    <span className="checkbox-checkmark"></span>
                </label>
            </td>
            <td>${amount}</td>

            <td>{moment(log_date).format('lll')}</td>
            <td>
                <span className="btn-link py-0 pointer" onClick={() => setShowLogPayment(true)}><Icon icon={pencilSquare} width="18" height="18" /></span>
                <span className="btn-link py-0 pointer" onClick={onFeeDeleteClick}><Icon icon={trash3Fill} width="18" height="18" /></span>
                {receipt?.url && <a href={receipt.url} className="btn-link py-0" without rel="noopener noreferrer" target="_blank"><Icon icon={eyeFill} width="18" height="18" /></a>}
            </td>
            <LogPayment showModal={showEditLogPayment} setShowModal={setShowLogPayment} getFees={getFees} fee={fee} />

        </tr>
    );
};

export default FeeItem;