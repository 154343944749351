import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

//actions
import { setCurrentProject } from '../../../redux_actions/currentProjectActions';

//icons
import { Icon } from '@iconify/react';
import pencilSquare from '@iconify/icons-bi/pencil-square';
import trash3Fill from '@iconify/icons-bi/trash3-fill';
import eyeFill from '@iconify/icons-bi/eye-fill';
import server from '../../../apis/server';

//comp
import EditClient from './modals/EditClient';

const UserItem = ({ user }) => {
    let navigate = useNavigate();

    const { contactName, email, phoneNumber, updatedAt } = user;

    //redux state
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local
    const [showEditModal, setShowEditModal] = useState(false);

    const isSystemAdminOrCompanySupperAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }

        if (currentCompany?._id) {
            if (currentCompany?.client_admins?.includes(currentUser._id)) return true;
        }
        return false;
    };


    const onOwnerCheckboxClick = async () => {
        try {
            if (currentProject?.owner?._id !== user._id) {
                const response = await server.post('/project/changeowner', { companyId: currentCompany._id, projectId: currentProject._id, newOwnerId: user._id }, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });

                if (response.data._id) {
                    dispatch(setCurrentProject(response.data));
                }
            }


        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const onRemoveClick = async () => {
        try {
            if (currentProject?.owner?._id !== user._id) {
                const response = await server.post('/project/removefromteam', { companyId: currentCompany._id, projectId: currentProject._id, userId: user._id }, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });

                if (response.data._id) {
                    dispatch(setCurrentProject(response.data));
                }
            }

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const getUserRole = () => {
        if (currentCompany?.client_admins?.includes(user._id)) return 'Client Admin';
        if (currentCompany?.client_supper_admins?.includes(user._id)) return 'Client Supper Admin';
        if (currentCompany?.client_users?.includes(user._id)) return 'Client User';
        return 'System Admin';
    };

    return (
        <tr>
            <td>{contactName}</td>
            <td><a href="mailto:joshjohson@tepia.com">{email}</a></td>
            <td><a href="tel:972666555">{phoneNumber}</a></td>
            <td>
                <label className="checkbox">
                    <input type='checkbox'
                        onClick={onOwnerCheckboxClick}
                        disabled={!isSystemAdminOrCompanySupperAdmin()}
                        checked={currentProject?.owner?._id === user._id}
                    />
                    <span className="checkbox-checkmark"></span>
                </label>
            </td>
            <td>{getUserRole()}</td>
            <td>{moment(updatedAt).format('lll')}</td>
            <td>
                <span className="btn-link py-0 pointer" onClick={() => setShowEditModal(true)}><Icon icon={pencilSquare} width="18" height="18" /></span>
                <span className={`py-0 ${currentProject?.owner?._id === user._id ? 'text-grey px-2' : 'btn-link pointer'}`} onClick={onRemoveClick}><Icon icon={trash3Fill} width="18" height="18" /></span>
                <span className="btn-link py-0 pointer" onClick={() => navigate(`/user/${user._id}`)}><Icon icon={eyeFill} width="18" height="18" /></span>
            </td>
            <EditClient showModal={showEditModal} setShowModal={setShowEditModal} userData={user} />
        </tr>
    );
};

export default UserItem;