import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, Spinner, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import server from '../../../apis/server';
import moment from 'moment';
import { CSVLink } from "react-csv";

//icons
import { Icon } from '@iconify/react';
import filterIcon from '@iconify/icons-bi/filter';
import searchIcon from '@iconify/icons-bi/search';

//Modals

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import useDebounce from '../../utils/useDebounce';

const ProjectsPage = () => {
    const navigate = useNavigate();
    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);

    //local state
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    //Searching and sorting state
    const [searchInput, setSearchInput] = useState('');
    const [sortBy, setSortBy] = useState('Date');
    const [sortOrder, setSortOrder] = useState(-1);

    const debouncedSearch = useDebounce(searchInput, 500);

    //csv ref
    const csvLink = useRef();


    const getSystemNotifications = async () => {
        try {
            setLoading(true);
            const response = await server.get('/notifications/system', {
                params: { search: debouncedSearch, sortBy, sortOrder },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) setNotifications(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log({ error: error.message });
        }
    };

    const onViewClick = async (id) => {
        try {
            const response = await server.post('/notifications/system/read', { id }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) getSystemNotifications();
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        getSystemNotifications();
    }, [debouncedSearch, sortBy, sortOrder]);

    const onSearchClear = () => {
        setSearchInput('');
    };

    const onSortByChange = (sort) => {
        setSortBy(sort);
    };

    const getCSVData = () => {
        if (!notifications || notifications.length === 0) return [];
        const data = notifications.map(el => {
            const { title, message, sent_by, received_by, createdAt, projectName, project_id, read } = el;

            return {
                'Title': title,
                'Send by': sent_by ? sent_by.contactName : null,
                'Received by': 'All public',
                'Message': message,
                'Project Name': projectName,
                'Status': read === true ? 'Read' : 'New'
            };
        });
        return data;
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">
                    <div className="top-bar">
                        <h2>Notifications</h2>
                    </div>

                    <Container fluid className="px-3 py-2">

                        <Row className="py-4">
                            <Col>
                                <Card>

                                    <Card.Header>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-0">Notifications</h4>
                                            <Button variant="outline-primary" type="submit" className="ms-2" size="sm" onClick={() => csvLink.current.link.click()}>Export <span className="text-uppercase">CSV</span></Button>
                                        </div>
                                    </Card.Header>

                                    <div className="d-flex justify-content-between align-items-center px-3 py-2">
                                        <div className="input-search" controlid="exampleForm.ControlInput1">
                                            <span className="input-search--icon">
                                                {!searchInput && !loading && < Icon className="pointer" icon={searchIcon} width="24" height="24" />}
                                                {searchInput && !loading && <Icon className="pointer" icon="ic:round-clear" width="24" height="24" onClick={onSearchClear} />}
                                                {loading && <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />}

                                            </span>
                                            <Form.Control type="text" placeholder="Search" value={searchInput} onChange={e => setSearchInput(e.target.value.trimStart())} />
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link" id="dropdown-basic" className="primary">
                                                    <Button variant="outline-secondary" size="sm">Sort By - {sortBy} <Icon icon={filterIcon} width="24" height="24" /></Button>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onSortByChange('Title')}>Title</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => onSortByChange('Message')}>Message</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => onSortByChange('Date')}>Date</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => onSortByChange('Status')}>Status</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <div>
                                                {sortOrder === 1 && <Icon icon="cil:sort-ascending" className="pointer" onClick={() => setSortOrder(-1)} />}
                                                {sortOrder === -1 && <Icon icon="cil:sort-descending" className="pointer" onClick={() => setSortOrder(1)} />}
                                            </div>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Row>

                                            <Col md={12}>
                                                <Table bordered striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Send by</th>
                                                            <th>Received by</th>
                                                            <th>Date</th>
                                                            <th>Message</th>
                                                            <th>Project Name</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {notifications.map(el => {

                                                            const { title, message, sent_by, received_by, createdAt, projectName, project_id, read } = el;

                                                            return (
                                                                <tr>
                                                                    <td>{title}</td>
                                                                    <td>{sent_by ? sent_by.contactName : null}</td>
                                                                    <td>All public</td>
                                                                    <td>{moment(createdAt).format('lll')}</td>
                                                                    <td>{message}</td>
                                                                    <td><span className="btn-link--primary pointer" onClick={() => navigate(`/projectoverview/${project_id}`)}>{projectName}</span></td>
                                                                    <td>{el.read === true ? 'Read' : 'New'}</td>
                                                                    <td><span className="btn-link--primary pointer" onClick={() => onViewClick(el._id)}>{el.read === false ? 'View' : ''}</span></td>
                                                                </tr>
                                                            );
                                                        })}


                                                    </tbody>
                                                </Table>

                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </main>
            {notifications?.length > 0 && <CSVLink ref={csvLink} className="text-highliten d-none" data={getCSVData()} filename={`notifications.csv`} style={{ textDecoration: 'none', color: 'white' }}></CSVLink>}
        </div>
    );
};

export default ProjectsPage;