import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, ButtonGroup } from 'react-bootstrap';
import { isEmail } from 'validator';
import Select from 'react-select';
import server from '../../../../apis/server';

//actions
import { setCurrentProject } from '../../../../redux_actions/currentProjectActions';

//validation functions
import { validateMessageInput } from '../../../utils/customValidations';

const InviteClient = ({ showModal, setShowModal }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    const [companyUsers, setCompanyUsers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [responseError, setResponseError] = useState('');

    const getCompanyUsers = async () => {
        try {
            const response = await server.get('/companyusers', {
                params: {
                    companyId: currentCompany._id
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) setCompanyUsers(response.data.map(el => {
                return { ...el, value: el._id, label: el.contactName };
            }));
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        if (currentCompany._id) getCompanyUsers();
    }, [currentCompany]);

    const updateSelectedTeam = () => {
        if (currentProject?.assigned_team) {
            let team = currentProject.assigned_team;
            let selected = companyUsers.filter(el => team.includes(el._id));
            console.log(selected);
            setSelectedTeam(selected);
        }
    };

    useEffect(() => {
        updateSelectedTeam();
    }, [currentProject, companyUsers]);

    const onModalHide = () => {
        setShowModal(false);
        updateSelectedTeam();
        //reseting inputs
        setError({});
        setResponseError('');
    };

    const onInviteClick = async () => {
        if (!selectedTeam || selectedTeam.length === 0) return setError('Please select team members');
        setError('');

        setSubmitting(true);

        try {
            const data = {
                usersArray: selectedTeam.map(el => el._id),
                projectId: currentProject._id,
                companyId: currentCompany._id
            };

            const response = await server.post('/project/updateteam', data, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data._id) {
                setSubmitting(false);
                setError({});
                onModalHide();
                dispatch(setCurrentProject(response.data));
            }



        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });
        }
    };

    const isSystemAdminOrCompanySupperAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }

        if (currentCompany?._id) {
            if (currentCompany?.client_supper_admins?.includes(currentUser._id)) return true;
        }
        return false;
    };

    return (
        <Modal show={showModal} onHide={onModalHide} centered>
            <Modal.Header closeButton className="ps-md-5">
                <h2>Add Existing Client to Create Corp</h2>
            </Modal.Header>

            <Modal.Body className="px-md-5">
                <Form>

                    <label className="simple-title">Select Users</label>
                    <Row className="mb-3">
                        <Col md={12}>
                            <div className="pb-3 position-relative">
                                <Select
                                    isMulti
                                    placeholder={"Select project team member(s)"}
                                    options={companyUsers}
                                    value={selectedTeam}
                                    onChange={users => {
                                        if (!users.map(el => el._id).includes(currentProject.owner._id)) {
                                            const owner = companyUsers.find(el => el._id === currentProject.owner._id);
                                            users.push(owner);
                                        }
                                        setSelectedTeam(users);
                                    }}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        menu: provided => ({ ...provided, zIndex: 9999 }),
                                        input: provided => ({ ...provided, paddingTop: '5px', paddingBottom: '5px' }),

                                    }}
                                />
                                {/* {error && <p className='text-red m-0 p-0 text-sm'>{error}</p>} */}
                            </div>
                            Note: Project owner can't be unselected.
                        </Col>
                    </Row>

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <div className="ms-auto d-flex">
                    <Button variant="outline-primary" type="submit" className="ms-2" onClick={onModalHide}>Cancel</Button>
                    <Button variant="primary" type="submit" className="ms-2" onClick={onInviteClick} disabled={submitting}>
                        {submitting
                            ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />
                            : 'Add Existing Client'
                        }
                    </Button>


                </div>
                <div className="mb-3">
                    {responseError && <p className="text-red">{responseError}</p>}
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default InviteClient;