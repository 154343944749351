import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import moment from 'moment';

const CompanyTableRaw = ({ company }) => {
    const navigate = useNavigate();

    const {
        name,
        location_city,
        location_state,
        location_zip,
        phoneNumber,
        contractor_license_number,
        workers_compensation_company_name,
        workers_compensation_expiration_date,
        workers_compensation_policy_number,
        color
    } = company;

    return (
        <tr>
            <td><div className="d-flex align-items-center"><span className="color-indicator" style={{ background: color }}></span><span>{name}</span></div></td>
            <td>{location_city}</td>
            <td>{location_state}</td>
            <td>{location_zip}</td>
            <td>{phoneNumber}</td>
            <td>{contractor_license_number}</td>
            <td>{workers_compensation_company_name}</td>
            <td>{workers_compensation_policy_number}</td>
            <td>{moment(workers_compensation_expiration_date).format('ll')}</td>
            <td><a className="btn-link-primary pointer" onClick={() => navigate(`/client/${company._id}`)}>View</a></td>
        </tr>
    );
};

export default CompanyTableRaw;