import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination, InputGroup } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import server from '../../../apis/server';

//actions
import { loadCurrentProject } from '../../../redux_actions/currentProjectActions';

//icons
import { Icon } from '@iconify/react';
import pencilSquare from '@iconify/icons-bi/pencil-square';
import trash3Fill from '@iconify/icons-bi/trash3-fill';
import eyeFill from '@iconify/icons-bi/eye-fill';

//Modals
import CreateClient from './modals/CreateClient';
import InviteClient from './modals/InviteClient';
import AddExistingClient from './modals/AddExistingClient';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import ProjectNavigationBar from '../../common/ProjectNavigationBar/ProjectNavigationBar';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import UserItem from './UserItem';

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectsTeamPage = () => {
    const { projectId } = useParams();

    //redux state
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    const [team, setTeam] = useState([]);

    const [showCreateClient, setShowCreateClient] = useState(false);
    const [showInviteClient, setShowInviteClient] = useState(false);
    const [showAddExistingClient, setShowAddExistingClient] = useState(false);

    const getTeam = async () => {
        try {
            const response = await server.get('/projects/team', {
                params: {
                    companyId: currentCompany._id,
                    project_id: currentProject._id,
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data) setTeam(response.data);

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        if (currentCompany._id && currentProject._id) getTeam();
    }, [currentProject, currentCompany]);

    useEffect(() => {
        dispatch(loadCurrentProject(projectId));
    }, [projectId]);

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">

                    <ProjectNavigationBar />

                    <Container fluid className="py-3">

                        <div className='d-flex align-items-center justify-content-end'>
                            <div>
                                <Button variant="primary" className="ms-2" onClick={() => setShowAddExistingClient(true)}>Add Existing client</Button>
                                <Button variant="primary" className="ms-2" onClick={() => setShowInviteClient(true)}>Invite client</Button>
                                <Button variant="primary" className="ms-2" onClick={() => setShowCreateClient(true)}>Create client</Button>
                            </div>
                        </div>

                        <Row className="py-4">
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <h4 className="mb-0">Assigned team</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={12}>
                                                <Table bordered striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Phone Number</th>
                                                            <th>Project Owner</th>
                                                            <th>Role</th>
                                                            <th>Last Active</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {team.length > 0 && team.map(user => {
                                                            return (
                                                                <UserItem user={user} />
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>

                                                <p className='text-grey text-sm'>Note: Project owner can't be deleted, if you want to delete the owner change the owner first then delete.</p>

                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Container>

                </div>
            </main>

            <CreateClient showModal={showCreateClient} setShowModal={setShowCreateClient} />
            <InviteClient showModal={showInviteClient} setShowModal={setShowInviteClient} />
            <AddExistingClient showModal={showAddExistingClient} setShowModal={setShowAddExistingClient} />
        </div>
    );
};

export default ProjectsTeamPage;