import server from '../apis/server';
import history from '../history'; // to be able to make programatic navs

//Action to sign up the user by email
export const emailSignup = (data) => async (dispatch) => {
    const response = await server.post('/users/emailsignup', data);
};

//Action to sign up the user by email
export const emailLogin = (data) => async (dispatch) => {
    try {
        const response = await server.get(`/users/signin?email=${data.email}&password=${data.password}`);
        dispatch({
            type: 'EMAIL_LOGIN',
            payload: response.data
        });

        return response.data;

    } catch (error) {
        console.log(error.response);
        if (error.response.data === 'Please activate your account') return { error: 'Please activate your account' };
        if (error.response?.status === 429) return { error: 'Too Many Requests, try again in a few minutes' };
        return { error: 'Wrong email or password' };
    }
};

export const createdUserLogin = (data) => (dispatch) => {
    dispatch({
        type: 'EMAIL_LOGIN',
        payload: data
    });
};

//Action to Log out the user
export const logoutUser = (callback) => async (dispatch, getState) => {
    const token = getState().accessToken;

    //dispatching first to clear the user from redux store in case the tken is not valid
    dispatch({
        type: 'LOGOUT_USER',
    });

    await server.post('/users/logout', {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (callback) callback();
};

//activated user
export const activatedUser = (payload) => {
    return {
        type: 'EMAIL_LOGIN',
        payload
    };
};

//Action to add a favorite
export const addFavorite = (itemId, itemCategory) => async (dispatch, getState) => {
    let data = { itemId };
    if (itemCategory) data.itemCategory = itemCategory;

    const response = await server.post('/addfavorite', data,
        {
            headers: {
                Authorization: `Bearer ${getState().accessToken}`
            }
        });

    dispatch({
        type: 'ADD_FAVORITE',
        payload: response.data
    });
};

//Action to add a favorite
export const addPersonalTag = (label, color) => async (dispatch, getState) => {

    const response = await server.post('/users/personaltag', { label, color },
        {
            headers: {
                Authorization: `Bearer ${getState().accessToken}`
            }
        });

    dispatch({
        type: 'UPDATE_PROFILE',
        payload: {
            user: response.data
        }
    });

    if (response.data && response.data.personalTags) return response.data.personalTags;

};

//action to update user data
export const updateProfile = (user) => {
    return {
        type: 'UPDATE_PROFILE',
        payload: {
            user
        }
    };
};

//action to update the access token
export const directLogin = (user, token, callback) => (dispatch, getState) => {
    dispatch({
        type: 'UPDATE_PROFILE',
        payload: {
            user
        }
    });
    dispatch({
        type: 'UPDATE_TOKEN',
        payload: {
            token
        }
    });
    callback();
};