import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card, InputGroup, Collapse } from 'react-bootstrap';

//icons
import { Icon } from '@iconify/react';
import eyeOpen from '@iconify/icons-el/eye-open';
import eyeClose from '@iconify/icons-el/eye-close';
import lockIcon from '@iconify/icons-bx/lock';

const PasswordInput = ({ password, setPassword, setPasswordCriteria, passwordError }) => {

    //local state
    const [hasLowerChar, setHasLowerChar] = useState(false);
    const [hasUpperChar, setHasUpperChar] = useState(false);
    const [hasDigit, setHasDigit] = useState(false);
    const [hasSymbol, setHasSymbol] = useState(false);
    const [greaterThanMinimumLength, setGreaterThanMinimumLength] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);

    const [showPassword, setShowPassword] = useState();


    const onPasswordChange = (e) => {
        const pass = e.target.value;
        setPassword(pass);

        const lowerChar = /[a-z]/.test(pass);
        const upperChar = /[A-Z]/.test(pass);
        const digit = /[0-9]/.test(pass);
        const symbole = /[#?!@$%^&*-]/.test(pass);

        const isValid = lowerChar && upperChar && digit && symbole && pass.length > 7;

        setHasLowerChar(lowerChar);
        setHasUpperChar(upperChar);
        setHasDigit(digit);
        setHasSymbol(symbole);
        setGreaterThanMinimumLength(pass.length > 7);

        setIsValidPassword(isValid);

        setPasswordCriteria({
            hasLowerChar: lowerChar,
            hasUpperChar: upperChar,
            hasDigit: digit,
            hasSymbol: symbole,
            greaterThanMinimumLength: pass.length > 7,
            isValidPassword: isValid
        });
    };

    return (
        <div className="w-100">
            <Form.Group className="mb-3">
                <InputGroup className="mb-3">
                    <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon-eye-open"
                        value={password} onChange={onPasswordChange}
                        autoComplete="new-password"
                        isInvalid={(password && !isValidPassword) || passwordError}
                    />
                    {!showPassword && <InputGroup.Text id="basic-addon-eye-open" className="pointer" onClick={() => setShowPassword(true)}><Icon icon={eyeOpen} width="24" height="24" /></InputGroup.Text>}
                    {showPassword && <InputGroup.Text id="basic-addon-eye-open" className="pointer" onClick={() => setShowPassword(false)}><Icon icon={eyeClose} width="24" height="24" /></InputGroup.Text>}

                </InputGroup>
                <Collapse in={password ? true : false}>
                    <div id="example-collapse-text">
                        <p className={`text-sm m-0 p-0 ${greaterThanMinimumLength ? 'text-green' : 'text-grey'}`}>Greater that 8 characters {greaterThanMinimumLength && <Icon icon="akar-icons:check" width="18" />}</p>
                        <p className={`text-sm m-0 p-0 ${hasLowerChar ? 'text-green' : 'text-grey'}`}>At least one small letter {hasLowerChar && <Icon icon="akar-icons:check" width="18" />}</p>
                        <p className={`text-sm m-0 p-0 ${hasUpperChar ? 'text-green' : 'text-grey'}`}>At least one capital letter {hasUpperChar && <Icon icon="akar-icons:check" width="18" />}</p>
                        <p className={`text-sm m-0 p-0 ${hasDigit ? 'text-green' : 'text-grey'}`}>At least one digit {hasDigit && <Icon icon="akar-icons:check" width="18" />}</p>
                        <p className={`text-sm m-0 p-0 ${hasSymbol ? 'text-green' : 'text-grey'}`}>Contains a symbol {hasSymbol && <Icon icon="akar-icons:check" width="18" />}</p>
                    </div>
                </Collapse>

            </Form.Group>
        </div>
    );
};

export default PasswordInput;