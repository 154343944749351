import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, DropdownButton, Navbar, Nav, Button, Form, Spinner, Pagination, Tabs, Tab } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import server from '../../../apis/server';
import { CSVLink } from "react-csv";
import moment from 'moment';

//actions
import { getProjectUnreadNotificationsCount } from '../../../redux_actions/projectNotificationsActions';

//icons
import { Icon } from '@iconify/react';
import baselineBuild from '@iconify/icons-ic/baseline-build';
import peopleFill from '@iconify/icons-bi/people-fill';
import roundGroupWork from '@iconify/icons-ic/round-group-work';
import flagFill from '@iconify/icons-bi/flag-fill';
import settingsFilled from '@iconify/icons-ci/settings-filled';
import filterIcon from '@iconify/icons-bi/filter';
import searchIcon from '@iconify/icons-bi/search';
import angleDown from '@iconify/icons-la/angle-down';

//Modals
import CreateProject from './modals/CreateProject';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import ProjectsTable from './ProjectsTable';
import useDebounce from '../../utils/useDebounce';

let active = 1;
let items = [];
for (let number = 1; number <= 1; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectsPage = () => {
    const navigate = useNavigate();
    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const dispatch = useDispatch();

    //local state
    const [showCreateProject, setShowCreateProject] = useState(false);
    const [projects, setProjects] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState('active')

    //Searching and sorting state
    const [searchInput, setSearchInput] = useState('');
    const [sortBy, setSortBy] = useState('Project Name');
    const [sortOrder, setSortOrder] = useState(1);

    const debouncedSearch = useDebounce(searchInput, 500);

    //csv ref
    const csvLink = useRef();

    const getProjects = async () => {
        try {
            setLoading(true);
            const response = await server.get('/projects', {
                params: { search: debouncedSearch, sortBy, sortOrder },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            let filterTableData;
            if (key === 'completed') {
                filterTableData = response.data.filter(data => data.progress_statuses?.permit_dropped_off?.state === true && data.progress_statuses?.corrections_issued?.state === false )
            } else {
                filterTableData = response.data.filter(data => data.progress_statuses?.permit_dropped_off?.state !== true ||  data.progress_statuses?.corrections_issued?.state !== false)
            }
            setProjects(filterTableData);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        getProjects();
    }, [debouncedSearch, sortBy, sortOrder, key]);

    useEffect(() => {
        dispatch({
            type: 'SET_PROJECT_UNREAD_NOTIFICATION',
            payload: 0
        });
        dispatch({
            type: 'SET_CURRENT_PROJECT',
            payload: {}
        });
    }, []);

    const getCSVData = () => {
        if (!projects || projects.length === 0) return [];
        const data = projects.map(project => {
            const {
                name,
                companyName,
                companyColor,
                date_created,
                location_city,
                location_state,
                location_zip,
                project_status,
                ownerContactName,
                latestStatus,
                latestStatusDate
            } = project;

            return {
                'Company Name': companyName,
                'Project name': name,
                City: location_city,
                State: location_state,
                Zip: location_zip,
                "Date Ordered": moment(date_created).format('ll'),
                "Date Recievied": moment(date_created).format('ll'),
                "Date Submitted to the City": '',
                "Most resent status change": moment(latestStatusDate).format('lll'),
                "Status": latestStatus,
                "Project Owner": ownerContactName,
            };
        });
        return data;
    };

    const onSearchClear = () => {
        setLoading(true);
        setSearchInput('');
    };

    const onSortByChange = (sort) => {
        setSortBy(sort);
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">
                    <div className="top-bar">
                        <h2>Projects </h2>

                        <div className="top-bar--right">
                            <Button variant="outline-primary" type="submit" className="ms-2" onClick={() => csvLink.current.link.click()}>export <span className="text-uppercase">CSV</span></Button>
                            <Button variant="primary" type="submit" className="ms-2" onClick={() => setShowCreateProject(true)}>Add Project</Button>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center px-3 py-2">
                        <div className="input-search" controlid="exampleForm.ControlInput1">
                            <span className="input-search--icon">
                                {!searchInput && !loading && < Icon className="pointer" icon={searchIcon} width="24" height="24" />}
                                {searchInput && !loading && <Icon className="pointer" icon="ic:round-clear" width="24" height="24" onClick={onSearchClear} />}
                                {loading && <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />}

                            </span>
                            <Form.Control type="text" placeholder="Search" value={searchInput} onChange={e => setSearchInput(e.target.value.trimStart())} />
                        </div>
                        <div className='w-25 justify-content-center'>
                            <Tabs className='custom justify-content-center' activeKey={key} onSelect={(k) => setKey(k)}>
                                <Tab eventKey='active' title='Active' />
                                <Tab eventKey='completed' title='Completed' />
                            </Tabs>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-basic" className="primary">
                                    <Button variant="outline-secondary" size="sm">Sort By - {sortBy} <Icon icon={filterIcon} width="24" height="24" /></Button>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => onSortByChange('Project Name')}>Project name</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('Company Name')}>Company name</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('City')}>City</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('State')}>State</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onSortByChange('Zip')}>Zip</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div>
                                {sortOrder === 1 && <Icon icon="cil:sort-ascending" className="pointer" onClick={() => setSortOrder(-1)} />}
                                {sortOrder === -1 && <Icon icon="cil:sort-descending" className="pointer" onClick={() => setSortOrder(1)} />}
                            </div>
                        </div>
                    </div>

                    <Container fluid className="px-3 py-2">

                        <ProjectsTable setShowCreateProject={setShowCreateProject} projects={projects} searchInput={searchInput} loading={loading} />

                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <Pagination size="sm">{items}</Pagination>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </main>

            <CreateProject showModal={showCreateProject} setShowModal={setShowCreateProject} getProjects={getProjects} />
            {projects?.length > 0 && <CSVLink ref={csvLink} className="text-highliten d-none" data={getCSVData()} filename={`projects-list.csv`} style={{ textDecoration: 'none', color: 'white' }}></CSVLink>}
        </div>
    );
};

export default ProjectsPage;