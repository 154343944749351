import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Col } from 'react-bootstrap';
import { isEmail } from 'validator';
import server from '../../../../apis/server';

// redux actions
import { setCurrentCompany } from '../../../../redux_actions/currentCompanyActions';

//comp
import EditUserForm from '../../../common/Forms/EditUserForm';


const EditClient = ({ showModal, setShowModal, userData }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [responseError, setResponseError] = useState('');

    const onModalHide = () => {
        setShowModal(false);
        setResponseError('');
    };

    const onSaveClick = async (updatedData) => {
        setSubmitting(true);
        try {
            const response = await server.post('/client/edit', { updatedData, oldUserData: userData, companyId: currentCompany._id }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data.updatedCompany?._id) dispatch(setCurrentCompany(response.data.updatedCompany));
            setSubmitting(false);
            onModalHide();

        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });

            if (error.response?.status === 409) {
                setResponseError('There is already an account associated with this email');
            } else {
                setResponseError('Something went wrong please try again');
            }
        }
    };

    const getUserRole = () => {
        if (currentCompany?.client_admins?.includes(userData._id)) return 'admin';
        if (currentCompany?.client_supper_admins?.includes(userData._id)) return 'supper_admin';
        if (currentCompany?.client_users?.includes(userData._id)) return 'user';
    };


    return (
        <Modal show={showModal} onHide={onModalHide} centered>
            <Modal.Header closeButton className="ps-md-5">
                <h2>Edit Client</h2>
            </Modal.Header>

            <Modal.Body className="px-md-5">
                <EditUserForm
                    onSave={onSaveClick}
                    onCancel={onModalHide}
                    userData={userData}
                    showUserRole
                    currentRole={getUserRole()}
                    // companyOptions={[]}
                    // projectOptions={[]}
                    submitting={submitting} setSubmitting={setSubmitting}
                />

                {responseError && <Col md={12}>
                    <div className="mb-3">
                        <p className="text-red mt-3">- {responseError}</p>
                    </div>
                </Col>}
            </Modal.Body>


        </Modal >
    );
};

export default EditClient;