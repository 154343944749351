import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import '../../../css/ui-components/_datepicker.scss';

//icons
import { Icon } from '@iconify/react';
import checkCircleFill from '@iconify/icons-bi/check-circle-fill';
import infoCircle from '@iconify/icons-bi/info-circle';
import circleFill from '@iconify/icons-bi/circle-fill';

//Modals
import EditProjectStatuses from '../modals/EditProjectStatuses';
import server from '../../../../apis/server';
import { setCurrentProject } from '../../../../redux_actions/currentProjectActions';

const ProjectStatus = () => {
    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    const { name, companyName, companyColor, date_created, location_city, location_state, location_zip, progress_statuses, ownerContactName, approval_status } = currentProject;
    //local state
    const [showEditProjectStatuses, setShowEditProjectStatuses] = useState(false);
    const [datePicker, setDatePicker] = useState('');

    const calculateProgress = () => {
        let itemsCount = 1;
        if (approval_status?.state === 'accepted') itemsCount++;
        if (progress_statuses?.submitted?.included === true && progress_statuses?.submitted?.state === true) itemsCount++;
        if (progress_statuses?.planning?.included === true && progress_statuses?.planning?.state === true) itemsCount++;
        if (progress_statuses?.building_and_safety?.included === true && progress_statuses?.building_and_safety?.state === true) itemsCount++;
        if (progress_statuses?.public_works?.included === true && progress_statuses?.public_works?.state === true) itemsCount++;
        if (progress_statuses?.permit_approved_pending_issuance?.included === true && progress_statuses?.permit_approved_pending_issuance?.state === true) itemsCount++;
        if (progress_statuses?.permit_issued_secured?.included === true && progress_statuses?.permit_issued_secured?.state === true) itemsCount++;
        if (progress_statuses?.permit_dropped_off?.included === true && progress_statuses?.permit_dropped_off?.state === true) itemsCount++;

        let totalCount = 2;
        if (progress_statuses?.submitted?.included === true) totalCount++;
        if (progress_statuses?.planning?.included === true) totalCount++;
        if (progress_statuses?.building_and_safety?.included === true) totalCount++;
        if (progress_statuses?.public_works?.included === true) totalCount++;
        if (progress_statuses?.permit_approved_pending_issuance?.included === true) totalCount++;
        if (progress_statuses?.permit_issued_secured?.included === true) totalCount++;
        if (progress_statuses?.permit_dropped_off?.included === true) totalCount++;
        return (itemsCount / totalCount) * 100;
    };

    const onStatusChange = async (title, state, changed_at) => {
        if (progress_statuses?.corrections_issued?.state && title !== 'corrections_issued') return;
        try {
            const response = await server.post('/project/updatestatus', { projectId: currentProject._id, title, state, oldStatuses: progress_statuses, changed_at }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?._id) {
                dispatch(setCurrentProject(response.data));
            }

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    const handleVariant = () => {
        if (progress_statuses?.corrections_issued?.state === true) return 'danger';
        return 'success';
    };

    useEffect(() => {
        handleVariant();
    }, progress_statuses);

    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <h4 className="mb-0">Project Status</h4>
                    </Card.Header>
                    <Card.Body>

                        <ProgressBar variant={handleVariant()} now={calculateProgress()} />

                        <div className="status-card-list">
                            <div className="status-card">
                                <div className="status-card--icon">
                                    <Icon icon={checkCircleFill} width="24" height="24" color="#D1337F" />
                                </div>

                                <p className="status-card--info">Received</p>
                                <span className="status-card--date">{moment(date_created).format('lll')}</span>

                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>

                            </div>

                            <div className="status-card">
                                <div className="status-card--icon">
                                    {approval_status?.state === 'accepted'
                                        ? <Icon icon={checkCircleFill} width="24" height="24" color="#D1337F" />
                                        : <Icon icon={circleFill} width="24" height="24" color="#D1337F" />
                                    }

                                </div>

                                <p className="status-card--info">Project Accepted</p>
                                <span className="status-card--date">{approval_status?.state === 'accepted' ? moment(approval_status.changed_at).format('lll') : null}</span>

                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>

                            </div>

                            {currentProject?.progress_statuses?.submitted?.included && <div className="status-card">
                                <div className="status-card--icon">
                                    {progress_statuses?.submitted?.state === true
                                        ? <Icon className='pointer' icon={checkCircleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('submitted', false, new Date())} />
                                        : <Icon className='pointer' icon={circleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('submitted', true, new Date())} />
                                    }
                                </div>

                                <p className="status-card--info">Submitted</p>
                                {progress_statuses?.submitted?.state &&
                                    <div>
                                        <div className='align-items-center'>
                                            <span className="status-card--date">{moment(progress_statuses.submitted?.changed_at).format('lll')}</span>
                                            <Button className='p-0 display-6' variant='link' style={{ color: '#019EB2', fontWeight: 400 }} onClick={() => { setDatePicker('submitted'); }}>Edit</Button>
                                        </div>
                                        <div className='customDatePicker'>
                                            <DatePicker
                                                selected={new Date(progress_statuses?.submitted?.changed_at)}
                                                onClickOutside={() => { setDatePicker(''); }}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                open={datePicker === 'submitted'}
                                                onChange={date => onStatusChange('submitted', progress_statuses?.submitted?.state, date)}
                                            />
                                        </div>
                                    </div>}
                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>
                            </div>}

                            {currentProject?.progress_statuses?.planning?.included && <div className="status-card">
                                <div className="status-card--icon">
                                    {progress_statuses?.planning?.state === true
                                        ? <Icon className='pointer' icon={checkCircleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('planning', false, new Date())} />
                                        : <Icon className='pointer' icon={circleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('planning', true, new Date())} />
                                    }
                                </div>

                                <p className="status-card--info">Planning Approved</p>
                                {progress_statuses?.planning?.state &&
                                    <div>
                                        <div className='align-items-center'>
                                            <span className="status-card--date">{moment(progress_statuses.planning?.changed_at).format('lll')}</span>
                                            <Button className='p-0 display-6' variant='link' style={{ color: '#019EB2', fontWeight: 400 }} onClick={() => { setDatePicker('planning'); }}>Edit</Button>
                                        </div>
                                        <div className='customDatePicker'>
                                            <DatePicker
                                                selected={new Date(progress_statuses?.planning?.changed_at)}
                                                onClickOutside={() => { setDatePicker(''); }}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                open={datePicker === 'planning'}
                                                onChange={date => onStatusChange('planning', progress_statuses?.planning?.state, date)}
                                            />
                                        </div>
                                    </div>}
                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>
                            </div>}

                            {currentProject?.progress_statuses?.building_and_safety?.included && <div className="status-card">
                                <div className="status-card--icon">
                                    {progress_statuses?.building_and_safety?.state === true
                                        ? <Icon className='pointer' icon={checkCircleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('building_and_safety', false, new Date())} />
                                        : <Icon className='pointer' icon={circleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('building_and_safety', true, new Date())} />
                                    }
                                </div>

                                <p className="status-card--info">Building and Safety Approved</p>
                                {progress_statuses?.building_and_safety?.state &&
                                    <div>
                                        <div className='align-items-center'>
                                            <span className="status-card--date">{moment(progress_statuses.building_and_safety?.changed_at).format('lll')}</span>
                                            <Button className='p-0 display-6' variant='link' style={{ color: '#019EB2', fontWeight: 400 }} onClick={() => { setDatePicker('building_and_safety'); }}>Edit</Button>
                                        </div>
                                        <div className='customDatePicker'>
                                            <DatePicker
                                                selected={new Date(progress_statuses?.building_and_safety?.changed_at)}
                                                onClickOutside={() => { setDatePicker(''); }}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                open={datePicker === 'building_and_safety'}
                                                onChange={date => onStatusChange('building_and_safety', progress_statuses?.building_and_safety?.state, date)}
                                            />
                                        </div>
                                    </div>}
                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>
                            </div>}

                            {currentProject?.progress_statuses?.public_works?.included && <div className="status-card">
                                <div className="status-card--icon">
                                    {progress_statuses?.public_works?.state === true
                                        ? <Icon className='pointer' icon={checkCircleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('public_works', false, new Date())} />
                                        : <Icon className='pointer' icon={circleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('public_works', true, new Date())} />
                                    }
                                </div>

                                <p className="status-card--info">Public Works</p>
                                {progress_statuses?.public_works?.state &&
                                    <div>
                                        <div className='align-items-center'>
                                            <span className="status-card--date">{moment(progress_statuses.public_works?.changed_at).format('lll')}</span>
                                            <Button className='p-0 display-6' variant='link' style={{ color: '#019EB2', fontWeight: 400 }} onClick={() => { setDatePicker('public_works'); }}>Edit</Button>
                                        </div>
                                        <div className='customDatePicker'>
                                            <DatePicker
                                                selected={new Date(progress_statuses?.public_works?.changed_at)}
                                                onClickOutside={() => { setDatePicker(''); }}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                open={datePicker === 'public_works'}
                                                onChange={date => onStatusChange('public_works', progress_statuses?.public_works?.state, date)}
                                            />
                                        </div>
                                    </div>}
                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>
                            </div>}

                            {currentProject?.progress_statuses?.permit_approved_pending_issuance?.included && <div className="status-card">
                                <div className="status-card--icon">
                                    {progress_statuses?.permit_approved_pending_issuance?.state === true
                                        ? <Icon className='pointer' icon={checkCircleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('permit_approved_pending_issuance', false, new Date())} />
                                        : <Icon className='pointer' icon={circleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('permit_approved_pending_issuance', true, new Date())} />
                                    }
                                </div>

                                <p className="status-card--info">Permit Approved / Pending Issuance</p>
                                {progress_statuses?.permit_approved_pending_issuance?.state &&
                                    <div>
                                        <div className='align-items-center'>
                                            <span className="status-card--date">{moment(progress_statuses.permit_approved_pending_issuance?.changed_at).format('lll')}</span>
                                            <Button className='p-0 display-6' variant='link' style={{ color: '#019EB2', fontWeight: 400 }} onClick={() => { setDatePicker('permit_approved_pending_issuance'); }}>Edit</Button>
                                        </div>
                                        <div className='customDatePicker'>
                                            <DatePicker
                                                selected={new Date(progress_statuses?.permit_approved_pending_issuance?.changed_at)}
                                                onClickOutside={() => { setDatePicker(''); }}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                open={datePicker === 'permit_approved_pending_issuance'}
                                                onChange={date => onStatusChange('permit_approved_pending_issuance', progress_statuses?.permit_approved_pending_issuance?.state, date)}
                                            />
                                        </div>
                                    </div>}
                                <div className="status-card--icon pt-2">
                                    <Icon className='pointer' icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>
                            </div>}

                            {currentProject?.progress_statuses?.permit_issued_secured?.included && <div className="status-card">
                                <div className="status-card--icon">
                                    {progress_statuses?.permit_issued_secured?.state === true
                                        ? <Icon className='pointer' icon={checkCircleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('permit_issued_secured', false, new Date())} />
                                        : <Icon className='pointer' icon={circleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('permit_issued_secured', true, new Date())} />
                                    }
                                </div>

                                <p className="status-card--info">Permit Issued / Secured</p>
                                {progress_statuses?.permit_issued_secured?.state &&
                                    <div>
                                        <div className='align-items-center'>
                                            <span className="status-card--date">{moment(progress_statuses.permit_issued_secured?.changed_at).format('lll')}</span>
                                            <Button className='p-0 display-6' variant='link' style={{ color: '#019EB2', fontWeight: 400 }} onClick={() => { setDatePicker('permit_issued_secured'); }}>Edit</Button>
                                        </div>
                                        <div className='customDatePicker'>
                                            <DatePicker
                                                selected={new Date(progress_statuses?.permit_issued_secured?.changed_at)}
                                                onClickOutside={() => { setDatePicker(''); }}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                open={datePicker === 'permit_issued_secured'}
                                                onChange={date => onStatusChange('permit_issued_secured', progress_statuses?.permit_issued_secured?.state, date)}
                                            />
                                        </div>
                                    </div>}
                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>
                            </div>}

                            {currentProject?.progress_statuses?.permit_dropped_off?.included && <div className="status-card">
                                <div className="status-card--icon">
                                    {progress_statuses?.permit_dropped_off?.state === true
                                        ? <Icon className='pointer' icon={checkCircleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('permit_dropped_off', false, new Date())} />
                                        : <Icon className='pointer' icon={circleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('permit_dropped_off', true, new Date())} />
                                    }
                                </div>

                                <p className="status-card--info">Permit Dropped Off</p>
                                {progress_statuses?.permit_dropped_off?.state &&
                                    <div>
                                        <div className='align-items-center'>
                                            <span className="status-card--date">{moment(progress_statuses.permit_dropped_off?.changed_at).format('lll')}</span>
                                            <Button className='p-0 display-6' variant='link' style={{ color: '#019EB2', fontWeight: 400 }} onClick={() => { setDatePicker('permit_dropped_off'); }}>Edit</Button>
                                        </div>
                                        <div className='customDatePicker'>
                                            <DatePicker
                                                selected={new Date(progress_statuses?.permit_dropped_off?.changed_at)}
                                                onClickOutside={() => { setDatePicker(''); }}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                open={datePicker === 'permit_dropped_off'}
                                                onChange={date => onStatusChange('permit_dropped_off', progress_statuses?.permit_dropped_off?.state, date)}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>
                            </div>}

                            <div className="status-card">
                                <div className="status-card--icon">
                                    {progress_statuses?.corrections_issued?.state === true
                                        ? <Icon className='pointer' icon={checkCircleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('corrections_issued', false, new Date())} />
                                        : <Icon className='pointer' icon={circleFill} width="24" height="24" color="#D1337F" onClick={() => onStatusChange('corrections_issued', true, new Date())} />
                                    }
                                </div>

                                <p className="status-card--info">Corrections Issued</p>
                                {progress_statuses?.corrections_issued?.state &&
                                    <div>
                                        <div className='align-items-center'>
                                            <span className="status-card--date">{moment(progress_statuses?.corrections_issued?.changed_at).format('lll')}</span>
                                            <Button className='p-0 display-6' variant='link' style={{ color: '#019EB2', fontWeight: 400 }} onClick={() => { setDatePicker('corrections_issued'); }}>Edit</Button>
                                        </div>
                                        <div className='customDatePicker'>
                                            <DatePicker
                                                selected={new Date(progress_statuses?.corrections_issued?.changed_at)}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                onClickOutside={() => { setDatePicker(''); }}
                                                open={datePicker === 'corrections_issued'}
                                                onChange={date => onStatusChange('corrections_issued', progress_statuses?.corrections_issued?.state, date)}
                                            />
                                        </div>
                                    </div>}
                                <div className="status-card--icon pt-2">
                                    <Icon icon={infoCircle} width="16" height="16" inline={true} color="#7B7B7B" />
                                </div>
                            </div>

                        </div>

                        {isSystemAdmin() && <div className="d-flex align-items-center justify-content-end">
                            <Button variant="primary" type="button" size="sm" onClick={() => setShowEditProjectStatuses(true)}>Edit Project’s Statuses</Button>
                        </div>}
                    </Card.Body>
                </Card>
            </Col>
            {currentProject && <EditProjectStatuses showModal={showEditProjectStatuses} setShowModal={setShowEditProjectStatuses} />}

        </Row>
    );
};

export default ProjectStatus;