import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, InputGroup, Table } from 'react-bootstrap';
import { isEmail } from 'validator';
import server from '../../../../apis/server';
import { SketchPicker } from 'react-color';

//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


// redux actions
import { setCurrentCompany } from '../../../../redux_actions/currentCompanyActions';

//Comp
import PDFUpload from '../../../common/fileUpload/PDFUpload';

//icons
import { Icon } from '@iconify/react';
import twotoneAttachFile from '@iconify/icons-ic/twotone-attach-file';

//validation functions
import { validateSmallTextNoNumbers, validateSmallText, validateZipCode, validatePhoneNumber, validateSmallNumber } from '../../../utils/customValidations';

const CreateSystemAdminUser = ({ showModal, setShowModal, companies, setCompanies, company, setCompany }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const dispatch = useDispatch();

    //local
    const [name, setName] = useState('');
    const [locationCity, setLocationCity] = useState('');
    const [locationState, setLocationState] = useState('');
    const [locationZip, setLocationZip] = useState('');
    const [mailingAddressCity, setMailingAddressCity] = useState('');
    const [mailingAddressState, setMailingAddressState] = useState('');
    const [mailingAddressZip, setMailingAddressZip] = useState('');
    const [contractorLicenseNumber, setContractorLicenseNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [letterOfAuthorization, setLetterOfAuthorization] = useState({});
    const [email, setEmail] = useState('');
    const [workersCompensationCompanyName, setWorkersCompensationCompanyName] = useState('');
    const [workersCompensationPolicyNumber, setWorkersCompensationPolicyNumber] = useState('');
    const [workersCompensationExpirationDate, setWorkersCompensationExpirationDate] = useState('');
    const [color, setColor] = useState('');

    const [submitting, setSubmitting] = useState(false);

    const [errors, setErrors] = useState('');
    const [responseError, setResponseError] = useState('');

    //state for handling pdf upload
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState('');

    useEffect(() => {
        if (company?._id) {
            setName(company.name);
            setLocationCity(company.location_city);
            setLocationState(company.location_state);
            setLocationZip(company.location_zip);
            setMailingAddressCity(company.mailing_address_city);
            setMailingAddressState(company.mailing_address_state);
            setMailingAddressZip(company.mailing_address_zip);
            setContractorLicenseNumber(company.contractor_license_number);
            setPhoneNumber(company.phoneNumber);
            setLetterOfAuthorization(company.letterOfAuthorization);
            setEmail(company.email);
            setWorkersCompensationCompanyName(company.workers_compensation_company_name);
            setWorkersCompensationPolicyNumber(company.workers_compensation_policy_number);
            setWorkersCompensationExpirationDate(new Date(company.workers_compensation_expiration_date));
            setColor(company.color);
        }
    }, [company]);

    const onModalHide = () => {

        if (!company) {
            setName('');
            setLocationCity('');
            setLocationState('');
            setLocationZip('');
            setMailingAddressCity('');
            setMailingAddressState('');
            setMailingAddressZip('');
            setContractorLicenseNumber('');
            setPhoneNumber('');
            setLetterOfAuthorization({});
            setEmail('');
            setWorkersCompensationCompanyName('');
            setWorkersCompensationPolicyNumber('');
            setWorkersCompensationExpirationDate('');
            setColor(undefined);
        }

        setSubmitting(false);

        setErrors('');
        setResponseError('');

        setUploading(false);
        setUploadError('');

        setShowModal(false);
    };

    useEffect(() => {
        const newErrors = {};

        if (name && validateSmallText(name, "Company's name").error) newErrors.name = validateSmallText(name, "Company's name").error;
        if (locationCity && validateSmallTextNoNumbers(locationCity, 'the Company’s city').error) newErrors.locationCity = validateSmallTextNoNumbers(locationCity, 'the Company’s city').error;
        if (locationState && validateSmallTextNoNumbers(locationState, 'the Company’s state').error) newErrors.locationState = validateSmallTextNoNumbers(locationState, 'the Company’s state').error;
        if (locationZip && validateZipCode(locationZip, 'the Company’s zip').error) newErrors.locationZip = validateZipCode(locationZip, 'the Company’s zip').error;

        if (mailingAddressCity && validateSmallTextNoNumbers(mailingAddressCity, 'the Company’s mailing address city').error) newErrors.mailingAddressCity = validateSmallTextNoNumbers(mailingAddressCity, 'the Company’s mailing address city').error;
        if (mailingAddressState && validateSmallTextNoNumbers(mailingAddressState, 'the Company’s mailing address state').error) newErrors.mailingAddressState = validateSmallTextNoNumbers(mailingAddressState, 'the Company’s mailing address state').error;
        if (mailingAddressZip && validateZipCode(mailingAddressZip, 'the Company’s mailing address zip').error) newErrors.mailingAddressZip = validateZipCode(mailingAddressZip, 'the Company’s mailing address zip').error;

        if (contractorLicenseNumber && validateSmallNumber(contractorLicenseNumber, 'the Company’s Contractor License Number').error) newErrors.contractorLicenseNumber = validateSmallNumber(contractorLicenseNumber, 'the Company’s Contractor License Number').error;

        if (phoneNumber && validatePhoneNumber(phoneNumber, 'the Company’s phone number').error) newErrors.phoneNumber = validatePhoneNumber(phoneNumber, 'the Company’s phone number').error;

        if (email && !isEmail(email)) newErrors.email = 'Email is not valid';

        if (workersCompensationCompanyName && validateSmallTextNoNumbers(workersCompensationCompanyName, 'the Company’s Workers Compensation Company name').error) newErrors.workersCompensationCompanyName = validateSmallTextNoNumbers(workersCompensationCompanyName, 'the Company’s Workers Compensation Company name').error;
        if (workersCompensationPolicyNumber && validateSmallText(workersCompensationPolicyNumber, 'the Company’s Workers Compensation policy number').error) newErrors.workersCompensationPolicyNumber = validateSmallText(workersCompensationPolicyNumber, 'the Company’s Workers Compensation policy number').error;

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});
    }, [name, locationCity, locationState, locationZip, mailingAddressCity, mailingAddressState, mailingAddressZip, contractorLicenseNumber, phoneNumber, email, workersCompensationCompanyName, workersCompensationPolicyNumber]);


    const onCreateClick = async (createUserFormData) => {

        const newErrors = {};

        //validating inputs

        if (validateSmallText(name, "Company's name").error) newErrors.name = validateSmallText(name, "Company's name").error;
        if (validateSmallTextNoNumbers(locationCity, 'the Company’s city').error) newErrors.locationCity = validateSmallTextNoNumbers(locationCity, 'the Company’s city').error;
        if (validateSmallTextNoNumbers(locationState, 'the Company’s state').error) newErrors.locationState = validateSmallTextNoNumbers(locationState, 'the Company’s state').error;
        if (validateZipCode(locationZip, 'the Company’s zip').error) newErrors.locationZip = validateZipCode(locationZip, 'the Company’s zip').error;

        if (validateSmallTextNoNumbers(mailingAddressCity, 'the Company’s mailing address city').error) newErrors.mailingAddressCity = validateSmallTextNoNumbers(mailingAddressCity, 'the Company’s mailing address city').error;
        if (validateSmallTextNoNumbers(mailingAddressState, 'the Company’s mailing address state').error) newErrors.mailingAddressState = validateSmallTextNoNumbers(mailingAddressState, 'the Company’s mailing address state').error;
        if (validateZipCode(mailingAddressZip, 'the Company’s mailing address zip').error) newErrors.mailingAddressZip = validateZipCode(mailingAddressZip, 'the Company’s mailing address zip').error;

        if (validateSmallNumber(contractorLicenseNumber, 'the Company’s Contractor License Number').error) newErrors.contractorLicenseNumber = validateSmallNumber(contractorLicenseNumber, 'the Company’s Contractor License Number').error;

        if (validatePhoneNumber(phoneNumber, 'the Company’s phone number').error) newErrors.phoneNumber = validatePhoneNumber(phoneNumber, 'the Company’s phone number').error;

        if (!email) newErrors.email = 'Please enter a Company email address';
        else if (!isEmail(email)) newErrors.email = 'Email is not valid';

        if (validateSmallTextNoNumbers(workersCompensationCompanyName, 'the Company’s Workers Compensation Company name').error) newErrors.workersCompensationCompanyName = validateSmallTextNoNumbers(workersCompensationCompanyName, 'the Company’s Workers Compensation Company name').error;
        if (validateSmallText(workersCompensationPolicyNumber, 'the Company’s Workers Compensation policy number').error) newErrors.workersCompensationPolicyNumber = validateSmallText(workersCompensationPolicyNumber, 'the Company’s Workers Compensation policy number').error;
        if (!workersCompensationExpirationDate) newErrors.workersCompensationExpirationDate = 'Please select your Company’s Workers Compensation expiration date';

        if (!color) newErrors.color = 'Please choose color';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        try {
            setSubmitting(true);

            const companyData = {
                name: name.trim(),
                location_city: locationCity.trim(),
                location_state: locationState.trim(),
                location_zip: locationZip.trim(),
                mailing_address_city: mailingAddressCity.trim(),
                mailing_address_state: mailingAddressState.trim(),
                mailing_address_zip: mailingAddressZip.trim(),
                contractor_license_number: contractorLicenseNumber.trim(),
                phoneNumber: phoneNumber.trim(),
                letterOfAuthorization: letterOfAuthorization,
                email: email.trim(),
                workers_compensation_company_name: workersCompensationCompanyName.trim(),
                workers_compensation_policy_number: workersCompensationPolicyNumber.trim(),
                workers_compensation_expiration_date: workersCompensationExpirationDate,
                color: color.hex
            };

            let response;

            if (company) {
                companyData._id = company._id;
                companyData.companyId = company._id;

                response = await server.post('/company/edit', companyData, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });

            } else {
                response = await server.post('/company/create', companyData, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });
            }

            if (response?.data?._id) {

                //updating companies list (UI)
                if (companies && setCompanies) setCompanies([...companies, response.data]);
                if (company) {
                    dispatch(setCurrentCompany(response.data)); // updating the currentCompany in the redux store
                }
                //closing the Modal
                onModalHide();
            }



        } catch (error) {
            console.log({ error: error.message });
            setResponseError('Something went wrong please try again');
        }
    };

    const onUploadClick = async (data) => {
        setLetterOfAuthorization(data);
    };

    return (
        <Modal show={showModal} onHide={onModalHide} size="xl" centered>
            <Modal.Header closeButton className="ps-md-4">
                <h2>{company ? 'Edit' : 'Create'} Company</h2>
            </Modal.Header>

            <Modal.Body className="px-md-4">
                <Form>
                    <Row>
                        <Col lg={4}>
                            <fieldset>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Company Name</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Company Name"
                                                    value={name} onChange={e => setName(e.target.value.trimStart())}
                                                    isInvalid={errors.name ? true : false}
                                                />
                                            </InputGroup>
                                            {errors.name && <p className='text-red m-0 p-0'>{errors.name}</p>}
                                        </div>
                                    </Col>
                                </Row>

                                <label className="simple-title pb-3">Location</label>
                                <Row className="mb-3 pb-3">
                                    <Col md={4}>
                                        <div className="position-relative">
                                            <Form.Label className="custom-form-label">City</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="city"
                                                    value={locationCity} onChange={e => setLocationCity(e.target.value.trimStart())}
                                                    isInvalid={errors.locationCity ? true : false}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="position-relative">
                                            <Form.Label className="custom-form-label">State</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="state"
                                                    value={locationState} onChange={e => setLocationState(e.target.value.trimStart())}
                                                    isInvalid={errors.locationState ? true : false}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="position-relative">
                                            <Form.Label className="custom-form-label">zip</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="zip"
                                                    value={locationZip} onChange={e => setLocationZip(e.target.value.trimStart())}
                                                    isInvalid={errors.locationZip ? true : false}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        {errors.locationCity && <p className='text-red m-0 p-0'>- {errors.locationCity}</p>}
                                        {errors.locationState && <p className='text-red m-0 p-0'>- {errors.locationState}</p>}
                                        {errors.locationZip && <p className='text-red m-0 p-0'>- {errors.locationZip}</p>}
                                    </Col>
                                </Row>

                                <label className="simple-title pb-3">Mailing Address</label>
                                <Row className="mb-3 pb-3">
                                    <Col md={4}>
                                        <div className="position-relative">
                                            <Form.Label className="custom-form-label">City</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="city"
                                                    value={mailingAddressCity} onChange={e => setMailingAddressCity(e.target.value.trimStart())}
                                                    isInvalid={errors.mailingAddressCity ? true : false}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="position-relative">
                                            <Form.Label className="custom-form-label">State</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="state"
                                                    value={mailingAddressState} onChange={e => setMailingAddressState(e.target.value.trimStart())}
                                                    isInvalid={errors.mailingAddressState ? true : false}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="position-relative">
                                            <Form.Label className="custom-form-label">zip</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="zip"
                                                    value={mailingAddressZip} onChange={e => setMailingAddressZip(e.target.value.trimStart())}
                                                    isInvalid={errors.mailingAddressZip ? true : false}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        {errors.mailingAddressCity && <p className='text-red m-0 p-0'>{errors.mailingAddressCity}</p>}
                                        {errors.mailingAddressState && <p className='text-red m-0 p-0'>{errors.mailingAddressState}</p>}
                                        {errors.mailingAddressZip && <p className='text-red m-0 p-0'>{errors.mailingAddressZip}</p>}
                                    </Col>

                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Contractor License Number</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Contractor License Number"
                                                    value={contractorLicenseNumber} onChange={e => setContractorLicenseNumber(e.target.value.trimStart())}
                                                    isInvalid={errors.contractorLicenseNumber ? true : false}
                                                />
                                            </InputGroup>
                                            {errors.contractorLicenseNumber && <p className='text-red m-0 p-0'>{errors.contractorLicenseNumber}</p>}
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>

                            <fieldset>
                                <label className="simple-title pb-4">Contact Information</label>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Phone Number</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    value={phoneNumber} onChange={e => setPhoneNumber(e.target.value.trimStart())}
                                                    isInvalid={errors.phoneNumber ? true : false}
                                                />
                                            </InputGroup>
                                            {errors.phoneNumber && <p className="text-red m-0 p-0">{errors.phoneNumber}</p>}
                                        </div>
                                    </Col>

                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Email Address</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email Address"
                                                    value={email} onChange={e => setEmail(e.target.value.trimStart())}
                                                    isInvalid={errors.email ? true : false}
                                                />
                                            </InputGroup>
                                            {errors.email && <p className='text-red m-0 p-0'>{errors.email}</p>}
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>

                        </Col>

                        <Col lg={5}>
                            <fieldset className="pb-3">
                                <label className="simple-title pb-4 line-height-1">Letter of Authorization</label>
                                <Row>
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    placeholder={letterOfAuthorization?.fileName ? letterOfAuthorization.fileName : "please upload a pdf file"}
                                                    aria-label="File name"
                                                    aria-describedby="basic-addon-twotoneAttachFile"
                                                    disabled
                                                />
                                                <InputGroup.Text id="basic-addon-twotoneAttachFile">
                                                    <PDFUpload
                                                        // trigger={<Icon className="pointer" icon={twotoneAttachFile} width="24" height="24" />}
                                                        trigger={uploading ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} /> : <Icon className="pointer" icon={twotoneAttachFile} width="24" height="24" />}
                                                        onPDFUploadClick={onUploadClick}
                                                        setUploading={setUploading}
                                                        setUploadError={setUploadError}
                                                        uploadedFile={letterOfAuthorization}
                                                    />

                                                </InputGroup.Text>

                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>

                            <fieldset>
                                <label className="simple-title pb-4">Workers Compensation Information</label>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Workers Compensation Company Name</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Workers Compensation Company Name"
                                                    value={workersCompensationCompanyName} onChange={e => setWorkersCompensationCompanyName(e.target.value.trimStart())}
                                                    isInvalid={errors.workersCompensationCompanyName ? true : false}
                                                />
                                            </InputGroup>
                                            {errors.workersCompensationCompanyName && <p className='text-red m-0 p-0'>{errors.workersCompensationCompanyName}</p>}
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Workers Compensation Policy Number</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Workers Compensation Policy Number"
                                                    value={workersCompensationPolicyNumber} onChange={e => setWorkersCompensationPolicyNumber(e.target.value.trimStart())}
                                                    isInvalid={errors.workersCompensationPolicyNumber ? true : false}
                                                />
                                            </InputGroup>
                                            {errors.workersCompensationPolicyNumber && <p className='text-red m-0 p-0'>{errors.workersCompensationPolicyNumber}</p>}
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <div className="pb-3 position-relative">
                                            <Form.Label className="custom-form-label">Workers Compensation Expiration Date</Form.Label>
                                            <DatePicker
                                                selected={workersCompensationExpirationDate}
                                                onChange={date => setWorkersCompensationExpirationDate(date)}
                                                showMonthDropdown
                                                showYearDropdown
                                                placeholderText="select date"
                                                className='form-control'
                                                style={{ zIndex: 100 }}
                                            />
                                            {/* <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Workers Compensation Expiration Date"
                                                    value={workersCompensationExpirationDate} onChange={e => setWorkersCompensationExpirationDate(e.target.value.trimStart())}
                                                    isInvalid={errors.workersCompensationExpirationDate ? true : false}
                                                />
                                            </InputGroup> */}
                                            {errors.workersCompensationExpirationDate && <p className='text-red m-0 p-0'>{errors.workersCompensationExpirationDate}</p>}
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>

                        <Col lg="3">
                            <span className="d-block info-text pb-3">Pick a color for the company</span>

                            <SketchPicker color={color} onChange={color => setColor(color)} />
                            {errors.color && <p className="text-red mt-3">Please choose company color</p>}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <div className="ms-auto d-flex">
                    <Button variant="outline-primary" type="submit" className="ms-2" onClick={onModalHide}>Cancel</Button>
                    <Button variant="primary" type="submit" className="ms-2" onClick={onCreateClick} disabled={submitting} style={{ minWidth: 100 }}>
                        {submitting
                            ? <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />
                            : company ? 'Save' : 'Create Company'
                        }
                    </Button>
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default CreateSystemAdminUser;