import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

//icons
import { Icon } from '@iconify/react';
import baselineBuild from '@iconify/icons-ic/baseline-build';
import peopleFill from '@iconify/icons-bi/people-fill';
import roundGroupWork from '@iconify/icons-ic/round-group-work';
import flagFill from '@iconify/icons-bi/flag-fill';
import settingsFilled from '@iconify/icons-ci/settings-filled';

//actions
import { getSystemUnreadNotificationsCount } from '../../../redux_actions/systemNotificationsActions';

const SideNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();

    //redux state
    const currentUser = useSelector(state => state.currentUser);
    const systemUnreadNotifications = useSelector(state => state.systemUnreadNotifications);

    const dispatch = useDispatch();

    useEffect(() => {
        let sysNotificationInterval;
        sysNotificationInterval = setInterval(() => {
            dispatch(getSystemUnreadNotificationsCount());
        }, 3000);

        return () => {
            if (sysNotificationInterval) clearInterval(sysNotificationInterval);
        };

    }, []);

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    return (
        <div className="sidebar">
            <Navbar expand="" bg="light">
                <Nav>
                    <Nav.Link className={(location?.pathname.includes('/project') || location?.pathname === '/') ? 'active' : ''} onClick={() => navigate('/projects')}><Icon icon={baselineBuild} width="20" height="20" /> <span className="nav-link--content">Projects</span></Nav.Link>
                    <Nav.Link className={location?.pathname.includes('/clients') ? 'active' : ''} onClick={() => navigate('/clients')}><Icon icon={peopleFill} width="20" height="20" /> <span className="nav-link--content">Clients</span></Nav.Link>
                    {isSystemAdmin() && <Nav.Link className={location?.pathname.includes('/admins') ? 'active' : ''} onClick={() => navigate('/admins')}><Icon icon={roundGroupWork} width="20" height="20" /> <span className="nav-link--content">System Admin Users</span> </Nav.Link>}
                    <Nav.Link className={location?.pathname.includes('/notifications') ? 'active' : ''} onClick={() => navigate('/notifications')}>
                        <span className="notifications"><Icon icon={flagFill} width="20" height="20" />
                            {systemUnreadNotifications > 0 && <span className="badge">{systemUnreadNotifications}</span>}
                        </span> <span className="nav-link--content">Notifications</span>
                    </Nav.Link>
                    {isSystemAdmin() && <Nav.Link className={location?.pathname.includes('/settings') ? 'active' : ''} onClick={() => navigate('/settings')}><Icon icon={settingsFilled} width="20" height="20" /> <span className="nav-link--content">System Settings</span> </Nav.Link>}
                </Nav>
            </Navbar>
        </div>
    );
};

export default SideNavigation;