import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Row, Col, Spinner, InputGroup, Table } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import circleCheck from '@iconify/icons-ci/circle-check';
import twotoneAttachFile from '@iconify/icons-ic/twotone-attach-file';
import server from '../../../../apis/server';

//actions
import { setCurrentProject } from '../../../../redux_actions/currentProjectActions';
import { set } from 'lodash';

const EditProjectStatuses = ({ showModal, setShowModal }) => {

    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    // const [approved, set_approved] = useState(false);
    const [submitted, set_submitted] = useState(false);
    const [planning, set_planning] = useState(false);
    const [building_and_safety, set_building_and_safety] = useState(false);
    const [public_works, set_public_works] = useState(false);
    const [permit_approved_pending_issuance, set_permit_approved_pending_issuance] = useState(false);
    const [permit_issued_secured, set_permit_issued_secured] = useState(false);
    const [permit_dropped_off, set_permit_dropped_off] = useState(false);

    const { name, companyName, companyColor, date_created, location_city, location_state, location_zip, progress_statuses, ownerContactName, approval_status } = currentProject;

    useEffect(() => {
        if (currentProject?.progress_statuses) {
            set_submitted(progress_statuses.submitted.included ? progress_statuses.submitted.included : false);
            set_planning(progress_statuses.planning.included ? progress_statuses.planning.included : false);
            set_building_and_safety(progress_statuses.building_and_safety.included ? progress_statuses.building_and_safety.included : false);
            set_public_works(progress_statuses.public_works.included ? progress_statuses.public_works.included : false);
            set_permit_approved_pending_issuance(progress_statuses.permit_approved_pending_issuance.included ? progress_statuses.permit_approved_pending_issuance.included : false);
            set_permit_issued_secured(progress_statuses.permit_issued_secured.included ? progress_statuses.permit_issued_secured.included : false);
            set_permit_dropped_off(progress_statuses.permit_dropped_off.included ? progress_statuses.permit_dropped_off.included : false);
        }

    }, [currentProject]);

    const onModalHide = () => {
        setShowModal(false);
    };

    const onStatusesUpdateClick = async () => {
        try {
            const includedStatuses = {};

            if (progress_statuses?.submitted?.included !== submitted) includedStatuses.submitted = submitted;
            if (progress_statuses?.planning?.included !== planning) includedStatuses.planning = planning;
            if (progress_statuses?.building_and_safety?.included !== building_and_safety) includedStatuses.building_and_safety = building_and_safety;
            if (progress_statuses?.public_works?.included !== public_works) includedStatuses.public_works = public_works;
            if (progress_statuses?.permit_approved_pending_issuance?.included !== permit_approved_pending_issuance) includedStatuses.permit_approved_pending_issuance = permit_approved_pending_issuance;
            if (progress_statuses?.permit_issued_secured?.included !== permit_issued_secured) includedStatuses.permit_issued_secured = permit_issued_secured;
            if (progress_statuses?.permit_dropped_off?.included !== permit_dropped_off) includedStatuses.permit_dropped_off = permit_dropped_off;

            console.log(includedStatuses);

            const response = await server.post('/project/includedstatuses', { projectId: currentProject._id, includedStatuses, oldStatuses: progress_statuses }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?._id) {
                dispatch(setCurrentProject(response.data));
                onModalHide();
            }

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    if (currentProject) return (

        <Modal show={showModal} onHide={onModalHide} size="xl" centered>
            <Modal.Header closeButton className="ps-md-4">
                <h2>Edit Statuses</h2>
            </Modal.Header>

            {/* 
            Submitted
            Planning
            Building And Safety
            Public Works
            Permit Approved / Pending Issuance
            Permit Issued / Secured
            Permit Dropped Off 
            */}

            <Modal.Body className="px-md-4">
                <Row>
                    <Col md={12}>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Project status</th>
                                    <th>N/A</th>
                                    <th>Include</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Received</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' disabled />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' disabled checked />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Accepted/Rejected Status</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' disabled />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' disabled checked />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Submitted</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_submitted(false)} checked={!submitted} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_submitted(true)} checked={submitted} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Planning Approved</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_planning(false)} checked={!planning} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_planning(true)} checked={planning} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Building and Safety Approved</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_building_and_safety(false)} checked={!building_and_safety} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_building_and_safety(true)} checked={building_and_safety} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Public Works</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_public_works(false)} checked={!public_works} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_public_works(true)} checked={public_works} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Permit Approved / Pending Issuance</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_permit_approved_pending_issuance(false)} checked={!permit_approved_pending_issuance} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_permit_approved_pending_issuance(true)} checked={permit_approved_pending_issuance} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Permit Issued / Secured</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_permit_issued_secured(false)} checked={!permit_issued_secured} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_permit_issued_secured(true)} checked={permit_issued_secured} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Permit Dropped Off</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_permit_dropped_off(false)} checked={!permit_dropped_off} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' onChange={e => set_permit_dropped_off(true)} checked={permit_dropped_off} />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Corrections Issued</td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' disabled />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input type='checkbox' disabled checked />
                                            <span className="checkbox-checkmark"></span>
                                        </label>
                                    </td>
                                </tr>

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <div className="ms-auto d-flex">
                    <Button variant="outline-primary" type="submit" className="ms-2" onClick={onModalHide}>Cancel</Button>
                    <Button variant="primary" type="submit" className="ms-2" onClick={onStatusesUpdateClick}>Update project statuses</Button>
                </div>
            </Modal.Footer>
        </Modal >
    );
    return null;
};

export default EditProjectStatuses;