import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import { useNavigate, useLocation } from 'react-router-dom';
import server from '../../../apis/server';
import qs from 'qs';

//redux actions
import { createdUserLogin } from '../../../redux_actions/userActions';

//Comp
import MainHeader from '../MainHeader/MainHeader';
import CreateUserBasicForm from '../../common/Forms/CreateUserBasicForm';

const ClientCreateProfile = () => {
    //React router hook that handles navigation
    let navigate = useNavigate();
    let location = useLocation();

    //redux
    const accessToken = useSelector(state => state.accessToken);
    const dispatch = useDispatch();

    //local
    const [invitationPayload, setInvitationPayload] = useState({});
    const [responseError, setResponseError] = useState('');
    const [submitting, setSubmitting] = useState(false); //after successful pdf upload this object will be => {fileName, url}

    //getting token from the link query strings
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });

    //getting the embedded data on the token
    const getInvitationPayload = async () => {
        try {
            const response = await server.post('/admins/invitepayload', {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            console.log(response.data);
            if (response.data?.email) {
                setInvitationPayload(response.data);
            }

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        if (token) getInvitationPayload();
    }, []);


    const onContinueClick = async (createUserFormData) => {
        setSubmitting(true);
        try {
            const data = {
                ...createUserFormData,
                method: 'client_invite',
                companyId: invitationPayload.companyId,
                companyRole: invitationPayload.role,
                project_id: invitationPayload.project_id
            };
            const response = await server.post('/client/create/invite', data, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            // clearing response error from local state if any
            setResponseError('');
            setSubmitting(false);

            //signin user and direct to projects page
            if (response.data?.user) {
                dispatch(createdUserLogin(response.data));
                navigate('/projects');
            }

        } catch (error) {
            setSubmitting(false);
            console.log({ error: error.message });
            if (error.response.status === 409) {
                setResponseError('There is already an account associated with this email');
            } else {
                setResponseError('Something went wrong please try again');
            }
        }
    };

    return (
        <div>
            <MainHeader />

            <main>
                <Container className="p-4">
                    <div className="text-center">
                        <h1 className="simple-title">Create your Account</h1>
                    </div>

                    <div className="container--sm">
                        <div>
                            <span className="btn-link pointer" onClick={() => navigate(-1)}> <Icon icon={roundArrowBack} width="24" height="24" /> <span className="d-inline-block ps-1">Back</span></span>
                        </div>

                        <Card className="primary-card">

                            <CreateUserBasicForm
                                infoTitle={'What’s your Name?'}
                                onCreateUserClick={onContinueClick}
                                onCancelClick={() => navigate(-1)}
                                disabledFields={['email']}
                                invitedEmail={invitationPayload.email ? invitationPayload.email : null}
                                submitting={submitting} setSubmitting={setSubmitting}
                            />

                        </Card>

                    </div>
                </Container>
            </main>
        </div>
    );
};

export default ClientCreateProfile;