import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination, InputGroup } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

//actions
import { loadCurrentProject } from '../../../redux_actions/currentProjectActions';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import ClientNotesSection from '../ProjectsOverviewPage/sections/ClientNotesSection';
import ProjectNavigationBar from '../../common/ProjectNavigationBar/ProjectNavigationBar';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import NoData from '../../common/NoData/NoData';

//validation functions
import { validateRequiredMessageInput } from '../../utils/customValidations';
import server from '../../../apis/server';

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectsNotesPage = () => {
    const { projectId } = useParams();

    //redux state
    const currentUser = useSelector(state => state.currentUser);
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    const [allNotes, setAllNotes] = useState([]);
    const [note, setNote] = useState('');
    const [publicNote, setPublicNote] = useState(true);
    const [error, setError] = useState('');

    const getNotes = async () => {
        try {
            const response = await server.get('/notes', {
                params: {
                    companyId: currentCompany._id,
                    project_id: currentProject._id,
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?.length > 0) setAllNotes(response.data);
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        dispatch(loadCurrentProject(projectId));
    }, [projectId]);

    useEffect(() => {
        getNotes();
    }, [currentCompany]);

    // effect to validate inputs with thier change
    useEffect(() => {
        //validating inputs
        if (note && validateRequiredMessageInput(note, 'Note').error) return setError(validateRequiredMessageInput(note, 'Note').error);
        setError('');

    }, [note]);

    const onAddNoteClick = async () => {

        if (validateRequiredMessageInput(note, 'Note').error) return setError(validateRequiredMessageInput(note, 'Note').error);
        setError('');

        try {
            const noteData = {
                companyId: currentCompany._id,
                project_id: currentProject._id,
                note,
                public: publicNote
            };

            const response = await server.post('/notes/add', noteData, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data._id) {
                setNote('');
                setError('');
                getNotes();
            }
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const getAdminNotes = () => {
        if (allNotes.length === 0) return [];
        return allNotes.filter(note => note.public === false);
    };

    const isSystemAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }
        return false;
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">

                    <ProjectNavigationBar />

                    <Container fluid className="py-3">

                        {isSystemAdmin() && <Row className="py-4">
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <h4 className="mb-0">System Admin Notes</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>

                                            <Col md={12}>
                                                {getAdminNotes().length > 0
                                                    ? <Table bordered striped>
                                                        <thead>
                                                            <tr>
                                                                <th>Notes</th>
                                                                <th>System Admin Name</th>
                                                                <th>Visibility</th>
                                                                <th>Date</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {getAdminNotes().map(noteData => {
                                                                const { note, added_by, createdAt } = noteData;
                                                                return (
                                                                    <tr>
                                                                        <td>{note}</td>
                                                                        <td>{added_by.contactName}</td>
                                                                        <td>Internal</td>
                                                                        <td>{moment(createdAt).format('lll')}</td>
                                                                    </tr>
                                                                );
                                                            })}

                                                        </tbody>
                                                    </Table>
                                                    : <NoData message={'No Admin notes added'} />
                                                }

                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>}
                        <ClientNotesSection/>
                        <Row className="py-4">
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <h4 className="mb-0">Add Note</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={12}>
                                                <div className="position-relative mb-3">
                                                    <Form.Label className="custom-form-label">Add Note</Form.Label>
                                                    <InputGroup className="">
                                                        <Form.Control as="textarea" rows={3} value={note} onChange={e => setNote(e.target.value.trimStart())} />
                                                    </InputGroup>
                                                    {error && <p className='text-red m-0 p-0 text-sm'>{error}</p>}
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    {isSystemAdmin() && <label className="checkbox">
                                                        <input type='checkbox' checked={publicNote} onChange={e => setPublicNote(e.target.checked)} />
                                                        <span className="checkbox-checkmark"></span>
                                                        <span className="checkbox-label">Public Note (this will be seen by all users)</span>
                                                    </label>}
                                                    <Button variant="primary" size="sm" type="submit" className="ms-2" onClick={onAddNoteClick}>Add Note</Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Container>

                </div>
            </main>

        </div>
    );
};

export default ProjectsNotesPage;