import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import lockIcon from '@iconify/icons-bx/lock';
import qs from 'qs';

//redux actions
import { createdUserLogin } from '../../../redux_actions/userActions';

//icons
import { Icon } from '@iconify/react';
import eyeOpen from '@iconify/icons-el/eye-open';
import eyeClose from '@iconify/icons-el/eye-close';
import server from '../../../apis/server';

//Comp
import MainHeader from '../MainHeader/MainHeader';

const SetNewPassPage = () => {
    //React router hook that handles navigation
    let navigate = useNavigate();
    let location = useLocation();

    //redux store
    const dispatch = useDispatch();

    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });
    console.log(token);

    //local state
    const [password, setPassword] = useState('');
    const [passwordCriteria, setPasswordCriteria] = useState({});

    const [hasLowerChar, setHasLowerChar] = useState(false);
    const [hasUpperChar, setHasUpperChar] = useState(false);
    const [hasDigit, setHasDigit] = useState(false);
    const [hasSymbol, setHasSymbol] = useState(false);
    const [greaterThanMinimumLength, setGreaterThanMinimumLength] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [error, setError] = useState('');

    const onPasswordChange = (e) => {
        const pass = e.target.value;
        setPassword(pass);

        const lowerChar = /[a-z]/.test(pass);
        const upperChar = /[A-Z]/.test(pass);
        const digit = /[0-9]/.test(pass);
        const symbole = /[#?!@$%^&*-]/.test(pass);

        const isValid = lowerChar && upperChar && digit && symbole && pass.length > 7;

        setHasLowerChar(lowerChar);
        setHasUpperChar(upperChar);
        setHasDigit(digit);
        setHasSymbol(symbole);
        setGreaterThanMinimumLength(pass.length > 7);

        setIsValidPassword(isValid);

        setPasswordCriteria({
            hasLowerChar: lowerChar,
            hasUpperChar: upperChar,
            hasDigit: digit,
            hasSymbol: symbole,
            greaterThanMinimumLength: pass.length > 7,
            isValidPassword: isValid
        });
    };

    const onResetClick = async () => {
        if (!password) return setError('Please enter a password');
        setError('');

        try {
            const response = await server.post('/updatepassword', { password }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            if (response.data?.user) {
                dispatch(createdUserLogin(response.data));
                navigate('/projects');
            }
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    return (
        <div>
            <MainHeader />

            <main>
                <Container className="px-4 py-5 p-md-5 mt-md-5">
                    <div className="text-center">
                        <h1 className="simple-title text-uppercase">SET PASSWORD</h1>
                        <span>Pick a good one!</span>
                    </div>

                    <div className="container--sm px-md-4">

                        <div className="p-3 p-md-5">
                            <Form>
                                <fieldset className="pb-4">

                                    <Row>
                                        <Col md={12}>
                                            <div className="pb-4 position-relative">
                                                <Form.Label className="custom-form-label">New password</Form.Label>
                                                <div className="position-relative">
                                                    <InputGroup className="mb-3 addon-left">
                                                        <InputGroup.Text id="basic-addon-lockIcon"><Icon icon={lockIcon} width="24" height="24" /></InputGroup.Text>

                                                        <Form.Control
                                                            placeholder="New password"
                                                            aria-label="New password"
                                                            aria-describedby="basic-addon-lockIcon"
                                                            value={password} onChange={onPasswordChange}
                                                            type={showPassword ? "text" : "password"}
                                                        />

                                                    </InputGroup>
                                                    {showPassword && <Icon className="eye-icon" icon={eyeClose} width="24" height="24" onClick={() => setShowPassword(false)} />}
                                                    {!showPassword && <Icon className="eye-icon" icon={eyeOpen} width="24" height="24" onClick={() => setShowPassword(true)} />}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={12}>
                                            <span className="d-block pb-3 ps-md-3">Requirements:</span>
                                            <div className="mb-3 ps-md-3">
                                                <div className="mb-2">
                                                    {!hasUpperChar && <Icon className="text-grey" icon="clarity:error-line" width="26" style={{ marginRight: 20 }} />}
                                                    {hasUpperChar && <Icon className="text-green" icon="akar-icons:check" width="18" style={{ marginRight: 20 }} />}
                                                    <span>Capital letter</span>
                                                </div>
                                                <div className="mb-2">
                                                    {!hasLowerChar && <Icon className="text-grey" icon="clarity:error-line" width="26" style={{ marginRight: 20 }} />}
                                                    {hasLowerChar && <Icon className="text-green" icon="akar-icons:check" width="18" style={{ marginRight: 20 }} />}
                                                    <span>Lowercase letter</span>
                                                </div>
                                                <div className="mb-2">
                                                    {!hasDigit && <Icon className="text-grey" icon="clarity:error-line" width="26" style={{ marginRight: 20 }} />}
                                                    {hasDigit && <Icon className="text-green" icon="akar-icons:check" width="18" style={{ marginRight: 20 }} />}
                                                    <span>Contains a digit</span>
                                                </div>
                                                <div className="mb-2">
                                                    {!hasSymbol && <Icon className="text-grey" icon="clarity:error-line" width="26" style={{ marginRight: 20 }} />}
                                                    {hasSymbol && <Icon className="text-green" icon="akar-icons:check" width="18" style={{ marginRight: 20 }} />}
                                                    <span>Contains a symbol</span>
                                                </div>
                                                <div className="mb-2">
                                                    {password?.length <= 7 && <Icon className="text-grey" icon="clarity:error-line" width="26" style={{ marginRight: 20 }} />}
                                                    {password.length > 7 && <Icon className="text-green" icon="akar-icons:check" width="18" style={{ marginRight: 20 }} />}
                                                    <span>Minimum length of 8 charachters</span>
                                                </div>


                                            </div>
                                        </Col>

                                    </Row>
                                </fieldset>

                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Button variant="primary" className="w-100" onClick={onResetClick}>Reset and Sign in</Button>
                                        </div>

                                    </Col>

                                    <Col md={12}>
                                        <div className="mb-3 text-center">
                                            <span className="btn btn-link btn-link--primary" onClick={() => navigate(-1)}>Back</span>
                                        </div>

                                    </Col>

                                </Row>

                            </Form>
                        </div>
                    </div>
                </Container>
            </main>
        </div>
    );
};

export default SetNewPassPage;