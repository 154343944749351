import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, InputGroup, Card, Button, Form, Table, Pagination } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import server from '../../../apis/server';

//constants
import { documentTitles } from '../../utils/constants';

//icons
import { Icon } from '@iconify/react';
import angleLeft from '@iconify/icons-la/angle-left';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import OverviewSection from '../ProjectsOverviewPage/sections/OverviewSection';

//actions
import { loadCurrentProject, setCurrentProject } from '../../../redux_actions/currentProjectActions';
import { setCurrentCompany } from '../../../redux_actions/currentCompanyActions';

//validation functions
import { validateSmallTextNoNumbers, validateZipCode, validatePhoneNumber, validateSmallNumber, validateMessageInput } from '../../utils/customValidations';

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectDetailsPage = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    const [hourly_rate, set_hourly_rate] = useState(0);
    const [comments_on_proposal, set_comments_on_proposal] = useState(null);
    const [errors, setErrors] = useState('');

    useEffect(() => {
        dispatch(loadCurrentProject(projectId));
    }, [projectId]);

    useEffect(() => {
        if (currentProject?.hourly_rate) set_hourly_rate(currentProject.hourly_rate);
    }, [currentProject]);

    const getDocument = (title) => {
        if (currentProject.documents?.length > 0) {
            const document = currentProject.documents.find(el => el.title === title);
            if (document) return document;
        }
        return null;
    };

    const checkStatus = (status) => {
        if (currentProject.progress_statuses) {
            const progress = currentProject.progress_statuses;

            if (progress[status]?.state === true) return true;
        }
        return false;
    };

    const onAcceptClick = async () => {
        const newErrors = {};

        if (validateSmallNumber(hourly_rate, 'Project Hourly Rate').error) newErrors.hourly_rate = validateSmallNumber(hourly_rate, 'Project Hourly Rate').error;
        if (validateMessageInput(comments_on_proposal, 'Comments On Proposal').error) newErrors.comments_on_proposal = validateMessageInput(comments_on_proposal, 'Comments On Proposal').error;

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});


        try {
            const response = await server.post('/project/approve', { projectId: currentProject._id, hourly_rate, comments_on_proposal }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?._id) {
                dispatch(setCurrentProject(response.data));
                navigate('/projects');
            }


        } catch (error) {
            console.log({ error: error.message });
        }
    };
    const onRejectClick = async () => {
        const newErrors = {};

        if (validateSmallNumber(hourly_rate, 'Project Hourly Rate').error) newErrors.hourly_rate = validateSmallNumber(hourly_rate, 'Project Hourly Rate').error;
        if (validateMessageInput(comments_on_proposal, 'Comments On Proposal').error) newErrors.comments_on_proposal = validateMessageInput(comments_on_proposal, 'Comments On Proposal').error;

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        try {
            const response = await server.post('/project/reject', { projectId: currentProject._id, hourly_rate, comments_on_proposal }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?._id) {
                dispatch(setCurrentProject(response.data));
                navigate('/projects');
            }

        } catch (error) {
            console.log({ error: error.message });
        }
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">
                    <div className="top-bar">
                        <h2 className="d-flex align-items-center mb-0">
                            <span className="btn--dark pointer" onClick={() => navigate('/projects')}><span className="d-inline-block pe-3"><Icon icon={angleLeft} width="32" height="32" /></span></span>
                            {currentCompany.name} - {currentProject.name} (Proposal)
                        </h2>

                        <span>Current Status: Received</span>
                    </div>

                    <Container className="py-3">
                        <Row>
                            <OverviewSection />
                        </Row>

                        <Row className="mt-4">
                            <Col lg={12}>
                                <Card>

                                    <Card.Body>
                                        <Row>
                                            <Col md={7} className="border-right">
                                                <h4>Prerequisite PDFs</h4>
                                                <Table striped bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>Document Title</th>
                                                            <th>Uploaded</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Purchase order
                                                                <p></p>
                                                            </td>
                                                            <td>
                                                                {getDocument(documentTitles.purchase_order)
                                                                    ? <span className="active">Yes</span>
                                                                    : <span className="text-grey">No</span>
                                                                }

                                                            </td>
                                                            <td>
                                                                {getDocument(documentTitles.purchase_order)
                                                                    ? <a className="btn-link--primary pointer" href={getDocument(documentTitles.purchase_order).url}>View</a>
                                                                    : <span className="text-grey">View</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Owner / Landloard Approval</td>
                                                            <td>
                                                                {getDocument(documentTitles.owner_landloard_approval)
                                                                    ? <span className="active">Yes</span>
                                                                    : <span className="text-grey">No</span>
                                                                }

                                                            </td>
                                                            <td>
                                                                {getDocument(documentTitles.owner_landloard_approval)
                                                                    ? <a className="btn-link--primary pointer" href={getDocument(documentTitles.owner_landloard_approval).url}>View</a>
                                                                    : <span className="text-grey">View</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Signed Plans</td>
                                                            <td>
                                                                {getDocument(documentTitles.signed_plans)
                                                                    ? <span className="active">Yes</span>
                                                                    : <span className="text-grey">No</span>
                                                                }

                                                            </td>
                                                            <td>
                                                                {getDocument(documentTitles.signed_plans)
                                                                    ? <a className="btn-link--primary pointer" href={getDocument(documentTitles.signed_plans).url}>View</a>
                                                                    : <span className="text-grey">View</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Title 24</td>
                                                            <td>
                                                                {getDocument(documentTitles.title_24)
                                                                    ? <span className="active">Yes</span>
                                                                    : <span className="text-grey">No</span>
                                                                }

                                                            </td>
                                                            <td>
                                                                {getDocument(documentTitles.title_24)
                                                                    ? <a className="btn-link--primary pointer" href={getDocument(documentTitles.title_24).url}>View</a>
                                                                    : <span className="text-grey">View</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Structural Calculations</td>
                                                            <td>
                                                                {getDocument(documentTitles.structural_calculations)
                                                                    ? <span className="active">Yes</span>
                                                                    : <span className="text-grey">No</span>
                                                                }

                                                            </td>
                                                            <td>
                                                                {getDocument(documentTitles.structural_calculations)
                                                                    ? <a className="btn-link--primary pointer" href={getDocument(documentTitles.structural_calculations).url}>View</a>
                                                                    : <span className="text-grey">View</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>

                                            <Col md={5} className="p-4">
                                                <h5>Additional Note</h5>
                                                <div>
                                                    <span className="info-text text-normal">{currentProject.additional_notes}</span>
                                                </div>

                                                <h5 className='mt-5'>Scope Of Work</h5>
                                                <div>
                                                    <span className="info-text text-normal">{currentProject.scope_of_work}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>

                        <Row className="mt-4">
                            <Col md={12}>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Project status</th>
                                            <th>N/A</th>
                                            <th>Include</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Received</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Approved</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={currentProject.approval_status?.state !== 'accepted'} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={currentProject.approval_status?.state === 'accepted'} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Submitted</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={!checkStatus('submitted')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={checkStatus('submitted')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Planning Approved</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={!checkStatus('planning')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={checkStatus('planning')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Building and Safety Approved</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={!checkStatus('building_and_safety')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={checkStatus('building_and_safety')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Public Works</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={!checkStatus('public_works')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={checkStatus('public_works')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Permit Approved, Pending Issuance</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={!checkStatus('permit_approved_pending_issuance')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={checkStatus('permit_approved_pending_issuance')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Permit Issued / Secured</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={!checkStatus('permit_issued_secured')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={checkStatus('permit_issued_secured')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Permit Dropped Off</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={!checkStatus('permit_dropped_off')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={checkStatus('permit_dropped_off')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Corrections Issued</td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={!checkStatus('corrections_issued')} />
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox">
                                                    <input type='checkbox' disabled checked={checkStatus('corrections_issued')}/>
                                                    <span className="checkbox-checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col md={12} className="mb-3">
                                <div className="position-relative">
                                    <Form.Label className="custom-form-label">Comments on Proposal</Form.Label>
                                    <InputGroup>
                                        <Form.Control as="textarea" rows={4}
                                            placeholder="Additional Notes"
                                            aria-label="Additional Notes"
                                            value={comments_on_proposal} onChange={e => set_comments_on_proposal(e.target.value.trimStart())}
                                        />
                                    </InputGroup>
                                    {errors.comments_on_proposal && <p className='text-red m-0 p-0 text-sm'>{errors.comments_on_proposal}</p>}
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4 mb-3">
                            <Col md={4}>
                                <InputGroup>
                                    <Form.Label className="custom-form-label">Set Project Hourly Rate</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Set Project Hourly Rate"
                                        aria-label="Set Project Hourly Rate"
                                        aria-describedby="basic-addon"
                                        value={hourly_rate} onChange={e => set_hourly_rate(e.target.value.trimStart())}
                                    />
                                </InputGroup>
                                {errors.hourly_rate && <p className='text-red m-0 p-0 text-sm'>{errors.hourly_rate}</p>}
                            </Col>
                            <Col md={8}>
                                <div className="d-flex justify-content-end">
                                    <Button variant="outline-primary" type="submit" className="ms-2" onClick={() => navigate('/projects')}>Cancel</Button>
                                    <Button variant="outline-primary" type="submit" className="ms-2" onClick={onRejectClick}>Reject</Button>
                                    <Button variant="primary" type="submit" className="ms-2" onClick={onAcceptClick}>Accept And Add Project</Button>
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </div>
            </main>

        </div>
    );
};

export default ProjectDetailsPage;