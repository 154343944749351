import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, InputGroup, Navbar, Nav, Button, Form, Table, Pagination } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import server from '../../../../apis/server';

//comps
import LogHours from '../../ProjectsHoursPage/modals/LogHours';
import { setCurrentProject } from '../../../../redux_actions/currentProjectActions';

const HoursSection = () => {
    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    const [showEditLogHours, setShowLogHours] = useState(false);
    const [allHours, setAllHours] = useState([]);
    const [projectHourlyRate, setProjectHourlyRate] = useState(0);
    const [error, setError] = useState('');


    const getHours = async () => {
        try {
            const response = await server.get('/hours', {
                params: {
                    companyId: currentCompany._id,
                    project_id: currentProject._id,
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setAllHours(response.data);
        } catch (error) {
            console.log({ error: error.message });
        }
    };


    useEffect(() => {
        getHours();
    }, [currentCompany]);

    const getTotalHours = () => {
        let totalHours = 0;
        if (allHours?.length > 0) {
            allHours.forEach(hour => totalHours += hour.amount);
        }
        return totalHours;
    };

    const getProjectHourlyRate = () => {
        let hourlyRate = 0;
        if (currentProject?.hourly_rate) hourlyRate = currentProject.hourly_rate;
        return hourlyRate;
    };

    const onProjectHourlyRateUpdate = async () => {
        if (!projectHourlyRate || projectHourlyRate <= 0) return setError('Please enter hourly rate');
        setError('');

        try {
            const response = await server.post('/project/updatehourlyrate', { projectId: currentProject._id, hourlyRate: projectHourlyRate, companyId: currentCompany._id }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data._id) dispatch(setCurrentProject(response.data));
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    const checkDisabled = () => {
        if (currentProject?.hourly_rate === projectHourlyRate) return true;
    };

    useEffect(() => {
        if (currentProject?.hourly_rate) setProjectHourlyRate(currentProject.hourly_rate);
    }, [currentProject]);

    return (
        <Row className="my-4">
            <Col>
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="mb-0">Hours Logged</h4>

                            {/* <div className='d-flex align-items-center justify-content-center'>
                                <InputGroup>
                                    <Form.Label className="custom-form-label">Project Hourly rate</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Project Hourly Rate"
                                        value={projectHourlyRate} onChange={e => setProjectHourlyRate(e.target.value.trimStart())}
                                    />
                                </InputGroup>
                                {error && <p className='text-red m-0 p-0 text-sm'>{error}</p>}
                                <Button className='pl-2' variant="primary" type="button" size="sm" onClick={onProjectHourlyRateUpdate} style={{ minWidth: 80, minHeight: 45 }} disabled={checkDisabled()}>Update</Button>
                            </div> */}
                        </div>

                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Notes</th>
                                    <th>Hours Logged</th>
                                    <th>Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                {allHours?.length > 0 && allHours.map(hour => {
                                    const { note, logged_by, log_date, amount } = hour;

                                    return (
                                        <tr>
                                            <td>{note}</td>
                                            <td>{amount}</td>

                                            <td>{moment(log_date).format('lll')}</td>
                                        </tr>
                                    );
                                })}

                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colSpan="3">
                                        <div className="text-right py-2"><strong>Project houly rate:</strong><strong className="d-inline-block ps-2 text-md">${getProjectHourlyRate()}</strong></div>
                                        <div className="text-right py-2"><strong>Total hours:</strong><strong className="d-inline-block ps-2 text-md">{Math.round(getTotalHours() * 100) / 100}</strong></div>
                                        <div className="text-right py-2"><strong>Total earnings:</strong><strong className="d-inline-block ps-2 text-lg">${Math.round((getProjectHourlyRate() * getTotalHours()) * 100) / 100}</strong></div>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>

                        <div className="d-flex align-items-center justify-content-end">
                            <Button variant="primary" type="button" size="sm" onClick={() => setShowLogHours(true)}>Add logs</Button>
                        </div>

                    </Card.Body>
                </Card>
            </Col>

            <LogHours showModal={showEditLogHours} setShowModal={setShowLogHours} getHours={getHours} />

        </Row>
    );
};

export default HoursSection;