import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Pagination, Breadcrumb, Card } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import server from '../../../apis/server';

//Modals
import EditUser from './modals/EditUser';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import ClientUserProjects from './ClientUserProjects';
import ClientUserCompanies from './ClientUserCompanies';

let active = 1;
let items = [];
for (let number = 1; number <= 1; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ClientUserDetailsPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);

    //local state
    const [userDetails, setUserDetails] = useState({});
    const [userCompanies, setUserCompanies] = useState([]);

    const [showEditUser, setShowEditUser] = useState(false);

    //Searching and sorting state
    const [searchInput, setSearchInput] = useState('');
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('lastActive');
    const [sortOrder, setSortOrder] = useState(-1);

    //csv ref
    const csvLink = useRef();

    const getUserData = async () => {
        try {
            const response = await server.get(`/client/${id}`, {
                params: { companyId: currentCompany._id },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data.userDetails) setUserDetails(response.data.userDetails);
            if (response.data.companies) setUserCompanies(response.data.companies);
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    const getUserRole = () => {
        if (currentCompany?.client_admins?.includes(userDetails._id)) return 'Client Admin';
        if (currentCompany?.client_supper_admins?.includes(userDetails._id)) return 'Client Supper Admin';
        if (currentCompany?.client_users?.includes(userDetails._id)) return 'Client User';
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">
                    <div className="top-bar">

                        {currentCompany?._id && <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate('/clients')}>{currentCompany.name}</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate(`/client/${currentCompany._id}`)}>Client Users</Breadcrumb.Item>
                            <Breadcrumb.Item active>Client User Details</Breadcrumb.Item>
                        </Breadcrumb>}

                        {/* <div className="top-bar--right">
                            <Button variant="primary" className="ms-2" onClick={() => setShowEditUser(true)}>Edit User</Button>
                        </div> */}
                    </div>

                    <Container fluid className="px-3 py-2">

                        <Row className="mb-4">
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0">Tom’s Construction</h4>
                                        </div>
                                    </Card.Header>

                                    <Card.Body>
                                        <Row>
                                            <Col md={2}>
                                                <div className="d-inline-block text-center">
                                                    <div className="user-avatar">
                                                        {userDetails._id && <span className="user-avatar-text">{userDetails.firstName[0]}{userDetails.lastName[0]}</span>}
                                                    </div>

                                                    <div>
                                                        <p className="mb-1"><strong>{userDetails.contactName}</strong></p>
                                                    </div>
                                                </div>

                                            </Col>

                                            <Col md={3} className="border-right">
                                                <h5>General info</h5>
                                                <div>
                                                    <span className="info-text text-normal">Status</span>
                                                    <p className={`mb-1 ${userDetails.active ? 'active' : 'inactive'}`}>{userDetails.active ? 'Active' : 'Inactive'}</p>
                                                </div>

                                                <div>
                                                    <span className="info-text text-normal">Role</span>
                                                    <p className="mb-1">{getUserRole()}</p>
                                                </div>

                                                <div>
                                                    <span className="info-text text-normal">Last Active</span>
                                                    <p className="mb-1">{moment(userDetails.updatedAt).format('lll')}</p>
                                                </div>

                                            </Col>

                                            <Col md={4} className="ps-5">
                                                <h5>Contact info</h5>
                                                <div>
                                                    <span className="info-text text-normal">Email</span>
                                                    <p className="mb-1"><a href="mailto:CC@aol.org">{userDetails.email}</a></p>
                                                </div>

                                                <div>
                                                    <span className="info-text text-normal">Phone number</span>
                                                    <p className="mb-1"><a href="tel:972-555-5555">{userDetails.phoneNumber}</a></p>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    {userDetails._id && userCompanies.length > 0 && <ClientUserCompanies companies={userCompanies} userDetails={userDetails} />}
                    <ClientUserProjects />

                </div>
            </main>

            <EditUser showModal={showEditUser} setShowModal={setShowEditUser} />
        </div>
    );
};

export default ClientUserDetailsPage;