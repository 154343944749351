import server from '../apis/server';

export const loadCurrentProject = (projectId) => async (dispatch, getState) => {

    try {
        const response = await server.get(`/project/${projectId}`, {
            headers: {
                Authorization: 'Bearer ' + getState().accessToken
            }
        });

        if (response.data?._id) {
            dispatch({
                type: 'SET_CURRENT_PROJECT',
                payload: response.data
            });
        }

        if (response.data?.documents) {
            dispatch({
                type: 'SET_DOCUMENTS',
                payload: response.data.documents
            });
        }

        if (response.data?.companyData) {
            dispatch({
                type: 'SET_CURRENT_COMPANY',
                payload: response.data.companyData
            });
        }
    } catch (error) {
        console.log({ error: error.message });
    }

};

export const setCurrentProject = (payload) => {
    return {
        type: 'SET_CURRENT_PROJECT',
        payload
    };
};

export const updateFinalizedDocuments = (payload) => {
    return {
        type: 'UPDATE_FINALIZED_DOCUMENTS',
        payload
    };
};