import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination, InputGroup } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import server from '../../../apis/server';
import moment from 'moment';
import { CSVLink } from "react-csv";

//actions
import { loadCurrentProject } from '../../../redux_actions/currentProjectActions';

//Modals
import LogHours from './modals/LogHours';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import ProjectNavigationBar from '../../common/ProjectNavigationBar/ProjectNavigationBar';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import NoData from '../../common/NoData/NoData';

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ProjectsHoursPage = () => {
    const { projectId } = useParams();

    //redux state
    const accessToken = useSelector(state => state.accessToken);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    //local state
    const [showEditLogHours, setShowLogHours] = useState(false);
    const [allHours, setAllHours] = useState([]);

    //csv ref
    const csvLink = useRef();

    const getHours = async () => {
        try {
            const response = await server.get('/hours', {
                params: {
                    companyId: currentCompany._id,
                    project_id: currentProject._id,
                },
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            setAllHours(response.data);
        } catch (error) {
            console.log({ error: error.message });
        }
    };


    useEffect(() => {
        getHours();
    }, [currentCompany]);

    useEffect(() => {
        dispatch(loadCurrentProject(projectId));
    }, [projectId]);

    const getTotalHours = () => {
        let totalHours = 0;
        if (allHours?.length > 0) {
            allHours.forEach(hour => totalHours += hour.amount);
        }
        return totalHours;
    };

    const getProjectHourlyRate = () => {
        let hourlyRate = 0;
        if (currentProject?.hourly_rate) hourlyRate = currentProject.hourly_rate;
        return hourlyRate;
    };

    const getCSVData = () => {
        if (!allHours || allHours.length === 0) return [];
        const data = allHours.map(hour => {
            const { note, logged_by, log_date, amount } = hour;

            return {
                'Notes': note,
                'Hours logged': amount,
                Date: moment(log_date).format('lll'),
            };
        });
        return data;
    };

    return (
        <div className="dashboard-layout">

            <HeaderWithActions />

            <main className="main">
                <SideNavigation />

                <div className="main-content">

                    <ProjectNavigationBar />

                    <Container fluid className="py-3">

                        <Row className="py-4">
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-0">Hours logged</h4>
                                            <Button variant="outline-primary" type="submit" className="ms-2" size="sm" onClick={() => csvLink.current.link.click()}>Export <span className="text-uppercase">CSV</span></Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>

                                            <Col md={12}>
                                                {allHours?.length > 0
                                                    ? <Table bordered striped>
                                                        <thead>
                                                            <tr>
                                                                <th>Notes</th>
                                                                <th>Hours logged</th>
                                                                <th>Date</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {allHours?.length > 0 && allHours.map(hour => {
                                                                const { note, logged_by, log_date, amount } = hour;

                                                                return (
                                                                    <tr>
                                                                        <td>{note}</td>
                                                                        <td>{amount}</td>

                                                                        <td>{moment(log_date).format('lll')}</td>
                                                                    </tr>
                                                                );
                                                            })}


                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="5">
                                                                    <div className="text-right py-2"><strong>Project houly rate:</strong><strong className="d-inline-block ps-2 text-md">${getProjectHourlyRate()}</strong></div>
                                                                    <div className="text-right py-2"><strong>Total hours:</strong><strong className="d-inline-block ps-2 text-md">{Math.round(getTotalHours() * 100) / 100}</strong></div>
                                                                    <div className="text-right py-2"><strong>Total earnings:</strong><strong className="d-inline-block ps-2 text-lg">${Math.round((getProjectHourlyRate() * getTotalHours()) * 100) / 100}</strong></div>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                    : <NoData message={'No hours logged'} />
                                                }


                                                <div className="d-flex justify-content-end align-items-center">
                                                    <Button variant="primary" type="button" className="ms-2" onClick={() => setShowLogHours(true)}>Add Log</Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Container>

                </div>
            </main>

            <LogHours showModal={showEditLogHours} setShowModal={setShowLogHours} getHours={getHours} />
            {allHours?.length > 0 && <CSVLink ref={csvLink} className="text-highliten d-none" data={getCSVData()} filename={`project-hours.csv`} style={{ textDecoration: 'none', color: 'white' }}></CSVLink>}
        </div>
    );
};

export default ProjectsHoursPage;