import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button, Pagination, Breadcrumb, Card } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { useNavigate, useParams } from 'react-router-dom';
import server from '../../../apis/server';
import moment from 'moment';

// redux actions
import { setCurrentCompany } from '../../../redux_actions/currentCompanyActions';

//icons
import { Icon } from '@iconify/react';
import pencilFill from '@iconify/icons-bi/pencil-fill';

//Modals
import CreateClient from './modals/CreateClient';
import CreateCompany from '../CompanyListPage/modals/CreateCompany';
import InviteClient from './modals/InviteClient';

//components
import HeaderWithActions from '../HeaderWithActions/HeaderWithActions';
import SideNavigation from '../../common/SideNavigation/SideNavigation';
import UsersList from './UsersList';
import NoData from '../../common/NoData/NoData';

let active = 1;
let items = [];
for (let number = 1; number <= 1; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ClientUsersPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);

    const dispatch = useDispatch();

    //local state
    const [users, setUsers] = useState([]);
    const [showCreateClient, setShowCreateClient] = useState(false);
    const [showCreateCompany, setShowCreateCompany] = useState(false);
    const [showInviteClient, setShowInviteClient] = useState(false);

    // const [company, setCompany] = useState(undefined);

    //csv ref
    const csvLink = useRef();

    const getCompanyDetails = async () => {
        try {
            const response = await server.get(`/company/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?._id) {
                dispatch(setCurrentCompany(response.data));
            }
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        getCompanyDetails();
    }, [id]);

    const getCSVData = () => {
        if (!users || users.length === 0) return [];
        const data = users.map(user => {
            const { firstName, lastName, contactName, email, phoneNumber, updatedAt, active } = user;
            return {
                Name: `${firstName} ${lastName}`,
                "Contact Name": contactName,
                Email: email,
                "Phone Number": phoneNumber,
                "Last Active": updatedAt,
                Status: active === true ? 'Active' : 'Not Active'
            };
        });
        return data;
    };

    const haveUsers = () => {
        if (currentCompany.client_admins) {
            let ids = [...currentCompany.client_admins, ...currentCompany.client_supper_admins, ...currentCompany.client_users];
            return ids.length > 0;
        }
        return true;
    };

    const isSystemAdminOrCompanyAdmin = () => {
        if (currentUser?._id) {
            if (currentUser.userRole === 'system_admin' && currentUser.active === true) return true;
        }

        if (currentCompany?._id) {
            if (currentCompany?.client_admins?.includes(currentUser._id)) return true;
            if (currentCompany?.client_supper_admins?.includes(currentUser._id)) return true;
        }
        return false;
    };

    if (currentCompany) {

        const {
            _id,
            name,
            email,
            location_city,
            location_state,
            location_zip,
            mailing_address_city,
            mailing_address_state,
            mailing_address_zip,
            phoneNumber,
            contractor_license_number,
            workers_compensation_company_name,
            workers_compensation_expiration_date,
            workers_compensation_policy_number,
            color
        } = currentCompany;

        return (
            <div className="dashboard-layout">

                <HeaderWithActions />

                <main className="main">
                    <SideNavigation />

                    <div className="main-content">
                        <div className="top-bar">

                            {currentCompany?._id && <Breadcrumb>
                                <Breadcrumb.Item onClick={() => navigate('/clients')}>{name}</Breadcrumb.Item>
                                <Breadcrumb.Item active onClick={() => navigate(`/client/${_id}`)}>Client Users</Breadcrumb.Item>
                            </Breadcrumb>}

                            {isSystemAdminOrCompanyAdmin() && <div className="top-bar--right">
                                {users?.length > 0 && <Button variant="outline-primary" type="submit" className="ms-2" onClick={() => csvLink.current.link.click()}>Export <span className="text-uppercase">CSV</span></Button>}
                                <Button variant="primary" className="ms-2" onClick={() => setShowInviteClient(true)}>Invite client</Button>
                                <Button variant="primary" className="ms-2" onClick={() => setShowCreateClient(true)}>Create client</Button>
                            </div>}
                        </div>

                        <Container fluid className="px-3 py-2">

                            <Row className="mb-4">
                                <Col md={12}>
                                    <Card>
                                        <Card.Header>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <span className="color-indicator" style={{ background: color ? color : 'white' }}></span>
                                                    <h4 className="mb-0">{name}</h4>
                                                </div>

                                                {isSystemAdminOrCompanyAdmin() && <Button variant="outline-primary" size="sm" onClick={() => setShowCreateCompany(true)}><Icon icon={pencilFill} width="20" height="20" /></Button>}
                                            </div>
                                        </Card.Header>

                                        <Card.Body>
                                            <Row>
                                                <Col md={4} className="border-right">
                                                    <h5>Contact Information</h5>
                                                    <div>
                                                        <span className="info-text text-normal">Email</span>
                                                        <p className="mb-1">{email}</p>
                                                    </div>

                                                    <div>
                                                        <span className="info-text text-normal">Phone number</span>
                                                        <p className="mb-1">{phoneNumber}</p>
                                                    </div>

                                                    <div>
                                                        <span className="info-text text-normal">Contractor License Number</span>
                                                        <p className="mb-1">{contractor_license_number}</p>
                                                    </div>

                                                </Col>

                                                <Col md={4} className="border-right ps-5">
                                                    <h5>Location</h5>
                                                    <div>
                                                        <span className="info-text text-normal">Address</span>
                                                        <p className="mb-1">{location_city}, {location_state}, {location_zip}</p>
                                                    </div>

                                                    <div>
                                                        <span className="info-text text-normal">Mailing Address</span>
                                                        <p className="mb-1">{mailing_address_city}, {mailing_address_state}, {mailing_address_zip}</p>
                                                    </div>

                                                </Col>

                                                <Col md={4} className="ps-5">
                                                    <h5>Workers Compensation Information</h5>
                                                    <div>
                                                        <span className="info-text text-normal">Company</span>
                                                        <p className="mb-1">{workers_compensation_company_name}</p>
                                                    </div>

                                                    <div>
                                                        <span className="info-text text-normal">Policy Number</span>
                                                        <p className="mb-1">{workers_compensation_policy_number}</p>
                                                    </div>

                                                    <div>
                                                        <span className="info-text text-normal">Expiration Date</span>
                                                        <p className="mb-1">{moment(workers_compensation_expiration_date).format('lll')}</p>
                                                    </div>

                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>

                        {
                            haveUsers()
                                ? <UsersList users={users} setUsers={setUsers} />
                                :
                                <NoData
                                    message={'No users found for this company'}
                                    actionLabel={isSystemAdminOrCompanyAdmin() ? 'Create Client' : ''}
                                    onActionClick={() => setShowCreateClient(true)}
                                />
                        }

                    </div>
                </main>

                <CreateClient showModal={showCreateClient} setShowModal={setShowCreateClient} getCompanyDetails={getCompanyDetails} />
                <InviteClient showModal={showInviteClient} setShowModal={setShowInviteClient} />
                <CreateCompany showModal={showCreateCompany} setShowModal={setShowCreateCompany} company={currentCompany} />
                {users?.length > 0 && <CSVLink ref={csvLink} className="text-highliten d-none" data={getCSVData()} filename={`admin-users.csv`} style={{ textDecoration: 'none', color: 'white' }}></CSVLink>}
            </div>
        );
    }

    return null;
};

export default ClientUsersPage;