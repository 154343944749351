import { combineReducers } from 'redux';

import accessTokenReducer from './accessTokenReducer';
import currentUserReducer from './currentUserReducer';
import currentCompanyReducer from './currentCompanyReducer';
import currentProjectReducer from './currentProjectReducer';
import documentsReducer from './documentsReducer';
import projectNotificationsReducer from './projectNotificationsReducer';
import systemNotificationsReducer from './systemNotificationsReducer';

export default combineReducers({
    //Custom User Dashboard
    accessToken: accessTokenReducer,
    currentUser: currentUserReducer,
    currentCompany: currentCompanyReducer,
    currentProject: currentProjectReducer,
    documents: documentsReducer,
    projectUnreadNotifications: projectNotificationsReducer,
    systemUnreadNotifications: systemNotificationsReducer,
});