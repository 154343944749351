import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, ProgressBar, Card, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import server from '../../../../apis/server';
import { setCurrentProject } from '../../../../redux_actions/currentProjectActions';

const OverviewSection = () => {
    const { projectId } = useParams();
    const [selectedDate, setSelectedDate] = useState('');
    //redux store
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();

    const { name, companyName, companyColor, date_created, location_city, location_state, location_zip, progress_statuses, ownerContactName, expected_Plan_Check
    } = currentProject;

    const getSubmittedDate = () => {
        if (progress_statuses?.submitted?.state === true) {
            if (progress_statuses?.submitted?.changed_at) return moment(progress_statuses.submitted.changed_at).format('ll');
        }
        return '--';
    };

    const getExpectedPlanDate = () => {
        if (expected_Plan_Check) {
            return moment(expected_Plan_Check).format('ll')
        }
        return '--';
    };

    const updateExpectedPlanDate = async (expectedDate) => {
        setSelectedDate(expectedDate)
        try {
            const response = await server.put('/project/update_expected_Plan_Check', { projectId: currentProject._id, expected_Plan_Check: expectedDate, companyId: currentCompany._id }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data._id) dispatch(setCurrentProject(response.data));
        } catch (error) {
            console.log({ error: error.message });
        }
    };
    return (
        <Col md={12}>
            <Card>
                <Card.Header>
                    <h4 className="mb-0">Overview</h4>
                </Card.Header>

                <Card.Body>
                    <Row>
                        <Col lg={4}>
                            <Card className="p-3">
                                <h4 className="pb-2">Dates</h4>
                                <div>
                                    <span className="info-text text-normal">Ordered</span>
                                    <p className="mb-1">{date_created ? moment(date_created).format('ll') : '--'}</p>
                                </div>

                                <div>
                                    <span className="info-text text-normal">Received</span>
                                    <p className="mb-1">{date_created ? moment(date_created).format('ll') : '--'}</p>
                                </div>

                                <div>
                                    <span className="info-text text-normal">Submitted to the City</span>
                                    <p className="mb-1">{getSubmittedDate()}</p>
                                </div>
                                <div>
                                    <span className="info-text text-normal">Expected Plan Check Completion Date</span>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className="mb-1" >{getExpectedPlanDate()}</p>
                                        <div>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={(expectedDate) => updateExpectedPlanDate(expectedDate)}
                                                placeholderText="select date"
                                                className='form-control'
                                                style={{ zIndex: 100 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span className="info-text text-normal">Completed</span>
                                    <p className="mb-1">{progress_statuses?.permit_dropped_off?.state === true ? moment(progress_statuses.permit_dropped_off.changed_at).format('ll') : '--'}</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="p-3">
                                <h4 className="pb-2">Company</h4>
                                <div>
                                    <span className="info-text text-normal">Company Name</span>
                                    <p className="mb-1">{currentCompany.name}</p>
                                </div>

                                <div>
                                    <span className="info-text text-normal">Company Address (City, State, ZIP)</span>
                                    <p className="mb-1">
                                        {currentCompany.location_city}, {currentCompany.location_state}, {currentCompany.location_zip}
                                    </p>
                                </div>

                                <div>
                                    <span className="info-text text-normal">Email</span>
                                    <p className="mb-1">{currentCompany.email}</p>
                                </div>
                                <div>
                                    <span className="info-text text-normal">Contractor License Number</span>
                                    <p className="mb-1">{currentCompany.contractor_license_number}</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="p-3">
                                <h4 className="pb-2">Project</h4>
                                <div>
                                    <span className="info-text text-normal">Property Owner Name</span>
                                    <p className="mb-1">{currentProject.property_owner_name}</p>
                                </div>

                                <div>
                                    <span className="info-text text-normal">Property Owner Phone number</span>
                                    <p className="mb-1">{currentProject.property_owner_phone_number}</p>
                                </div>

                                <div>
                                    <span className="info-text text-normal">Property Owner Email</span>
                                    <p className="mb-1">{currentProject.property_owner_email}</p>
                                </div>
                                <div>
                                    <span className="info-text text-normal">Project Location</span>
                                    <p className="mb-1">
                                        {currentProject.location_street_address ? `${currentProject.location_street_address},` : ``} {currentProject.location_city}, {currentProject.location_state}, {currentProject.location_zip}
                                    </p>
                                </div>
                            </Card>
                        </Col>



                    </Row>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default OverviewSection;