import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, Spinner, Navbar, Nav, Button, Form, Table, Pagination, Breadcrumb, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import server from '../../../apis/server';

//icons
import { Icon } from '@iconify/react';
import filterIcon from '@iconify/icons-bi/filter';
import searchIcon from '@iconify/icons-bi/search';
import pencilFill from '@iconify/icons-bi/pencil-fill';

//comp
import UserTableRaw from './UserTableRaw';
import useDebounce from '../../utils/useDebounce';

let active = 1;
let items = [];
for (let number = 1; number <= 1; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const UsersList = ({ users, setUsers }) => {
    const navigate = useNavigate();

    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const currentCompany = useSelector(state => state.currentCompany);

    //local state
    const [loading, setLoading] = useState(false);

    //Searching and sorting state
    const [searchInput, setSearchInput] = useState('');
    const [sortBy, setSortBy] = useState('lastActive');
    const [sortOrder, setSortOrder] = useState(-1);

    const debouncedSearch = useDebounce(searchInput, 500);

    const getUsersData = async () => {
        try {
            setLoading(true);
            if (currentCompany._id) {
                const response = await server.post('/client/users', { companyId: currentCompany._id, search: debouncedSearch, sortBy, sortOrder }, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });
                setLoading(false);
                setUsers(response.data);
            }

        } catch (error) {
            setLoading(false);
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        getUsersData();
    }, [currentCompany, debouncedSearch, sortBy, sortOrder]);

    const onSearchClear = () => {
        setSearchInput('');
    };

    const onSortByChange = (sort) => {
        setSortBy(sort);
        setSortOrder(-1);
    };

    if (currentCompany._id) return (
        <div>
            <div className="d-flex justify-content-between align-items-center px-3 pb-3">
                <div className="input-search" controlid="exampleForm.ControlInput1">
                    <span className="input-search--icon">
                        {!searchInput && !loading && < Icon icon={searchIcon} width="24" height="24" />}
                        {searchInput && !loading && <Icon className="pointer" icon="ic:round-clear" width="24" height="24" onClick={onSearchClear} />}
                        {loading && <Spinner className="mx-auto" animation="border" size="sm" style={{ color: '#019EB2' }} />}

                    </span>
                    <Form.Control type="text" placeholder="Search" value={searchInput} onChange={e => setSearchInput(e.target.value.trimStart())} />
                </div>

                <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic" className="primary">
                        <Icon icon={filterIcon} width="24" height="24" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => onSortByChange('name')}>Sort by name</Dropdown.Item>
                        <Dropdown.Item onClick={() => onSortByChange('email')}>Sort by email</Dropdown.Item>
                        <Dropdown.Item onClick={() => onSortByChange('status')}>Sort by status</Dropdown.Item>
                        <Dropdown.Item onClick={() => onSortByChange('lastActive')}>Sort by last active</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <Container fluid>
                <Row>
                    <Col>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Project Assignment</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Last Active</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.length > 0 && users.map(user => <UserTableRaw key={user._id} user={user} />)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="d-flex justify-content-end">
                            <Pagination size="sm">{items}</Pagination>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
    return null;
};

export default UsersList;