import React, { useState, } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton, Navbar, Nav, Button, Form, Table, Pagination, Breadcrumb, Card } from 'react-bootstrap';

//icons
import { Icon } from '@iconify/react';
import searchIcon from '@iconify/icons-bi/search';

//components
import NoData from '../../common/NoData/NoData';

let active = 1;
let items = [];
for (let number = 1; number <= 1; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const ClientUserProjects = ({ companies, userDetails }) => {
    //Searching and sorting state
    const [searchInput, setSearchInput] = useState('');
    const [search, setSearch] = useState('');

    const getUserRole = (company) => {
        if (company?.client_admins?.includes(userDetails._id)) return 'Client Admin';
        if (company?.client_supper_admins?.includes(userDetails._id)) return 'Client Supper Admin';
        if (company?.client_users?.includes(userDetails._id)) return 'Client User';
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center px-3 pb-3">
                <h4 className="mb-0">User Companies</h4>
            </div>

            {/* <NoData
                message={'No Projects assigned'}
                actionLabel={'Create Project'}
                onActionClick={() => { }}
            /> */}

            <Container fluid>
                <Row>
                    <Col>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>
                                        Company Name
                                    </th>
                                    <th>
                                        Role
                                    </th>
                                    <th>Company City</th>
                                    <th>Company State</th>
                                    <th>Company Zip</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companies.map(company => {
                                    return (
                                        <tr key={company._id}>
                                            <td>{company.name}</td>
                                            <td>{getUserRole(company)}</td>
                                            <td>{company.location_city}</td>
                                            <td>{company.location_state}</td>
                                            <td>{company.location_zip}</td>
                                        </tr>
                                    );
                                })}


                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="d-flex justify-content-end">
                            <Pagination size="sm">{items}</Pagination>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ClientUserProjects;