import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Dropdown, DropdownButton, Navbar, Nav, Button, Form, Table, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

//comps
import ProjectTableRow from './ProjectTableRow';
import NoData from '../../common/NoData/NoData';

const ProjectsTable = ({ setShowCreateProject, projects, searchInput, loading }) => {

    return (
        <div>
            {projects?.length > 0
                ? <Row>
                    <Col md={12}>
                        <Table striped bordered>
                            <thead >
                                <tr>
                                    <th style={{ whiteSpace: "nowrap" }}>Company Name</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Project Name</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Project Address</th>
                                    <th style={{ whiteSpace: "nowrap" }}>City</th>
                                    {/* <th>State</th>
                                    <th>Zip</th> */}
                                    <th style={{ whiteSpace: "nowrap" }}>Expected Plan Check Completion Date</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Date ordered</th>
                                    {/* <th>Date Received</th> */}
                                    <th style={{ whiteSpace: "nowrap" }}>Date Submitted to the City</th>
                                    {/* <th>Most resent status change</th> */}
                                    <th style={{ whiteSpace: "nowrap" }}>Status</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Project Owner</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects?.length > 0 && projects.map(project => <ProjectTableRow key={project._id} project={project} />)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                : searchInput
                    ? <NoData
                        message={"Sorry we couldn't find any results"}
                    />
                    : loading
                        ? <div className='d-flex align-items-center justify-content-center my-5'>
                            <Spinner className="mx-auto" animation="border" style={{ color: '#019EB2' }} />
                        </div>
                        : <NoData
                            message={'There are no projects yet'}
                            actionLabel={'Add Project'}
                            onActionClick={() => setShowCreateProject(true)}
                        />

                // <Row>
                //     <Col md={12}>
                //         <div className="text-center py-4">
                //             <img src={require('../../../images/no-data.svg').default} alt="No data found" className="img-fluid no-data-found-img" />
                //             <p className="py-4">There are no projects yet</p>
                //             <Button variant="primary" type="submit" className="ms-2" onClick={() => setShowCreateProject(true)}>Add Project</Button>
                //         </div>
                //     </Col>
                // </Row>
            }
        </div>
    );
};

export default ProjectsTable;