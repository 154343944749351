import React from 'react';
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import { loadState, saveState } from './localStorage/localStorage';
import { throttle } from 'lodash';
//redux
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from './components/App';
import reducers from './redux_reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState(); //loading data from local storage

//creating Redux store and initialize it with data from the local storage and adding Thunk enhancer
const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(thunk)));

//Saving state data to local storage every 1 second
store.subscribe(throttle(() => {
    saveState({
        accessToken: store.getState().accessToken,
        currentUser: store.getState().currentUser,
    });
}, 1000));

const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
            {/* <React.StrictMode>
                <App />
            </React.StrictMode> */}
        </BrowserRouter>
    </Provider>);
