import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { logoutUser } from '../../../redux_actions/userActions';

const MainHeader = () => {
    //redux
    const currentUser = useSelector(state => state.currentUser);
    const dispatch = useDispatch();

    return (
        <header className="header">
            <div className="d-flex align-items-center justify-content-center">
                <img src={require('../../../images/Tepia Co Logo.png')} alt="Stellar logo" className="img-fluid" style={{ width: 45, marginRight: 10 }} />
                <p className='logo-font'>Ryan's Permit Services</p>
            </div>
            {currentUser._id && <div>
                <p className="pointer" style={{ color: 'white', position: 'absolute', top: '20px', right: '30px' }} onClick={() => dispatch(logoutUser())}>Logout</p>
            </div>}
        </header>
    );
};

export default MainHeader;