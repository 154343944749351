import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isEmail } from 'validator';
import { emailLogin, logoutUser } from '../../../redux_actions/userActions';

//icons
import { Icon } from '@iconify/react';
import envelopeIcon from '@iconify/icons-bi/envelope';
import lockIcon from '@iconify/icons-bx/lock';

//Comp
import MainHeader from '../MainHeader/MainHeader';

const SignInPage = () => {
    //redux
    const dispatch = useDispatch();

    //local
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    let navigate = useNavigate();

    //handling Form Errors
    const [errors, setErrors] = useState('');

    //handling server login Errors
    const [responseError, setResponseError] = useState('');

    const onSigninClick = async () => {
        const newErrors = {};

        //validating inputs
        if (!email || !isEmail(email)) newErrors.email = 'Email is required to sign in';
        if (!password) newErrors.password = 'Please enter your password';

        if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
        else setErrors({});

        try {
            const response = await dispatch(emailLogin({ email, password }));
            if (response.error) setResponseError(response.error);
            else {
                //user signed in successfuly
                navigate('/projects');
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div>
            <MainHeader />

            <main>
                <Container className="p-5">
                    <div className="text-center">
                        <h1 className="simple-title text-uppercase">Sign in</h1>
                    </div>

                    <div className="container--sm px-md-4">

                        <div className="py-3 p-md-5">
                            <Form>
                                <fieldset className="pb-4">

                                    <Row>
                                        <Col md={12} className="mb-4">
                                            <div className="pb-4 position-relative">
                                                <Form.Label className="custom-form-label">Email</Form.Label>
                                                <InputGroup className="addon-left">
                                                    <InputGroup.Text id="basic-addon-envelopeIcon"><Icon icon={envelopeIcon} width="24" height="24" /></InputGroup.Text>

                                                    <Form.Control
                                                        placeholder="Email"
                                                        aria-label="Email"
                                                        aria-describedby="basic-addon-envelopeIcon"
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                        onKeyPress={e => { if (e.key === 'Enter') onSigninClick(); }}
                                                        isInvalid={errors.email ? true : false}
                                                    />
                                                </InputGroup>
                                                {errors.email && <p className="text-red m-0 p-0">{errors.email}</p>}
                                            </div>
                                        </Col>

                                        <Col md={12} className="mb-4">
                                            <div className="pb-3 position-relative">
                                                <Form.Label className="custom-form-label">Password</Form.Label>
                                                <InputGroup className="addon-left">
                                                    <InputGroup.Text id="basic-addon-lockIcon"><Icon icon={lockIcon} width="24" height="24" /></InputGroup.Text>

                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Password"
                                                        aria-label="Password"
                                                        aria-describedby="basic-addon-lockIcon"
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)}
                                                        onKeyPress={e => { if (e.key === 'Enter') onSigninClick(); }}
                                                        isInvalid={errors.password ? true : false}
                                                    />

                                                </InputGroup>
                                                {errors.password && <p className="text-red m-0 p-0">{errors.password}</p>}
                                            </div>
                                        </Col>
                                    </Row>
                                </fieldset>

                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Button variant="primary" className="w-100" onClick={onSigninClick}>Sign in</Button>
                                            {responseError && <p className="text-red mt-3">{responseError}</p>}
                                        </div>

                                    </Col >

                                    <Col md={12}>
                                        <div className="mb-3 text-center">
                                            <span className="btn btn-link btn-link--primary" onClick={() => navigate('/resetpassword')}>Forgot password</span>
                                        </div>
                                    </Col>

                                    {/* <Col md={12}>
                                        <div className="mb-2 text-center">
                                            <span className="info-text">First Time Signing in?</span>
                                        </div>

                                    </Col >

                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Button variant="primary" className="w-100" onClick={() => navigate('/createuser')}>Create profile</Button>
                                        </div>

                                    </Col > */}
                                </Row >

                            </Form >
                        </div >
                    </div >
                </Container >
            </main >
        </div >
    );
};

export default SignInPage;