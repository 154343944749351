/* eslint-disable import/no-anonymous-default-export */

const initialState = {

};

export default (status = initialState, action) => {
    if (action.type === 'SET_CURRENT_COMPANY') {
        return action.payload;
    }

    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return status;
};